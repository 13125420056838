import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OffersAdminService } from '../../services/offers-admin.service';
import {
  IOfferCustomerRedeem,
  IOfferDetail,
  IOfferRedeem,
} from 'src/app/shared/model/offer.model';
import { MatDialog } from '@angular/material/dialog';
import { AdminConfirmationComponent } from 'src/app/core/components/admin-confirmation/admin-confirmation.component';
import { HttpEventType } from '@angular/common/http';
import { ExportService } from '../../services/export.service';

@Component({
  selector: 'app-admin-offer-detail',
  templateUrl: './admin-offer-detail.component.html',
  styleUrls: ['./admin-offer-detail.component.scss'],
})
export class AdminOfferDetailComponent implements OnInit {
  id!: string;
  offerDetail!: IOfferDetail;
  offerRedeem!: IOfferRedeem;
  customerRedeem!: IOfferCustomerRedeem[];
  isLoading = false;
  currentPage = 1;
  pageSize: number = 10;
  totalPages: number | undefined;
  totalCount: number | undefined;
  selectMonth: any = null;
  isQuotaLoading = false;
  progress = 0;
  isExportLoading = false;
  infoLang: string = 'th';

  tabs = [{ title: 'Information' }, { title: 'Redemption quota' }];
  infoLangList = [
    { view: 'THAI version', viewValue: 'th' },
    { view: 'ENG version', viewValue: 'en' },
  ];
  selectedTab = 0;

  disablePause = ['full', 'expired', 'cancel'];

  disableEditQuota = ['expired', 'cancel'];

  queryParams: any = {
    skip: this.currentPage,
    limit: this.pageSize,
    dateStart: null,
    dateEnd: null,
    sortBy: 'usedAt',
    sortOrderBy: 'asc',
  };

  dateSelect: any = {
    dateStart: null,
    dateEnd: null,
  };

  months = [
    { view: 'This Month', viewValue: 'thisMonth' },
    { view: 'Last Month', viewValue: 'lastMonth' },
    { view: 'Last 3 months', viewValue: 'last3Months' },
    { view: 'Last 6 months', viewValue: 'last6Months' },
  ];

  constructor(
    private route: ActivatedRoute,
    private offerService: OffersAdminService,
    public matDialog: MatDialog,
    private exportService: ExportService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    console.log(this.id);
    this.getOfferDetail();
  }

  selectTab(index: number) {
    this.selectedTab = index;
    if (this.tabs[this.selectedTab].title === 'Redemption quota') {
      if (
        !Boolean(this.dateSelect.dateStart) &&
        !Boolean(this.dateSelect.dateEnd)
      ) {
        this.selectMonth = 'thisMonth';
        this.onMonthSelectionChange(this.selectMonth);
        this.onDateSelect();
      } else {
        this.onDateSelect();
      }
    }
  }

  getOfferDetail() {
    this.isLoading = true;
    this.offerService.getOfferDetail(this.id).subscribe({
      next: (data) => {
        console.log(data);
        this.offerDetail = data.data;
        this.isLoading = false;
      },
      error: (err) => {
        console.log(err);
        this.isLoading = false;
      },
    });
  }

  getQuotaDetail() {
    this.isQuotaLoading = true;
    this.offerService.getOfferQuotaDetail(this.id, this.queryParams).subscribe({
      next: (data) => {
        console.log(data);
        this.totalPages =
          data.data.totalCount > 0
            ? Math.ceil(data.data.totalCount / this.pageSize)
            : 1;
        this.totalCount = data.data.totalCount;
        this.offerRedeem = data.data.offerRedeem;
        this.customerRedeem = data.data.totalData;
        this.isQuotaLoading = false;
      },
      error: (err) => {
        console.log(err);
        this.isQuotaLoading = false;
      },
    });
  }
  onOpenConfirmation(action: string, result?: any) {
    const dialogRef = this.matDialog.open(AdminConfirmationComponent, {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      width: '600px',
      data: {
        location: window.location.pathname,
        offerData: { action: action, isPause: !result, id: this.id },
      },
      disableClose: true,
    });
  }

  onPauseOffer() {
    const isPause = this.offerDetail.status === 'pause' ? true : false;
    this.onOpenConfirmation('pause', isPause);
  }

  onDeleteOffer() {
    this.onOpenConfirmation('delete');
  }

  sortBy(sortType: string) {
    if (sortType === this.queryParams.sortBy) {
      if (this.queryParams.sortOrderBy === 'asc') {
        this.queryParams.sortOrderBy = 'desc';
      } else {
        this.queryParams.sortOrderBy = 'asc';
      }
    } else {
      this.queryParams.sortBy = sortType;
      this.queryParams.sortOrderBy = 'asc';
    }
    this.getQuotaDetail();
  }

  changePage(newPage: number): void {
    if (newPage >= 1 && newPage <= this.totalPages!) {
      this.currentPage = newPage;
      this.queryParams.skip = this.currentPage;
      this.getQuotaDetail();
    }
  }

  convertDate(date: any) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');

    const result = `${year}-${month}-${day}`;
    return result;
  }

  onDateSelect() {
    if (
      !Boolean(this.dateSelect.dateStart) ||
      !Boolean(this.dateSelect.dateEnd)
    ) {
      alert('Please select date from and date to');
    } else {
      this.queryParams.skip = 1;
      this.queryParams.dateStart = this.convertDate(this.dateSelect.dateStart);
      this.queryParams.dateEnd = this.convertDate(this.dateSelect.dateEnd);
      this.getQuotaDetail();
    }
  }

  toggleSelection(event: any) {
    if (event) {
      this.selectMonth = null;
    }
  }

  onMonthSelectionChange(value: any) {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();
    let year = today.getFullYear();
    let month = today.getMonth();

    switch (value) {
      case 'lastMonth':
        month--;
        if (month < 0) {
          year--;
          month = 11; // December
        }
        break;
      case 'last3Months':
        month -= 2; // Go back 3 months (including current)
        if (month < 0) {
          year--;
          month += 12; // Adjust for negative month values
        }
        break;
      case 'last6Months':
        month -= 5; // Go back 6 months (including current)
        if (month < 0) {
          year--;
          month += 12; // Adjust for negative month values
        }
        break;
    }

    if (value === 'lastMonth') {
      this.dateSelect.dateStart = new Date(year, month, 1);
      this.dateSelect.dateEnd = new Date(year, month + 1, 0);
    } else {
      this.dateSelect.dateStart = new Date(year, month, 1);
      this.dateSelect.dateEnd = new Date(currentYear, currentMonth + 1, 0);
    }
  }

  exportCode() {
    this.isExportLoading = true;
    this.offerService.exportCode(this.id).subscribe({
      next: (event: any) => {
        if (event.type === HttpEventType.DownloadProgress) {
          this.progress = this.exportService.handleDownloadProgress(
            event,
            this.progress
          );
        } else if (event.type === HttpEventType.Response) {
          this.exportService.handleHttpResponse(event, this.renderer, 'code');
          this.isExportLoading = false;
        }
      },
      error: (err) => {
        console.log(err);
        this.isExportLoading = false;
      },
    });
  }
}
