import { Injectable, inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  Router,
} from '@angular/router';

import { AuthService } from 'src/app/services/auth.service';
import { Role } from 'src/app/shared/enums/roles';

@Injectable()
export class AdminPermissionsService {
  role!: Role;
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const requiredRoles: Role[] = route.data['requiredRoles'];

    const currentUser = this.authService.currentUserValue;
    this.role = currentUser?.roles;

    if (requiredRoles?.some((userRole) => userRole === this.role)) {
      return true;
    } else {
      this.router.navigate(['admin/login']);
      return false;
    }
  }
}

export const authAdminGuard: CanActivateFn = (route, state) => {
  return inject(AdminPermissionsService).canActivate(route);
};

export const authAdminGuardChild: CanActivateChildFn = (route, state) => {
  return inject(AdminPermissionsService).canActivate(route);
};
