<div class="container mx-auto py-12 px-6 md:p-20">
  <a routerLink="/" class="flex items-center gap-2 cursor-pointer">
    <mat-icon>arrow_back</mat-icon>
    <span>{{ "Back to home" | transloco }}</span>
  </a>

  <div class="shadow-md shadow-gray-300 border-2 rounded-2xl w-full mt-10 p-6">
    <h1 class="text-5xl font-[Ontima-bold] font-bold">
      {{ "Forgot Password" | transloco }}
    </h1>

    <div class="sm:mb-6 mb-4 mt-8">
      <label for="email" class="block text-sm font-medium mb-2 ms-2">{{
        "fill an email" | transloco
      }}</label>
      <input
        type="email"
        id="email"
        name="email"
        required
        [formControl]="email"
        class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
        [placeholder]="'Email' | transloco"
        [ngClass]="
          (email.invalid && email.touched) || errMsg
            ? 'border-2 border-main-red'
            : ''
        "
      />
      <span
        class="text-main-red text-sm font-light"
        *ngIf="email.invalid && email.touched"
        >***{{ "Filled information are incorrect" | transloco }}</span
      >
      <p class="text-sm text-main-red" *ngIf="errMsg">{{ errMsg }}</p>
    </div>
    <p class="mb-10">
      {{ "We will sent a reset password link to your inbox" | transloco }}
    </p>

    <div class="sm:w-32">
      <app-primary-button
        *ngIf="!isLoading"
        content="Submit"
        (onClick)="onSubmit()"
      ></app-primary-button>
      <app-loading-button *ngIf="isLoading"></app-loading-button>
    </div>
  </div>
</div>
