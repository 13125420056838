import { Component } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent {
  subscription!: Subscription;
  lang!: string;

  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    this.subscription = this.translocoService.langChanges$.subscribe(
      (activeLang) => (this.lang = activeLang)
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
