import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  retry,
  throttle,
  throttleTime,
  throwError,
} from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OfferService {
  private apiUrl = environment.apiUrl; // Replace with your actual API URL
  private apiUrlWithPath = this.apiUrl + 'user/offer';
  constructor(private http: HttpClient, private route: Router) {}

  getOfferByType(queryParams: any) {
    let params = new HttpParams();
    for (let key in queryParams) {
      if (queryParams[key]) {
        params = params.set(key, queryParams[key]);
      }
    }
    const requestOptions = {
      params: params,
    };
    return this.http
      .get<any>(`${this.apiUrlWithPath}/list`, requestOptions)
      .pipe(
        throttleTime(500),
        distinctUntilChanged(),
        catchError(this.handleError)
      );
  }

  getOfferHistory(queryParams: any) {
    let params = new HttpParams();
    for (let key in queryParams) {
      if (queryParams[key]) {
        params = params.set(key, queryParams[key]);
      }
    }
    const requestOptions = {
      params: params,
    };
    return this.http
      .get<any>(`${this.apiUrlWithPath}/history`, requestOptions)
      .pipe(catchError(this.handleError));
  }

  getOfferHistoryByOffer(id: string) {
    return this.http
      .get<any>(`${this.apiUrlWithPath}/redeem/${id}`)
      .pipe(catchError(this.handleError));
  }

  getOfferById(id: string) {
    return this.http
      .get<any>(`${this.apiUrlWithPath}/detail/${id}`)
      .pipe(catchError(this.handleError));
  }

  redeemOffer(offerId: any) {
    return this.http
      .post<any>(`${this.apiUrlWithPath}/redeem`, offerId)
      .pipe(catchError(this.handleError));
  }

  setIsUsaged(id: { offerId: string }) {
    return this.http
      .patch<any>(`${this.apiUrlWithPath}/redeem`, id)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error?.error.message);
    } else {
      console.error('service::error ', error?.error);
    }
    let errorMessage = '';
    if (Array.isArray(error.error.statusMessage)) {
      errorMessage = error.error.statusMessage.toString();
    } else {
      errorMessage = error.error.statusMessage;
    }
    // console.error('offer.service::errorMessage:: ', errorMessage);
    return throwError(() => error.error);
  }
}
