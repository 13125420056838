import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transfromCard',
})
export class TransfromCardPipe implements PipeTransform {
  transform(cardNumber: string): unknown {
    const censerCard = cardNumber.slice(12, 16);

    const newCardNumber = `***${censerCard}`;

    return newCardNumber;
  }
}
