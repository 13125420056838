import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HeaderComponent } from 'src/app/core/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { RegisterComponent } from './pages/register/register.component';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { PrivilegeDetailComponent } from './pages/privilege-detail/privilege-detail.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { RedemptionHistoryComponent } from './pages/redemption-history/redemption-history.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { LoginComponent } from 'src/app/core/components/login/login.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { authGuardGuard } from 'src/app/services/auth.guard';
import { ActivateCallbackComponent } from './pages/activate-callback/activate-callback.component';
import { TermsComponent } from './pages/terms/terms.component';
import { PolicyComponent } from './pages/policy/policy.component';
import { ManualComponent } from './pages/manual/manual.component';

const routes: Routes = [
  {
    path: '',
    component: HeaderComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'register',
        component: RegisterComponent,
      },
      {
        path: 'forgot-password',
        component: ForgetPasswordComponent,
      },
      {
        path: 'offer/:id',
        component: PrivilegeDetailComponent,
      },
      {
        path: 'profile',
        canActivate: [authGuardGuard],
        component: ProfileComponent,
      },
      {
        path: 'history',
        canActivate: [authGuardGuard],
        component: RedemptionHistoryComponent,
      },
      {
        path: 'change-password',
        canActivate: [authGuardGuard],
        component: ChangePasswordComponent,
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
      },
      {
        path: 'terms',
        component: TermsComponent,
      },
      {
        path: 'privacy-policy',
        component: PolicyComponent,
      },
      {
        path: 'manual',
        component: ManualComponent,
      },
    ],
  },
  {
    path: 'auth/activate/:id',
    component: ActivateCallbackComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PublicRoutingModule {}
