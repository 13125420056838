import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { EditProfileComponent } from 'src/app/core/components/edit-profile/edit-profile.component';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';
import { IEditForm } from 'src/app/shared/model/form.model';
import { UserData } from 'src/app/shared/model/user.model';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  user!: UserData;
  subscription!: Subscription;
  isLoading = false;
  constructor(
    public matDialog: MatDialog,
    private storageService: StorageService,
    private userService: UserService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.subscription = this.storageService.changes.subscribe((data) => {
      if (data.key === 'profile') {
        this.user = data.value;
      }
    });
    this.getUser();
  }

  getUser() {
    this.isLoading = true;
    this.userService.getProfile().subscribe({
      next: (data) => {
        this.user = data.data;
        this.storageService.store('profile', this.user);
        this.isLoading = false;
      },
      error: (err) => {
        console.log(err);
        this.isLoading = false;
      },
    });
  }

  onOpenEditDialog() {
    const dialogRef = this.matDialog.open(EditProfileComponent, {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      data: { location: window.location.pathname, data: this.user },
    });
  }

  onLogout() {
    this.authService.logout();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
