<div class="flex flex-col min-h-screen">
  <header class="bg-white p-4 flex justify-center items-center shadow-lg">
    <div class="flex justify-between items-center w-full md:w-4/5">
      <a routerLink="/"
        ><img class="w-36" src="../../../assets/logo/tceb-logo.png" alt=""
      /></a>
      <div class="hidden md:flex space-x-4 items-center">
        <div
          (click)="changeLang()"
          class="hidden md:flex space-x-1 items-center cursor-pointer pr-4 border-r-2"
        >
          <mat-icon>language</mat-icon>
          <button class="btn my-2 my-sm-0">
            {{ lang }}
          </button>
        </div>

        <!-- no Login -->
        <app-primary-button
          *ngIf="!isUser || role !== 'USER'"
          [content]="'Login / Register'"
          (onClick)="onOpenLoginDialog()"
        ></app-primary-button>

        <!-- Login -->
        <div class="flex gap-4 items-center">
          <!-- <mat-icon class="text-white bg-black"
            >account_balance_wallet</mat-icon
          > -->
          <div
            *ngIf="isUser && role === 'USER'"
            class="cursor-pointer w-8 h-8 transition ease-in-out hover:scale-105 duration-150"
            [matBadge]="offerNotUse === 0 ? '' : offerNotUse"
            matBadgeSize="medium"
            routerLink="/history"
          >
            <img
              class="w-full h-full"
              src="../assets/icon/Wallet.svg"
              alt="Wallet"
            />
          </div>
          <button
            *ngIf="isUser && role === 'USER'"
            class="flex items-center gap-2 rounded-full px-2 transition ease-in-out delay-150 hover:scale-105 duration-150"
            [matMenuTriggerFor]="profileMenu"
          >
            <div
              class="border border-black rounded-full w-5 h-5 relative broder-2 overflow-hidden"
            >
              <mat-icon class="absolute bottom-[-5px] left-[-2px]"
                >person_outline</mat-icon
              >
            </div>

            <div *ngIf="user">{{ user.firstName }} {{ user.lastName }}</div>
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="md:hidden flex items-center gap-8">
      <div
        *ngIf="isUser && role === 'USER'"
        class="cursor-pointer w-8 h-8 transition ease-in-out hover:scale-105 duration-150"
        [matBadge]="offerNotUse === 0 ? '' : offerNotUse"
        matBadgeSize="medium"
        routerLink="/history"
      >
        <img
          src="../assets/icon/Wallet.svg"
          alt="Wallet"
          class="w-full h-full"
        />
      </div>
      <button (click)="toggleMenu()" class="focus:outline-none">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 6h16M4 12h16m-7 6h7"
          ></path>
        </svg>
      </button>
    </div>
  </header>
  <!-- profile menu -->
  <!-- dropdown for icon -->
  <mat-menu #profileMenu="matMenu" xPosition="before" class="w-64">
    <button routerLink="/profile" mat-menu-item>
      {{ "Profile" | transloco }}
    </button>
    <button routerLink="/history" mat-menu-item>
      {{ "Redemption History" | transloco }}
    </button>
    <button routerLink="/change-password" mat-menu-item>
      {{ "Change Password" | transloco }}
    </button>
    <button (click)="onLogout()" mat-menu-item>
      <span class="text-main-red">{{ "Logout" | transloco }}</span>
    </button>
  </mat-menu>
  <!-- side nav -->
  <div
    *ngIf="showMenu"
    class="bg-white shadow-lg md:hidden fixed top-0 right-0 h-screen w-4/6 p-4 pb-10 flex flex-col space-y-4 z-50"
    [@slideInOut]="showMenu ? 'in' : 'out'"
  >
    <button
      (click)="toggleMenu()"
      class="focus:outline-none absolute top-4 right-4"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M6 18L18 6M6 6l12 12"
        ></path>
      </svg>
    </button>
    <h1 class="text-5xl font-[Ontima-bold] font-bold">Menu</h1>
    <!-- no Login -->
    <ng-container *ngIf="!isUser || role !== 'USER'">
      <div class="flex items-center gap-2">
        <mat-icon>language</mat-icon>
        <button class="btn my-2 my-sm-0" (click)="changeLang()">
          {{ lang }}
        </button>
      </div>
      <a (click)="onOpenLoginDialog()"><p>Login / Register</p></a></ng-container
    >
    <!-- Login -->
    <div
      *ngIf="isUser && role === 'USER'"
      class="flex flex-col justify-between h-full bg-white"
    >
      <div class="flex flex-col items-start justify-start gap-4">
        <button
          class="flex gap-1 rounded-full"
          routerLink="/profile"
          (click)="toggleMenu()"
        >
          <mat-icon>person_outline</mat-icon>
          <div *ngIf="user">{{ user.firstName }} {{ user.lastName }}</div>
        </button>
        <button routerLink="/history" (click)="toggleMenu()">
          {{ "Redemption History" | transloco }}
        </button>
        <button routerLink="/change-password" (click)="toggleMenu()">
          {{ "Change Password" | transloco }}
        </button>
      </div>
      <div class="flex flex-col gap-2 items-start justify-start">
        <div class="flex items-center gap-2">
          <mat-icon>language</mat-icon>
          <button class="btn my-2 my-sm-0" (click)="changeLang()">
            {{ lang }}
          </button>
        </div>
        <button (click)="onLogout()">
          <span class="text-main-red">{{ "Logout" | transloco }}</span>
        </button>
      </div>
    </div>
  </div>
  <div class="flex-grow">
    <router-outlet>
      <p-toast
        [breakpoints]="{ '767px': { width: '100%', right: '0', left: '0' } }"
      ></p-toast
    ></router-outlet>
  </div>
  <app-footer></app-footer>
</div>
