import { Component } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { ActivatedRoute } from '@angular/router';
import { CustomerData } from 'src/app/shared/model/admin.model';
import { IOfferRedeemedList } from 'src/app/shared/model/offer.model';

@Component({
  selector: 'app-admin-customer-detail',
  templateUrl: './admin-customer-detail.component.html',
  styleUrls: ['./admin-customer-detail.component.scss'],
})
export class AdminCustomerDetailComponent {
  currentPage = 1;
  pageSize: number = 10;
  totalPages: number | undefined;
  totalCount: number | undefined;
  id!: string;
  customerDetail!: CustomerData;
  redeemedList!: IOfferRedeemedList[];
  isLoading = false;

  queryParams: any = {
    skip: this.currentPage,
    limit: this.pageSize,
    search: null,
    sortBy: 'usedAt',
    sortOrderBy: 'asc',
  };

  constructor(
    private adminService: AdminService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'] as string;
    this.getCustomerDetail();
  }

  getCustomerDetail() {
    this.isLoading = true;
    this.adminService.getCustmerDetail(this.id, this.queryParams).subscribe({
      next: (data) => {
        console.log(data);
        this.customerDetail = data.data.customerInfo;
        this.redeemedList = data.data.totalData;
        this.totalPages =
          data.data.totalCount > 0
            ? Math.ceil(data.data.totalCount / this.pageSize)
            : 1;

        this.isLoading = false;
      },
      error: (err) => {
        console.log(err);
        this.isLoading = false;
      },
    });
  }

  sortBy(sortType: string) {
    if (this.isLoading === false) {
      if (sortType === this.queryParams.sortBy) {
        if (this.queryParams.sortOrderBy === 'asc') {
          this.queryParams.sortOrderBy = 'desc';
        } else {
          this.queryParams.sortOrderBy = 'asc';
        }
      } else {
        this.queryParams.sortBy = sortType;
        this.queryParams.sortOrderBy = 'asc';
      }

      this.getCustomerDetail();
    }
  }

  changePage(newPage: number): void {
    if (newPage >= 1 && newPage <= this.totalPages!) {
      this.currentPage = newPage;
      this.queryParams.skip = this.currentPage;
      this.getCustomerDetail();
    }
  }
}
