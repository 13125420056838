<div class="mx-auto flex items-center justify-center h-screen bg-[#F7F7F7]">
  <div
    class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl w-[494px] p-5"
  >
    <h1 class="text-4xl mb-10">TCEB Offer Admin System</h1>

    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="mb-4">
        <label for="email" class="block text-sm font-medium mb-2 ms-2">{{
          "Email" | transloco
        }}</label>
        <input
          type="email"
          id="email"
          name="email"
          required
          class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
          [placeholder]="'Email' | transloco"
          formControlName="email"
          [ngClass]="
            (loginForm.controls['email'].invalid &&
              loginForm.controls['email'].touched) ||
            errmsg
              ? 'border-2 border-main-red'
              : ''
          "
        />
        <span
          class="text-main-red text-sm font-light"
          *ngIf="
            loginForm.controls['email']?.invalid &&
            loginForm.controls['email']?.touched
          "
          >***{{ "Filled information are incorrect" | transloco }}</span
        >
      </div>
      <div class="sm:mb-6 mb-2">
        <label for="password" class="block text-sm font-medium mb-2 ms-2">{{
          "Password" | transloco
        }}</label>
        <input
          type="password"
          id="password"
          name="password"
          required
          class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
          [placeholder]="'Password' | transloco"
          formControlName="password"
          [ngClass]="errmsg ? 'border-2 border-main-red' : ''"
        />
      </div>
      <p class="text-main-red font-light ps-2 mb-2" *ngIf="errmsg">
        {{ errmsg }}
      </p>

      <div
        class="sm:flex sm:justify-between gap-4 mt-10 grid grid-cols-1 w-full"
      >
        <div class="min-w-32">
          <app-loading-button *ngIf="isLoading"></app-loading-button>
          <app-primary-button
            [content]="'Login'"
            *ngIf="!isLoading"
          ></app-primary-button>
        </div>

        <button
          type="button"
          routerLink="/admin/forgot-password"
          class="text-[#006DAD] transition ease-in-out delay-150 hover:scale-110 duration-150 md:text-lg"
        >
          {{ "Forgot Password" | transloco }}
        </button>
      </div>
    </form>
  </div>
</div>
