import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { fromEvent, map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  public screenWidth$: Observable<number>;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.screenWidth$ = fromEvent(window, 'resize').pipe(
      map(() => this.document.defaultView?.innerWidth || 0)
    );
  }
}
