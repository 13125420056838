<div class="container mx-auto py-12 px-6 md:p-20">
  <div class="md:grid md:grid-cols-12 md:gap-10">
    <div class="md:col-span-4 max-md:hidden">
      <div class="shadow-md shadow-gray-300 border-2 rounded-2xl w-full p-6">
        <h2 routerLink="/profile" class="mb-4 opacity-50 cursor-pointer">
          {{ "Profile" | transloco }}
        </h2>
        <h2 class="mb-4 text-blue-500 cursor-pointer">
          {{ "Redemption History" | transloco }}
        </h2>
        <h2
          routerLink="/change-password"
          class="mb-4 opacity-50 cursor-pointer"
        >
          {{ "Change Password" | transloco }}
        </h2>
        <h2 class="text-main-red cursor-pointer" (click)="onLogout()">
          {{ "Logout" | transloco }}
        </h2>
      </div>
    </div>

    <div class="md:col-span-8">
      <h1 class="text-5xl font-[Ontima-bold] font-bold">
        {{ "Redemption History" | transloco }}
      </h1>
      <!-- <p class="opacity-50 mt-5">
        {{ "redemtion condition" | transloco }}
      </p> -->
      <div class="p-5 bg-[#F7F7F7] w-64 mt-5 rounded-xl">
        {{ "Welcome Offer Available" }}: {{ totalAvai }}
      </div>
      <h2 class="mt-10 mb-2">{{ "History" | transloco }}</h2>
      <hr />
      <ng-container *ngIf="!isLoading">
        <div
          class="flex flex-col mt-10 gap-2.5"
          *ngFor="let content of offerHistoryList"
        >
          <app-secondary-card
            (onClick)="getOfferCode(content)"
            [id]="content._id"
            [offerName]="content.offerName"
            [offerNameEN]="content.offerNameEN"
            [name]="content.merchantName"
            [nameEN]="content.merchantNameEN"
            [redeemDate]="content.usedAt"
            [image]="content.offerImage"
            [isLoading]="loadingStates[content._id] || false"
            [isUsed]="content.isUsaged"
            [isTimeLeft]="content.isTimeLeft"
          ></app-secondary-card>
        </div>
      </ng-container>

      <div *ngIf="isLoading">
        <app-main-loading></app-main-loading>
      </div>

      <!-- <h2 class="mt-10 mb-2">{{ "Yesterday" | transloco }}</h2>
      <hr />
      <div class="flex flex-col mt-10 gap-4">
        <app-secondary-card
          [id]="mockData.id"
          [offerName]="mockData.title"
          [name]="mockData.name"
          [redeemDate]="mockData.redeemDate"
          [image]="mockData.image"
        ></app-secondary-card>
        <app-secondary-card
          [id]="mockData.id"
          [offerName]="mockData.title"
          [name]="mockData.name"
          [redeemDate]="mockData.redeemDate"
          [image]="mockData.image"
        ></app-secondary-card>
      </div>

      <h2 class="mt-10 mb-2">{{ "This week" | transloco }}</h2>
      <hr />

      <div class="flex flex-col mt-10 gap-4">
        <app-secondary-card
          [id]="mockData.id"
          [offerName]="mockData.title"
          [name]="mockData.name"
          [redeemDate]="mockData.redeemDate"
          [image]="mockData.image"
        ></app-secondary-card>
        <app-secondary-card
          [id]="mockData.id"
          [offerName]="mockData.title"
          [name]="mockData.name"
          [redeemDate]="mockData.redeemDate"
          [image]="mockData.image"
        ></app-secondary-card>
      </div>

      <h2 class="mt-10 mb-2">{{ "This month" | transloco }}</h2>
      <hr />

      <div class="flex flex-col mt-10 gap-4">
        <app-secondary-card
          [id]="mockData.id"
          [offerName]="mockData.title"
          [name]="mockData.name"
          [redeemDate]="mockData.redeemDate"
          [image]="mockData.image"
        ></app-secondary-card>
        <app-secondary-card
          [id]="mockData.id"
          [offerName]="mockData.title"
          [name]="mockData.name"
          [redeemDate]="mockData.redeemDate"
          [image]="mockData.image"
        ></app-secondary-card>
      </div>

      <h2 class="mt-10 mb-2">{{ "Last month" | transloco }}</h2>
      <hr />
   
      <div class="flex flex-col mt-10 gap-4">
        <app-secondary-card
          [id]="mockData.id"
          [offerName]="mockData.title"
          [name]="mockData.name"
          [redeemDate]="mockData.redeemDate"
          [image]="mockData.image"
        ></app-secondary-card>
        <app-secondary-card
          [id]="mockData.id"
          [offerName]="mockData.title"
          [name]="mockData.name"
          [redeemDate]="mockData.redeemDate"
          [image]="mockData.image"
        ></app-secondary-card>
      </div>

      <h2 class="mt-10 mb-2">{{ "Last 6 month" | transloco }}</h2>
      <hr />
   
      <div class="flex flex-col mt-10 gap-4">
        <app-secondary-card
          [id]="mockData.id"
          [offerName]="mockData.title"
          [name]="mockData.name"
          [redeemDate]="mockData.redeemDate"
          [image]="mockData.image"
        ></app-secondary-card>
        <app-secondary-card
          [id]="mockData.id"
          [offerName]="mockData.title"
          [name]="mockData.name"
          [redeemDate]="mockData.redeemDate"
          [image]="mockData.image"
        ></app-secondary-card>
      </div> -->
    </div>
  </div>
</div>
