import { Component, Renderer2 } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { CustomerData } from 'src/app/shared/model/admin.model';
import { HttpEventType } from '@angular/common/http';
import { ExportService } from '../../services/export.service';

@Component({
  selector: 'app-admin-customer-list',
  templateUrl: './admin-customer-list.component.html',
  styleUrls: ['./admin-customer-list.component.scss'],
})
export class AdminCustomerListComponent {
  currentPage = 1;
  pageSize: number = 10;
  totalPages: number | undefined;
  totalCount: number | undefined;
  customerList!: CustomerData[];
  isLoading = false;
  progress = 0;
  isExportLoading = false;

  queryParams: any = {
    skip: this.currentPage,
    limit: this.pageSize,
    search: null,
    dateStart: null,
    dateEnd: null,
    sortBy: 'createdAt',
    sortOrderBy: 'asc',
  };

  constructor(
    private adminService: AdminService,
    private exportService: ExportService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.getCustomerList();
  }

  getCustomerList() {
    this.isLoading = true;
    this.adminService.getCustomerList(this.queryParams).subscribe({
      next: (data) => {
        console.log(data);
        this.customerList = data.data.totalData;
        this.totalPages = Math.ceil(data.data.totalCount / this.pageSize);
        this.isLoading = false;
      },
      error: (err) => {
        console.log(err);
        this.isLoading = false;
      },
    });
  }

  sortBy(sortType: string) {
    if (this.isLoading === false) {
      if (sortType === this.queryParams.sortBy) {
        if (this.queryParams.sortOrderBy === 'asc') {
          this.queryParams.sortOrderBy = 'desc';
        } else {
          this.queryParams.sortOrderBy = 'asc';
        }
      } else {
        this.queryParams.sortBy = sortType;
        this.queryParams.sortOrderBy = 'asc';
      }

      this.getCustomerList();
    }
  }

  changePage(newPage: number): void {
    if (newPage >= 1 && newPage <= this.totalPages!) {
      this.currentPage = newPage;
      this.queryParams.skip = this.currentPage;
      this.getCustomerList();
    }
  }

  exportData() {
    this.progress = 0;
    this.isExportLoading = true;
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = today.getDate().toString().padStart(2, '0');
    const dateStart = '2024-01-01';
    const dateEnd = `${year}-${month}-${day}`;
    const query = {
      dateStart: dateStart,
      dateEnd: dateEnd,
    };

    this.adminService.exportCustomer(query).subscribe({
      next: (event: any) => {
        console.log(event);

        if (event.type === HttpEventType.DownloadProgress) {
          this.progress = this.exportService.handleDownloadProgress(
            event,
            this.progress
          );
        } else if (event.type === HttpEventType.Response) {
          this.exportService.handleHttpResponse(
            event,
            this.renderer,
            'customer'
          );
          this.isExportLoading = false;
        }
      },
      error: (err) => {
        console.log(err);
        this.isExportLoading = false;
      },
    });
  }

  get style() {
    return 'width:' + this.progress + '%';
  }
}
