import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OfferCodeComponent } from '../offer-code/offer-code.component';
import { OfferService } from 'src/app/services/offer.service';
import { ICode } from 'src/app/shared/model/offer.model';
import { ResponsiveService } from 'src/app/shared/utils/responsive.service';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss'],
})
export class ConfirmPopupComponent {
  id!: string;
  codeData!: ICode;
  body = {
    offerId: '',
  };
  isLoading = false;
  screenW: any;
  errMsg = '';
  subscription!: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ICode,
    public dialogRef: MatDialogRef<ConfirmPopupComponent>,
    private router: Router,
    public matDialog: MatDialog,
    private offerService: OfferService,
    private responsiveService: ResponsiveService,
    @Inject(DOCUMENT) private document: Document
  ) {
    const width = this.document.defaultView?.innerWidth;
    this.screenW = width! < 640 ? 1 : 2;
  }

  ngOnInit(): void {
    this.subscription = this.responsiveService.screenWidth$.subscribe(
      (width) => {
        this.screenW = width < 640 ? 1 : 2;
      }
    );
  }

  onOpenCode() {
    const closeData = { result: true, status: 'success' };
    // this.dialogRef.close(closeData);
    const optionSM = {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      minWidth: '100%',
      height: '550px',
      data: this.data,
      disableClose: true,
    };
    const option = {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      width: '600px',
      data: this.data,
      disableClose: true,
    };

    const selectOption = this.screenW === 1 ? optionSM : option;
    const dialog = this.matDialog.open(OfferCodeComponent, selectOption);

    dialog.beforeClosed().subscribe((result) => {
      if (result) {
        this.dialogRef.close(closeData);
      } else {
        closeData.result = false;
        this.dialogRef.close(closeData);
      }
    });
  }

  // onConfirm() {
  //   this.isLoading = true;
  //   this.offerService.redeemOffer(this.body).subscribe({
  //     next: (data) => {
  //       this.codeData = data.data;
  //       this.codeData.isUsaged = false;
  //       this.codeData.isHistory = false;
  //       this.codeData.offerId = this.id;
  //       this.isLoading = false;
  //     },
  //     error: (err) => {
  //       console.log(err);
  //       this.isLoading = false;
  //       this.errMsg = err.statusMessage;
  //     },
  //     complete: () => {
  //       this.isLoading = false;
  //       this.onOpenCode();
  //     },
  //   });
  // }

  onClose() {
    if (this.errMsg) {
      const data = { result: true, status: 'error' };
      this.dialogRef.close(data);
    } else {
      const data = { result: false, status: 'success' };
      this.dialogRef.close(data);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
