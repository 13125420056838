import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  inject,
} from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { LangDefinition, TranslocoService } from '@jsverse/transloco';
import { map, Subscription, take } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../components/login/login.component';
import { AuthService } from 'src/app/services/auth.service';
import { UserData } from 'src/app/shared/model/user.model';
import { StorageService } from 'src/app/services/storage.service';
import { Role } from 'src/app/shared/enums/roles';
import { toFile } from 'qrcode';
import { MessageService } from 'primeng/api';
import { ResponsiveService } from 'src/app/shared/utils/responsive.service';
import { DOCUMENT } from '@angular/common';
import { AnyObject } from 'chart.js/dist/types/basic';
import { OfferService } from 'src/app/services/offer.service';
import { IOfferHistory } from 'src/app/shared/model/offer.model';
import { DataServiceService } from 'src/app/shared/utils/data-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          transform: 'translate3d(0, 0, 0)',
        })
      ),
      state(
        'out',
        style({
          transform: 'translate3d(100%, 0, 0)',
        })
      ),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out')),
    ]),
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  availableLang: any;
  showMenu: boolean = false;
  lang!: string;
  getLang!: string;
  isUser = false;
  subscription!: Subscription;
  user!: UserData;
  role!: Role;
  screenW: any;
  offerNotUse: number = 0;
  queryParams: any = {
    skip: 1,
    limit: 10,
    dateStart: '2024/05/01',
    dateEnd: null,
    sortBy: 'usedAt',
    sortOrderBy: 'asc',
  };

  constructor(
    private translocoService: TranslocoService,
    public matDialog: MatDialog,
    private authService: AuthService,
    private storageService: StorageService,
    private messageService: MessageService,
    private responsiveService: ResponsiveService,
    @Inject(DOCUMENT) private document: Document,
    private offerService: OfferService,
    private dataService: DataServiceService
  ) {
    this.availableLang = translocoService.getAvailableLangs();
    const width = this.document.defaultView?.innerWidth;
    this.screenW = width! < 640 ? 1 : 2;
  }

  ngOnInit(): void {
    this.getLocalLang();
    this.subscription = this.authService.currentUser.subscribe((user: any) => {
      this.isUser = Boolean(user);
      this.role = user?.roles;
      if (this.isUser) {
        this.getHistoryList();
      }
    });
    const userStorage = localStorage.getItem('profile');
    this.user = JSON.parse(userStorage!);

    const subscription2 = this.storageService.changes.subscribe((data: any) => {
      if (data.key === 'profile') {
        this.user = data.value;
      }
    });

    const sub3 = this.responsiveService.screenWidth$.subscribe((width) => {
      this.screenW = width < 640 ? 1 : 2;
    });

    const sub4 = this.dataService.offerNotUse$.subscribe((data) => {
      this.offerNotUse = data;
    });

    this.subscription.add(subscription2);
    this.subscription.add(sub3);
    this.subscription.add(sub4);
  }

  getSelectedLang() {
    this.getLang = this.translocoService.getActiveLang();
    const selectLang = this.availableLang.find(
      (lang: any) => this.getLang === lang.id
    );
    this.lang = selectLang.label;
    localStorage.setItem('lang', this.getLang);
  }

  toggleMenu(): void {
    this.showMenu = !this.showMenu;
  }
  changeLang() {
    if (this.getLang === 'en') {
      this.translocoService.setActiveLang('th');
    } else {
      this.translocoService.setActiveLang('en');
    }
    this.getSelectedLang();
  }

  getLocalLang() {
    const localLang = localStorage.getItem('lang');
    if (localLang) {
      this.translocoService.setActiveLang(localLang);
      this.getSelectedLang();
    } else {
      this.getSelectedLang();
    }
  }

  getHistoryList() {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = today.getDate().toString().padStart(2, '0');

    const formattedDate = `${year}/${month}/${day}`;
    this.queryParams.dateEnd = formattedDate;

    this.offerService
      .getOfferHistory(this.queryParams)
      .pipe(
        map((data) => {
          const redeemNum = data.data.totalData.length;

          if (redeemNum === 0) {
            return 0;
          } else {
            const useOffer = data.data.totalData.filter(
              (item: IOfferHistory) => item.isUsaged === true
            );

            if (useOffer.length > 0) {
              return redeemNum - useOffer.length;
            } else {
              return redeemNum;
            }
          }
        })
      )
      .subscribe({
        next: (data) => {
          this.offerNotUse = data;
          this.dataService.updateOfferUse(data);
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  onOpenLoginDialog() {
    this.showMenu = false;
    const optionSM = {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      minWidth: '100%',
    };
    const option = {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      width: '480px',
    };

    const selectOption = this.screenW === 1 ? optionSM : option;

    const dialogRef = this.matDialog.open(LoginComponent, selectOption);

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.messageService.add({
          severity: 'success',
          summary: 'Login Success',
          detail: 'You are already login!',
          life: 2000,
        });
      }
    });
  }
  onLogout() {
    this.authService.logout();
    this.toggleMenu();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
