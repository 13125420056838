import { Component } from '@angular/core';

@Component({
  selector: 'app-discard-form',
  templateUrl: './discard-form.component.html',
  styleUrls: ['./discard-form.component.scss']
})
export class DiscardFormComponent {

}
