import { Component, Inject, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { UserData } from 'src/app/shared/model/user.model';
import { CompletedPopupComponent } from '../completed-popup/completed-popup.component';
import { IEditForm } from 'src/app/shared/model/form.model';
import { UserService } from 'src/app/services/user.service';
import { StorageService } from 'src/app/services/storage.service';
import { AdminData } from 'src/app/shared/model/admin.model';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss'],
})
export class EditProfileComponent {
  userData!: UserData;
  editProfileForm!: FormGroup;
  editData!: IEditForm;
  isLoading = false;
  adminData!: AdminData;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditProfileComponent>,
    public matDialog: MatDialog,
    private fb: FormBuilder,
    private userService: UserService,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    if (this.data?.isAdmin) {
      this.adminData = this.data.data;
      this.initAdminForm();
      this.patchAdminForm();
    } else {
      this.userData = this.data.data;
      this.initForm();
      this.patchForm();
    }
  }
  initForm() {
    this.editProfileForm = this.fb.group({
      name: [null, Validators.required],
      lastName: [null, Validators.required],
      card: [null, Validators.required],
      phone: [null, Validators.required],
    });
  }

  initAdminForm() {
    this.editProfileForm = this.fb.group({
      name: [null, Validators.required],
      lastName: [null, Validators.required],
      phone: [null, Validators.required],
    });
  }

  patchAdminForm() {
    const patchValue = {
      name: this.adminData.firstName,
      lastName: this.adminData.lastName,
      phone: this.adminData.phoneNumber,
    };

    this.editProfileForm.patchValue(patchValue);
  }

  patchForm() {
    const formatCard = this.formatCardNumber(this.userData.cardNumber, true);

    const patchValue = {
      name: this.userData.firstName,
      lastName: this.userData.lastName,
      card: formatCard,
      phone: this.userData.phoneNumber,
    };

    this.editProfileForm.patchValue(patchValue);
  }

  getUser() {
    this.userService.getProfile().subscribe({
      next: (user) => {
        if (this.data?.isAdmin) {
          this.adminData = user.data;
          this.storageService.store('profile', this.adminData);
        } else {
          this.userData = user.data;
          this.storageService.store('profile', this.userData);
        }
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  formatCardNumber(event: any, isPatch?: boolean): string | void {
    let inputValue;
    if (isPatch) {
      inputValue = event;
    } else {
      inputValue = event.target.value;
    }

    // Remove non-numeric characters from the input
    const numericValue = inputValue.replace(/\D/g, '');

    // Format the numeric value as desired
    let formattedValue = '';
    for (let i = 0; i < numericValue.length; i++) {
      if (i < 4) {
        formattedValue += numericValue[i];
      } else if (i === 4) {
        formattedValue += ' - ' + numericValue[i];
      } else if (i === 5) {
        formattedValue += numericValue[i];
      } else if (i === 6) {
        formattedValue += 'XX - XXXX - ' + numericValue[i];
      } else if (i >= 7 && i <= 9) {
        formattedValue += numericValue[i];
      }
    }

    // Update the card input field with the formatted value
    if (isPatch) {
      return formattedValue;
    } else {
      this.editProfileForm.get('card')?.setValue(formattedValue);
    }
  }

  onOpenCompleted() {
    const dialogRef = this.matDialog.open(CompletedPopupComponent, {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      width: '600px',
      data: { location: window.location.pathname },
    });

    // dialogRef.afterClosed().subscribe((data) => {
    //   this.dialogRef.close();
    // });
  }

  summaryForm(): IEditForm {
    if (this.data.isAdmin) {
      this.editData = {
        firstName: this.editProfileForm.value.name,
        lastName: this.editProfileForm.value.lastName,
        phoneNumber: this.editProfileForm.value.phone,
      };
    } else {
      const card = this.editProfileForm.value.card;
      const formatCard = card?.split(' - ').join('');
      this.editData = {
        firstName: this.editProfileForm.value.name,
        lastName: this.editProfileForm.value.lastName,
        cardNumber: formatCard,
        phoneNumber: this.editProfileForm.value.phone,
      };
    }

    return this.editData;
  }

  onSubmit() {
    this.isLoading = true;
    if (this.editProfileForm.invalid) {
      this.editProfileForm.markAllAsTouched();
    } else {
      const editData = this.summaryForm();
      this.userService.updateProfile(editData).subscribe({
        next: (data) => {
          this.getUser();
        },
        error: (err) => {
          console.log(err);
          this.isLoading = false;
        },
        complete: () => this.onOpenCompleted(),
      });
    }
  }
}
