<div class="container mx-auto py-12 px-6 md:p-20">
  <div class="md:grid md:grid-cols-12 md:gap-10">
    <div class="md:col-span-4 max-md:hidden">
      <div class="shadow-md shadow-gray-300 border-2 rounded-2xl w-full p-6">
        <h2 class="mb-4 text-blue-500 cursor-pointer">
          {{ "Profile" | transloco }}
        </h2>
        <h2 routerLink="/history" class="mb-4 opacity-50 cursor-pointer">
          {{ "Redemption History" | transloco }}
        </h2>
        <h2
          routerLink="/change-password"
          class="mb-4 opacity-50 cursor-pointer"
        >
          {{ "Change Password" | transloco }}
        </h2>
        <h2 class="text-main-red cursor-pointer" (click)="onLogout()">
          {{ "Logout" | transloco }}
        </h2>
      </div>
    </div>

    <div class="md:col-span-8">
      <h1 class="text-5xl font-[Ontima-bold] font-bold">
        {{ "Profile" | transloco }}
      </h1>
      <ng-container *ngIf="!isLoading">
        <div
          class="shadow-md shadow-gray-300 border-2 rounded-2xl w-full p-6 mt-10"
          *ngIf="user"
        >
          <div class="flex flex-col max-md:gap-4 md:grid md:grid-cols-2">
            <div class="flex flex-col overflow-auto">
              <h2 class="font-semibold">{{ "Firstname" | transloco }}</h2>
              <h2 class="font-medium">{{ user.firstName }}</h2>
            </div>
            <div class="flex flex-col overflow-auto">
              <h2 class="font-semibold">{{ "Lastname" | transloco }}</h2>
              <h2 class="font-medium">{{ user.lastName }}</h2>
            </div>
          </div>
          <div class="grid-cols-1 mt-4">
            <h2 class="font-semibold">{{ "Card no." | transloco }}</h2>
            <h2 class="font-medium">{{ user.cardNumber | transfromCard }}</h2>
          </div>
          <div class="flex flex-col max-md:gap-4 md:grid md:grid-cols-2 mt-4">
            <div class="flex flex-col overflow-auto">
              <h2 class="font-semibold">{{ "Phone number" | transloco }}</h2>
              <h2 class="font-medium">{{ user.phoneNumber }}</h2>
            </div>
            <div class="flex flex-col overflow-auto">
              <h2 class="font-semibold">{{ "Email" | transloco }}</h2>
              <h2 class="font-medium">{{ user.email }}</h2>
            </div>
          </div>
        </div>
        <div class="mt-5 md:w-44">
          <app-secondary-button
            content="Edit Profile"
            (onClick)="onOpenEditDialog()"
          ></app-secondary-button>
        </div>
      </ng-container>
      <ng-container *ngIf="isLoading">
        <app-main-loading></app-main-loading>
      </ng-container>
    </div>
  </div>
</div>
