import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CompletedPopupComponent } from 'src/app/core/components/completed-popup/completed-popup.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-admin-forget-password',
  templateUrl: './admin-forget-password.component.html',
  styleUrls: ['./admin-forget-password.component.scss'],
})
export class AdminForgetPasswordComponent {
  email = new FormControl('', Validators.email);
  errMsg = '';
  isLoading = false;
  constructor(public matDialog: MatDialog, private userService: UserService) {}

  onOpenCompleted() {
    const dialogRef = this.matDialog.open(CompletedPopupComponent, {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      width: '600px',
      data: { location: window.location.pathname },
      disableClose: true,
    });
  }

  onSubmit() {
    this.errMsg = '';
    if (this.email.invalid) {
      this.email.markAsTouched();
    } else {
      this.isLoading = true;
      const data = { email: this.email.value };
      this.userService.forgotPassword(data).subscribe({
        next: (data) => {
          console.log(data);
          this.isLoading = false;
        },
        error: (err) => {
          this.errMsg = err.statusMessage;
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
          this.onOpenCompleted();
        },
      });
    }
  }
}
