<div class="container mx-auto py-12 px-6 md:p-20">
  <a routerLink="/" class="flex items-center gap-2 cursor-pointer">
    <mat-icon>arrow_back</mat-icon>
    <span>{{ "Back to home" | transloco }}</span>
  </a>

  <div class="shadow-md shadow-gray-300 border-2 rounded-2xl w-full mt-10 p-6">
    <h1 class="text-5xl font-[Ontima-bold] font-bold">
      {{ "Reset Password" | transloco }}
    </h1>

    <form [formGroup]="changeForm" (ngSubmit)="onSubmit()">
      <div class="mt-8 mb-10 grid grid-cols-1 sm:grid-cols-2 gap-5">
        <div>
          <label
            for="newPassword"
            class="block text-sm font-medium mb-2 ms-2"
            >{{ "New password" | transloco }}</label
          >
          <input
            type="password"
            id="newPassword"
            name="newPassword"
            required
            class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
            [placeholder]="'New password' | transloco"
            formControlName="newPassword"
            [ngClass]="
              (changeForm.controls['newPassword'].invalid &&
                changeForm.controls['newPassword'].touched) ||
              errMsg
                ? 'border-2 border-main-red'
                : ''
            "
          />
          <span
            class="text-main-red text-sm font-light"
            *ngIf="
              changeForm.controls['newPassword']?.invalid &&
              changeForm.controls['newPassword']?.touched
            "
            >***{{ "Filled information are incorrect" | transloco }}</span
          >
        </div>
        <div>
          <label
            for="cNewPassword"
            class="block text-sm font-medium mb-2 ms-2"
            >{{ "Confirm new password" | transloco }}</label
          >
          <input
            type="password"
            id="cNewPassword"
            name="cNewPassword"
            required
            class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
            [placeholder]="'Confirm new password' | transloco"
            formControlName="cNewPassword"
            [ngClass]="
              changeForm.controls['cNewPassword'].touched &&
              changeForm.controls['newPassword'].value !==
                changeForm.controls['cNewPassword'].value
                ? 'border-2 border-main-red'
                : ''
            "
          />
          <span
            class="text-main-red text-sm font-light"
            *ngIf="
              changeForm.controls['cNewPassword'].touched &&
              changeForm.controls['newPassword']?.value !==
                changeForm.controls['cNewPassword']?.value
            "
            >***{{ "Filled information are incorrect" | transloco }}</span
          >
        </div>
        <p class="text-main-red" *ngIf="errMsg">{{ errMsg }}</p>
      </div>
      <div class="sm:w-52">
        <app-loading-button *ngIf="isLoading"></app-loading-button>
        <app-primary-button
          *ngIf="!isLoading"
          content="Save change"
        ></app-primary-button>
      </div>
    </form>
  </div>
</div>
