import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { ExportOptions } from 'src/app/shared/model/admin.model';
import { IInviteAdmin } from 'src/app/shared/model/form.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private apiUrl = environment.apiUrl; // Replace with your actual API URL
  private apiAdmin = this.apiUrl + 'backoffice/admin';
  private apiCustomer = this.apiUrl + 'backoffice/customer';
  private apiDashboard = this.apiUrl + 'backoffice/dashboard';
  constructor(private http: HttpClient, private route: Router) {}

  resetPassword(password: any) {
    return this.http
      .patch<any>(`${this.apiUrl}user/admin/resetPassword`, password)
      .pipe(catchError(this.handleError));
  }

  getDashboard() {
    return this.http
      .get<any>(`${this.apiDashboard}`)
      .pipe(catchError(this.handleError));
  }

  getAdminList(queryParams: any) {
    let params = new HttpParams();
    for (let key in queryParams) {
      if (queryParams[key]) {
        params = params.set(key, queryParams[key]);
      }
    }
    const requestOptions = {
      params: params,
    };
    return this.http
      .get<any>(`${this.apiAdmin}/list`, requestOptions)
      .pipe(catchError(this.handleError));
  }

  getAdminDetail(id: string) {
    return this.http
      .get<any>(`${this.apiAdmin}/detail/${id}`)
      .pipe(catchError(this.handleError));
  }

  inviteAdmin(formSummary: IInviteAdmin) {
    return this.http
      .post<any>(`${this.apiAdmin}`, formSummary)
      .pipe(catchError(this.handleError));
  }

  deleteAdmin(id: string, passwordBody: any) {
    const options = {
      body: passwordBody,
    };

    return this.http
      .delete<any>(`${this.apiAdmin}/${id}`, options)
      .pipe(catchError(this.handleError));
  }

  getCustomerList(queryParams: any) {
    let params = new HttpParams();
    for (let key in queryParams) {
      if (queryParams[key]) {
        params = params.set(key, queryParams[key]);
      }
    }
    const requestOptions = {
      params: params,
    };
    return this.http
      .get<any>(`${this.apiCustomer}/list`, requestOptions)
      .pipe(catchError(this.handleError));
  }

  getCustmerDetail(id: string, queryParams: any) {
    let params = new HttpParams();
    for (let key in queryParams) {
      if (queryParams[key]) {
        params = params.set(key, queryParams[key]);
      }
    }
    const requestOptions = {
      params: params,
    };
    return this.http
      .get<any>(`${this.apiCustomer}/detail/${id}`, requestOptions)
      .pipe(catchError(this.handleError));
  }

  exportCustomer(queryParams: any) {
    let params = new HttpParams();
    for (let key in queryParams) {
      if (queryParams[key]) {
        params = params.set(key, queryParams[key]);
      }
    }
    const requestOptions: ExportOptions = {
      params: params,
      reportProgress: true,
      observe: 'events',
      responseType: 'blob' as 'json',
    };

    return this.http
      .get<Blob>(`${this.apiCustomer}/export`, requestOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      console.error('service::error ', error.error);
    }
    let errorMessage = '';
    if (Array.isArray(error.error.statusMessage)) {
      errorMessage = error.error.statusMessage.toString();
    } else {
      errorMessage = error.error.statusMessage;
    }
    // console.error('offer.service::errorMessage:: ', errorMessage);
    return throwError(() => error.error);
  }
}
