import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { AdminDataList } from 'src/app/shared/model/admin.model';

@Component({
  selector: 'app-admin-management',
  templateUrl: './admin-management.component.html',
  styleUrls: ['./admin-management.component.scss'],
})
export class AdminManagementComponent implements OnInit {
  currentPage = 1;
  pageSize: number = 10;
  totalPages: number | undefined;
  totalCount: number | undefined;
  adminList!: AdminDataList[];
  isLoading = false;

  queryParams: any = {
    skip: this.currentPage,
    limit: this.pageSize,
    search: null,
    dateStart: null,
    dateEnd: null,
    sortBy: 'createdAt',
    sortOrderBy: 'asc',
  };

  constructor(private adminService: AdminService) {}

  ngOnInit(): void {
    this.getAdminList();
  }

  getAdminList() {
    this.isLoading = true;
    this.adminService.getAdminList(this.queryParams).subscribe({
      next: (data) => {
        console.log(data);
        this.adminList = data.data.totalData;
        this.totalPages = Math.ceil(data.data.totalCount / this.pageSize);
        this.isLoading = false;
      },
      error: (err) => {
        console.log(err);
        this.isLoading = false;
      },
    });
  }

  sortBy(sortType: string) {
    if (sortType === this.queryParams.sortBy) {
      if (this.queryParams.sortOrderBy === 'asc') {
        this.queryParams.sortOrderBy = 'desc';
      } else {
        this.queryParams.sortOrderBy = 'asc';
      }
    } else {
      this.queryParams.sortBy = sortType;
      this.queryParams.sortOrderBy = 'asc';
    }

    this.getAdminList();
  }

  changePage(newPage: number): void {
    if (newPage >= 1 && newPage <= this.totalPages!) {
      this.currentPage = newPage;
      this.queryParams.skip = this.currentPage;
      this.getAdminList();
    }
  }
}
