<button
  class="flex items-center justify-center gap-1 bg-main-yellow border-2 border-main-yellow rounded-3xl px-6 py-1 text-base w-full md:text-lg disabled:bg-[#D5D5D5] disabled:border-[#D5D5D5] disabled:text-[#6F6F6F] disabled:hover:scale-100 hover:bg-[#F7b400]"
  [type]="type ? type : 'submit'"
  (click)="onClickButton($event)"
  [disabled]="isDisable"
>
  <mat-icon *ngIf="icon && isIconLeft">{{ icon }}</mat-icon>
  <span class="font-normal">{{ content | transloco }}</span>
  <mat-icon *ngIf="icon && !isIconLeft">{{ icon }}</mat-icon>
</button>
