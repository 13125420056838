import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';
import { UserData } from 'src/app/shared/model/user.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  userLoginForm!: FormGroup;
  errmsg = '';
  user!: UserData;
  isLoading = false;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    public dialogRef: MatDialogRef<LoginComponent>,
    private userService: UserService,
    private storageService: StorageService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.loadRememberMeData();
  }

  initForm() {
    this.userLoginForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
      isRemember: false,
    });
  }

  loadRememberMeData() {
    // Load data from local storage
    const storedEmail = localStorage.getItem('rememberEmail');
    if (storedEmail) {
      this.userLoginForm.get('email')?.setValue(storedEmail);
      this.userLoginForm.get('isRemember')?.setValue(true);
    }
  }

  saveRememberMeData() {
    // Save email to local storage
    const isRemember = this.userLoginForm.get('isRemember')?.value;
    const email = this.userLoginForm.get('email')?.value;
    if (isRemember) {
      localStorage.setItem('rememberEmail', email);
    } else {
      localStorage.removeItem('rememberEmail');
    }
  }

  getUser() {
    this.userService.getProfile().subscribe((data) => {
      this.user = data.data;
      this.storageService.store('profile', this.user);
    });
  }

  onSubmit() {
    this.saveRememberMeData();
    this.errmsg = '';

    if (this.userLoginForm.invalid) {
      this.userLoginForm.markAllAsTouched();
    } else {
      this.isLoading = true;
      const bodyLogin = {
        email: this.userLoginForm.value.email,
        password: this.userLoginForm.value.password,
      };

      this.authService.login(bodyLogin.email, bodyLogin.password).subscribe({
        next: (data) => {
          this.getUser();
          this.isLoading = false;
          this.dialogRef.close(true);
        },
        error: (err) => {
          this.errmsg = err.statusMessage;
          this.isLoading = false;
          this.messageService.add({
            severity: 'error',
            summary: 'Login Failed',
            detail: 'Please try again!',
            life: 2000,
          });
        },
      });
    }
  }
}
