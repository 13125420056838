import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transformDate',
})
export class TransformDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}
  transform(
    date: string,
    format: string = 'dd/MM/yyyy HH:mm:ss'
  ): string | null {
    if (!date) return '';

    const dateParts = date.split(' '); // Splitting original date
    const [day, month, year] = dateParts[0].split('/'); // Extract the date
    const [hour, minute, second] = dateParts[1].split(':'); // Extract the time

    // Create a new date object in the desired timezone (UTC+7)
    const zonedDate = new Date(
      Number(year),
      Number(month) - 1,
      Number(day),
      Number(hour) + 7,
      Number(minute),
      Number(second)
    ); // Adding 7 hours

    // Apply formatting using DatePipe with the specified or default format
    return this.datePipe.transform(zonedDate, format);
  }
}
