<div class="p-5">
  <div class="flex flex-col items-center">
    <div class="my-8">
      <mat-icon class="transform scale-[5]">error_outline</mat-icon>
    </div>
    <!-- redeem offer -->
    <div>
      <h1 class="text-2xl tracking-wide text-center mt-10">
        Are you sure you want to leave this page?
      </h1>

      <p class="opacity-50 mt-5 text-center">
        If you proceed to leave this page, <br />
        any unsaved changes in the form will be discarded.
      </p>
    </div>

    <div class="w-full mt-10 grid grid-cols-1 md:grid-cols-2 gap-4">
      <app-primary-button
        [mat-dialog-close]="true"
        content="Yes"
      ></app-primary-button>

      <app-secondary-button
        content="No"
        [mat-dialog-close]="false"
      ></app-secondary-button>
    </div>
  </div>
</div>
