import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';

import { AdminLoginComponent } from './pages/admin-login/admin-login.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoService } from '@jsverse/transloco';
import { AdminForgetPasswordComponent } from './pages/admin-forget-password/admin-forget-password.component';
import { MaterialModule } from 'src/app/shared/material.module';
import { AdminOffersComponent } from './pages/admin-offers/admin-offers.component';
import { AdminDashboardComponent } from './pages/admin-dashboard/admin-dashboard.component';
import { AdminCustomerListComponent } from './pages/admin-customer-list/admin-customer-list.component';
import { AdminManagementComponent } from './pages/admin-management/admin-management.component';
import { AdminProfileComponent } from './pages/admin-profile/admin-profile.component';
import { AdminChangePasswordComponent } from './pages/admin-change-password/admin-change-password.component';
import { AdminInviteAdminComponent } from './pages/admin-invite-admin/admin-invite-admin.component';
import { AdminCreateOfferComponent } from './pages/admin-create-offer/admin-create-offer.component';
import { EditorModule } from 'primeng/editor';
import { AdminResetPasswordComponent } from './pages/admin-reset-password/admin-reset-password.component';
import { AdminManagementDetailComponent } from './pages/admin-management-detail/admin-management-detail.component';
import { ChartModule } from 'primeng/chart';
import { AdminCustomerDetailComponent } from './pages/admin-customer-detail/admin-customer-detail.component';
import { AdminOfferDetailComponent } from './pages/admin-offer-detail/admin-offer-detail.component';
import { AdminEditQuotaComponent } from './pages/admin-edit-quota/admin-edit-quota.component';

@NgModule({
  declarations: [
    AdminLoginComponent,
    AdminForgetPasswordComponent,
    AdminOffersComponent,
    AdminDashboardComponent,
    AdminCustomerListComponent,
    AdminManagementComponent,
    AdminProfileComponent,
    AdminChangePasswordComponent,
    AdminInviteAdminComponent,
    AdminCreateOfferComponent,
    AdminResetPasswordComponent,
    AdminManagementDetailComponent,
    AdminCustomerDetailComponent,
    AdminOfferDetailComponent,
    AdminEditQuotaComponent,
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    EditorModule,
    ChartModule,
  ],
  providers: [TranslocoService],
})
export class AdminModule {}
