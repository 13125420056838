import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = environment.apiUrl; // Replace with your actual API URL
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  private refreshTokenSubject: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(null);

  constructor(private http: HttpClient, private route: Router) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem('currentUser')!)
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    return this.http
      .post<any>(`${this.apiUrl}auth/signin`, { email, password })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage
          const { data } = user;
          localStorage.setItem('currentUser', JSON.stringify(data));
          this.currentUserSubject.next(data);
          return user;
        }),
        catchError(this.handleError)
      );
  }

  logout(isAdmin?: boolean) {
    // remove user from local storage to log user out
    this.http.get<any>(`${this.apiUrl}auth/logout`).subscribe((result: any) => {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('profile');
      this.currentUserSubject.next(null);
      if (isAdmin) {
        this.route.navigate(['admin/login']);
      } else {
        this.route.navigate(['/']);
      }
    });
  }

  // Function to notify subscribers when the refresh token is updated
  public notifyRefreshTokenUpdated(token: string | null): void {
    this.refreshTokenSubject.next(token);
  }

  // Getter for the observable to subscribe to refresh token updates
  public get refreshTokenObservable(): Observable<any> {
    return this.refreshTokenSubject.asObservable();
  }

  RefreshToken(): Observable<any> {
    const currentUser = localStorage.getItem('currentUser');
    let refreshToken;
    if (currentUser) {
      const parseData = JSON.parse(currentUser);
      refreshToken = parseData.refreshToken ? parseData.refreshToken : null;
    } else {
      refreshToken = null;
    }

    let header = new HttpHeaders({
      Authorization: 'Bearer ' + refreshToken,
    });
    return this.http.get<any>(`${this.apiUrl}auth/refresh`, {
      headers: header,
    });
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      console.error('service::error ', error.error);
    }
    let errorMessage = '';
    if (Array.isArray(error.error.statusMessage)) {
      errorMessage = error.error.statusMessage.toString();
    } else {
      errorMessage = error.error.statusMessage;
    }
    // console.error('offer.service::errorMessage:: ', errorMessage);
    return throwError(() => error.error);
  }
}
