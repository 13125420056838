<div class="container mx-auto pt-10 bg-white px-20 mb-20">
  <a
    [routerLink]="['/admin/offer', id]"
    class="flex items-center gap-2 cursor-pointer text-main-blue"
  >
    <mat-icon>arrow_back</mat-icon>
    <span>Offer info</span>
  </a>

  <h1 class="text-4xl mt-10 mb-14">Edit redemption quota</h1>
  <form [formGroup]="editQuotaForm" (ngSubmit)="onSubmit()">
    <div
      class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl p-6 mt-5"
    >
      <div class="mb-5">
        <label for="formUpload" class="block text-sm font-medium mb-2 ms-2">
          Upload Code File
        </label>
        <input
          #fileRef
          type="file"
          id="formUpload"
          name="formUpload"
          (change)="onFileSelected($event)"
          hidden
          accept=".csv"
        />

        <div
          class="my-3 border-2 p-2 w-96 flex gap-2 items-center relative"
          *ngIf="selectedFile"
        >
          <div class="w-20 h-20 flex items-center justify-center">
            <img src="../assets/icon/csv.png" alt="csv icon" />
          </div>
          <div class="flex flex-col gap-1">
            <h1>{{ selectedFileName }}</h1>
            <span class="text-xs opacity-50">{{ selectedFileSize }} KB</span>
          </div>
          <button
            class="absolute right-0 top-0"
            type="button"
            (click)="onDeleteFile('formUpload')"
          >
            <mat-icon class="opacity-50">close</mat-icon>
          </button>
        </div>

        <div
          class="bg-[#F7F7F7] p-5 flex items-center justify-between"
          [ngClass]="
            editQuotaForm.controls['formUpload'].invalid &&
            editQuotaForm.controls['formUpload'].touched
              ? 'border-2 border-main-red'
              : ''
          "
        >
          <h1>Upload only .csv file</h1>

          <div class="w-36">
            <app-primary-button
              content="Browse"
              (onClick)="onBrowseClick()"
              type="button"
            ></app-primary-button>
          </div>
        </div>

        <span
          class="text-main-red text-sm font-light"
          *ngIf="
            editQuotaForm.controls['formUpload']?.invalid &&
            editQuotaForm.controls['formUpload']?.touched
          "
          >***{{ "Filled information are incorrect" | transloco }}</span
        >
      </div>
      <div class="mt-2 mb-4">
        <h1 class="mb-2 text-center">
          *Your CSV file MUST contain the following columns in this order: "no"
          and "code".*
        </h1>
        <mat-accordion>
          <mat-expansion-panel
            (opened)="panelOpenState = true"
            (closed)="panelOpenState = false"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                Click to {{ panelOpenState ? "close" : "show" }} the example
                file pattern
              </mat-panel-title>
            </mat-expansion-panel-header>
            <img
              src="../assets/examplecsv.png"
              alt="example csv"
            /> </mat-expansion-panel
        ></mat-accordion>
      </div>
      <div class="text-sm">
        <h1>CUATION!!</h1>
        <ul class="list-disc ps-4">
          <li class="list item">
            This action will permanently increase your quota.
          </li>
          <li class="list item">
            Duplicate codes within the file or from previous uploads will be
            accepted, BUT:
            <ol class="list-decimal ps-4">
              <li>This will result in duplicate redemption codes</li>
              <li>
                Duplicate redemption codes may cause issues or errors when users
                attempt to use them.
              </li>
            </ol>
          </li>
          <li class="list item">
            To avoid potential problems for your users, carefully review your
            data for duplicate codes before uploading.
          </li>
        </ul>
      </div>
    </div>
    <div class="flex items-center gap-5 mt-10">
      <div class="w-56">
        <app-primary-button
          content="Save change"
          *ngIf="!isLoading"
        ></app-primary-button>
        <app-loading-button *ngIf="isLoading"></app-loading-button>
      </div>
      <div class="w-40">
        <app-secondary-button
          content="Cancel"
          [isDisable]="isLoading"
          [routerLink]="['/admin/offer', id]"
        ></app-secondary-button>
      </div>
    </div>
    <p *ngIf="errMsg" class="mt-2.5 text-main-red">{{ errMsg }}</p>
  </form>
</div>
