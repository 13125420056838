<div class="container mx-auto pt-10 bg-white px-20 mb-20">
  <div class="mb-10 flex items-center justify-between">
    <h1 class="text-4xl">Offers</h1>
    <div class="w-52">
      <app-primary-button
        routerLink="/admin/create-offer"
        content="Create Offer"
        icon="add"
        [isDisable]="isLoading"
      ></app-primary-button>
    </div>
  </div>

  <div class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl p-6">
    <h1 class="text-2xl font-medium mb-5">Filter</h1>
    <div class="grid grid-cols-2 gap-4 w-full">
      <div class="mb-5 mt-10 md:m-0">
        <label for="type" class="block text-sm font-medium mb-2 ms-2">
          Offer type
        </label>
        <mat-select
          id="type"
          [(ngModel)]="queryParams.offerType"
          name="type"
          placeholder="All Type"
          class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
        >
          <mat-option *ngFor="let type of offerType" [value]="type.viewValue">
            {{ type.view }}
          </mat-option>
        </mat-select>
      </div>
      <!-- <div class="mb-5 mt-10 md:m-0 relative">
        <label for="offerStart" class="block text-sm font-medium mb-2 ms-2">
          Date Added
        </label>
        <input
          id="offerStart"
          name="offerStart"
          class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
          matInput
          [matDatepicker]="offerStart"
          placeholder="DD/MM/YY"
          [(ngModel)]="queryParams.offerStart"
        />
        <div class="absolute right-0 top-7">
          <mat-datepicker-toggle matIconSuffix [for]="offerStart">
          </mat-datepicker-toggle>
        </div>
        <mat-datepicker #offerStart></mat-datepicker>
      </div> -->
      <!-- <div class="mb-5 mt-10 md:m-0 relative">
        <label for="offerEnded" class="block text-sm font-medium mb-2 ms-2">
          Date Expired
        </label>
        <input
          id="offerEnded"
          name="offerEnded"
          class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
          matInput
          [matDatepicker]="offerEnded"
          placeholder="DD/MM/YY"
          [(ngModel)]="queryParams.offerEnded"
        />
        <div class="absolute right-0 top-7">
          <mat-datepicker-toggle matIconSuffix [for]="offerEnded">
          </mat-datepicker-toggle>
        </div>
        <mat-datepicker #offerEnded></mat-datepicker>
      </div> -->

      <!-- <div class="mb-5 mt-10 md:m-0 relative">
        <label for="dateExpire" class="block text-sm font-medium mb-2 ms-2">{{
          "Date Expire" | transloco
        }}</label>
        <mat-date-range-input
          id="dateExpire"
          name="dateExpire"
          class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
          [rangePicker]="dateExpire"
        >
          <input
            matStartDate
            placeholder="Start date"
            [(ngModel)]="queryParams.offerEndFrom"
          />
          <input
            matEndDate
            placeholder="End date"
            [(ngModel)]="queryParams.offerEndTo"
          />
        </mat-date-range-input>
        <div class="absolute right-0 top-7">
          <mat-datepicker-toggle
            matIconSuffix
            [for]="dateExpire"
          ></mat-datepicker-toggle>
        </div>

        <mat-date-range-picker #dateExpire></mat-date-range-picker>
      </div> -->
      <div class="mb-5 mt-10 md:m-0">
        <label for="status" class="block text-sm font-medium mb-2 ms-2">
          Status
        </label>
        <mat-select
          id="status"
          [(ngModel)]="queryParams.status"
          name="status"
          placeholder="All Status"
          class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
        >
          <mat-option *ngFor="let item of status" [value]="item.viewValue">
            {{ item.view }}
          </mat-option>
        </mat-select>
      </div>
      <div class="mb-5 mt-10 md:m-0">
        <label for="category" class="block text-sm font-medium mb-2 ms-2">
          category
        </label>
        <mat-select
          id="category"
          [(ngModel)]="queryParams.category"
          name="category"
          placeholder="All category"
          class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
        >
          <mat-option *ngFor="let item of categories" [value]="item.viewValue">
            {{ item.view }}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div class="flex gap-5 mt-10">
      <div class="w-52">
        <app-primary-button
          (onClick)="applyFilter()"
          content="Apply Filters"
          *ngIf="!isLoading"
        ></app-primary-button>
        <app-loading-button *ngIf="isLoading"></app-loading-button>
      </div>
      <div class="w-44">
        <app-secondary-button
          (onClick)="resetFilter()"
          content="Reset Filters"
        ></app-secondary-button>
      </div>
    </div>
  </div>

  <div class="w-72 mt-10 relative">
    <div class="flex gap-2 mb-2 items-center">
      <label class="text-xl ps-2" for="search">Search </label>
      <mat-icon>search</mat-icon>
    </div>
    <input
      type="search"
      id="search"
      name="search"
      class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
      placeholder="Offer Name, Merchant Name"
      [(ngModel)]="queryParams.search"
    />
    <!-- <div
      class="absolute inset-y-0 right-0 flex items-center justify-center cursor-pointer"
      *ngIf="queryParams.search"
    >
      <mat-icon>clear</mat-icon>
    </div> -->
  </div>

  <div class="my-10" *ngIf="totalCount">
    <div class="text-2xl">
      Total: <span>{{ totalCount }}</span>
    </div>
  </div>
  <div class="overflow-x-auto" *ngIf="offerList">
    <table class="min-w-full table-fixed border-separate border-spacing-2">
      <thead>
        <tr class="text-left cursor-pointer">
          <th
            class="pe-2 bg-[#F4F4F4] font-light relative text-center"
            (click)="sortBy('merchantName')"
          >
            <div class="flex items-center justify-center group">
              <span class="text-center">Merchant name</span>
              <mat-icon
                class="absolute right-0 opacity-30 transform scale-75 invisible group-hover:visible"
                *ngIf="queryParams.sortBy !== 'merchantName'"
                >arrow_upward</mat-icon
              >
              <mat-icon
                class="absolute right-0 transform scale-75"
                *ngIf="
                  queryParams.sortBy === 'merchantName' &&
                  queryParams.sortOrderBy === 'asc'
                "
                >arrow_upward</mat-icon
              >
              <mat-icon
                class="absolute right-0 transform scale-75"
                *ngIf="
                  queryParams.sortBy === 'merchantName' &&
                  queryParams.sortOrderBy === 'desc'
                "
                >arrow_downward</mat-icon
              >
            </div>
          </th>
          <th class="pe-2 bg-[#F4F4F4] font-light relative text-center">
            <span class="text-center">Offer type</span>
          </th>
          <th
            class="pe-2 bg-[#F4F4F4] font-light relative text-center"
            (click)="sortBy('offerStart')"
          >
            <div class="flex items-center justify-center group">
              <span class="text-center">Date start</span>
              <mat-icon
                class="absolute right-0 opacity-30 transform scale-75 invisible group-hover:visible"
                *ngIf="queryParams.sortBy !== 'offerStart'"
                >arrow_upward</mat-icon
              >
              <mat-icon
                class="absolute right-0 transform scale-75"
                *ngIf="
                  queryParams.sortBy === 'offerStart' &&
                  queryParams.sortOrderBy === 'asc'
                "
                >arrow_upward</mat-icon
              >
              <mat-icon
                class="absolute right-0 transform scale-75"
                *ngIf="
                  queryParams.sortBy === 'offerStart' &&
                  queryParams.sortOrderBy === 'desc'
                "
                >arrow_downward</mat-icon
              >
            </div>
          </th>
          <th
            class="pe-2 bg-[#F4F4F4] font-light relative text-center"
            (click)="sortBy('offerEnd')"
          >
            <div class="flex items-center justify-center group">
              <span class="text-center">Date expiration</span>
              <mat-icon
                class="absolute right-0 opacity-30 transform scale-75 invisible group-hover:visible"
                *ngIf="queryParams.sortBy !== 'offerEnd'"
                >arrow_upward</mat-icon
              >
              <mat-icon
                class="absolute right-0 transform scale-75"
                *ngIf="
                  queryParams.sortBy === 'offerEnd' &&
                  queryParams.sortOrderBy === 'asc'
                "
                >arrow_upward</mat-icon
              >
              <mat-icon
                class="absolute right-0 transform scale-75"
                *ngIf="
                  queryParams.sortBy === 'offerEnd' &&
                  queryParams.sortOrderBy === 'desc'
                "
                >arrow_downward</mat-icon
              >
            </div>
          </th>
          <th
            class="pe-4 bg-[#F4F4F4] font-light relative text-center"
            (click)="sortBy('offetLimit')"
          >
            <div class="flex items-center justify-center group">
              <span class="text-center">Redemption</span>
              <mat-icon
                class="absolute right-0 opacity-30 transform scale-75 invisible group-hover:visible"
                *ngIf="queryParams.sortBy !== 'offetLimit'"
                >arrow_upward</mat-icon
              >
              <mat-icon
                class="absolute right-0 transform scale-75"
                *ngIf="
                  queryParams.sortBy === 'offetLimit' &&
                  queryParams.sortOrderBy === 'asc'
                "
                >arrow_upward</mat-icon
              >
              <mat-icon
                class="absolute right-0 transform scale-75"
                *ngIf="
                  queryParams.sortBy === 'offetLimit' &&
                  queryParams.sortOrderBy === 'desc'
                "
                >arrow_downward</mat-icon
              >
            </div>
          </th>
          <th class="pe-2 bg-[#F4F4F4] font-light relative text-center">
            <span class="text-center">Status</span>
          </th>
          <th class="pe-2 bg-[#F4F4F4] font-light relative text-center">
            <span class="text-center">Action</span>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          class="bg-white border-2 shadow shadow-gray-300 rounded-lg mb-3 transition duration-150 ease-in-out hover:bg-gray-100"
          *ngFor="let offer of offerList"
        >
          <td
            class="px-6 py-4 rounded-tl-lg rounded-bl-lg truncate text-blue-600 max-w-48 relative"
          >
            <div
              class="absolute right-0 top-0 text-black"
              [matTooltip]="offer.offerNameEN"
            >
              <mat-icon>info</mat-icon>
            </div>

            {{ offer.merchantNameEN }}
          </td>
          <td class="px-6 py-4 truncate text-center">{{ offer.offerType }}</td>
          <td class="px-6 py-4 truncate text-center">{{ offer.offerStart }}</td>
          <td class="px-6 py-4 truncate text-center">{{ offer.offerEnd }}</td>
          <td class="px-6 py-4 truncate text-center">
            {{ offer?.offerLimit ? offer.offerLimit : "-" }}
          </td>
          <td
            class="px-6 py-4 truncate text-center"
            [ngClass]="
              offer.status === 'start'
                ? 'text-[#20C371]'
                : offer.status === 'pause'
                ? 'text-yellow-500'
                : 'text-main-red'
            "
          >
            {{ offer.status.toUpperCase() }}
          </td>
          <td class="py-4 px-2 rounded-tr-lg rounded-br-lg truncate">
            <div
              class="flex items-center justify-end gap-1 text-blue-600 cursor-pointer"
              [routerLink]="['/admin/offer', offer._id]"
            >
              <span>Offer info</span><mat-icon>arrow_forward</mat-icon>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="flex justify-center items-center mt-4">
    <div class="flex items-center cursor-pointer">
      <mat-icon
        (click)="changePage(currentPage - 1)"
        [ngClass]="{ disabled: currentPage === 1 }"
        >chevron_left</mat-icon
      >
      <span>{{ currentPage }}/{{ totalPages }}</span>
      <mat-icon
        (click)="changePage(currentPage + 1)"
        [ngClass]="{ disabled: currentPage === totalPages }"
        >chevron_right</mat-icon
      >
    </div>
  </div>
</div>
