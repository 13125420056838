<div class="p-5">
  <h1 class="text-5xl font-[Ontima-bold] font-bold sm:w-[480px] w-64">
    {{ "Edit Profile" | transloco }}
  </h1>
  <form [formGroup]="editProfileForm" (ngSubmit)="onSubmit()" class="mt-8">
    <div class="mb-4">
      <label for="name" class="block text-sm font-medium mb-2 ms-2">{{
        "Firstname" | transloco
      }}</label>
      <input
        type="text"
        id="name"
        name="name"
        required
        class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
        [placeholder]="'Firstname' | transloco"
        formControlName="name"
        [ngClass]="
          editProfileForm.controls['name'].invalid &&
          editProfileForm.controls['name'].touched
            ? 'border-2 border-main-red'
            : ''
        "
      />
      <span
        class="text-main-red text-sm font-light"
        *ngIf="
          editProfileForm.controls['name']?.invalid &&
          editProfileForm.controls['name']?.touched
        "
        >***{{ "Filled information are incorrect" | transloco }}</span
      >
    </div>
    <div class="mb-4">
      <label for="lastname" class="block text-sm font-medium mb-2 ms-2">{{
        "Lastname" | transloco
      }}</label>
      <input
        type="text"
        id="lastname"
        name="lastname"
        required
        class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
        [placeholder]="'Lastname' | transloco"
        formControlName="lastName"
        [ngClass]="
          editProfileForm.controls['lastName'].invalid &&
          editProfileForm.controls['lastName'].touched
            ? 'border-2 border-main-red'
            : ''
        "
      />
      <span
        class="text-main-red text-sm font-light"
        *ngIf="
          editProfileForm.controls['lastName']?.invalid &&
          editProfileForm.controls['lastName']?.touched
        "
        >***{{ "Filled information are incorrect" | transloco }}</span
      >
    </div>
    <div class="mb-4" *ngIf="userData">
      <label for="card" class="block text-sm font-medium mb-2 ms-2">{{
        "Card no." | transloco
      }}</label>
      <input
        type="text"
        id="card"
        name="card"
        required
        minlength="25"
        class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
        placeholder="_ _ _ _ - _ _ XX - XXXX - _ _ _ _"
        formControlName="card"
        (input)="formatCardNumber($event)"
        [ngClass]="
          editProfileForm.controls['card'].invalid &&
          editProfileForm.controls['card'].touched
            ? 'border-2 border-main-red'
            : ''
        "
      />
      <span
        class="text-main-red text-sm font-light"
        *ngIf="
          editProfileForm.controls['card']?.invalid &&
          editProfileForm.controls['card']?.touched
        "
        >***{{ "Filled information are incorrect" | transloco }}</span
      >
      <p class="mt-2 text-sm">
        {{ "6 digits first and 4 digits last" | transloco }}
      </p>
    </div>
    <div class="mb-4">
      <label for="phone" class="block text-sm font-medium mb-2 ms-2">{{
        "Phone number" | transloco
      }}</label>
      <input
        type="text"
        id="phone"
        name="phone"
        required
        class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
        [placeholder]="'Phone number' | transloco"
        formControlName="phone"
        [ngClass]="
          editProfileForm.controls['phone'].invalid &&
          editProfileForm.controls['phone'].touched
            ? 'border-2 border-main-red'
            : ''
        "
      />
      <span
        class="text-main-red text-sm font-light"
        *ngIf="
          editProfileForm.controls['phone']?.invalid &&
          editProfileForm.controls['phone']?.touched
        "
        >***{{ "Filled information are incorrect" | transloco }}</span
      >
    </div>
    <div class="grid grid-cols-1 sm:flex gap-5 mt-12">
      <ng-container *ngIf="!isLoading">
        <app-primary-button content="Save change"></app-primary-button>
        <app-secondary-button
          content="Cancel"
          mat-dialog-close
        ></app-secondary-button>
      </ng-container>
      <div class="sm:w-44 w-full" *ngIf="isLoading">
        <app-loading-button></app-loading-button>
      </div>
    </div>
  </form>
</div>
