import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AdminConfirmationComponent } from 'src/app/core/components/admin-confirmation/admin-confirmation.component';

@Component({
  selector: 'app-admin-edit-quota',
  templateUrl: './admin-edit-quota.component.html',
  styleUrls: ['./admin-edit-quota.component.scss'],
})
export class AdminEditQuotaComponent implements OnInit {
  @ViewChild('fileRef') fileRef!: ElementRef;
  id!: string;
  selectedFile!: File | null;
  selectedFileName!: string;
  selectedFileSize!: number | null;
  editQuotaForm!: FormGroup;
  isLoading = false;
  errMsg = '';
  panelOpenState = false;

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'] as string;
    this.initForm();
  }

  initForm() {
    this.editQuotaForm = this.fb.group({
      formUpload: [null, Validators.required],
    });
  }

  onOpenConfirmation(formUpload: any) {
    const dialogRef = this.matDialog.open(AdminConfirmationComponent, {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      width: '600px',
      data: {
        location: window.location.pathname,
        offerData: { formUpload: formUpload, id: this.id },
      },
      disableClose: true,
    });
  }

  onFileSelected(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files;

    if (files && files.length > 0) {
      const file = files[0];
      console.log(file);
      if (file.type.includes('csv')) {
        this.selectedFile = file;
        this.selectedFileName = this.selectedFile.name;
        this.selectedFileSize = this.selectedFile.size;
        this.editQuotaForm.patchValue({ formUpload: this.selectedFile });
      } else {
        alert('Please upload a valid file (only csv).');
      }
    }
  }

  onBrowseClick() {
    const inputElement: HTMLInputElement = this.fileRef.nativeElement;
    inputElement.click();
  }

  onDeleteFile(inputName: string) {
    if (inputName === 'formUpload') {
      this.selectedFile = null;
      this.selectedFileName = '';
      this.selectedFileSize = null;
      this.editQuotaForm.patchValue({ formUpload: null });
      this.editQuotaForm.get('formUpload')?.markAsTouched();
    }
  }

  onSubmit() {
    if (this.editQuotaForm.invalid) {
      this.editQuotaForm.markAllAsTouched();
    } else {
      this.onOpenConfirmation(this.editQuotaForm.value.formUpload);
    }
  }
}
