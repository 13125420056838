<div class="flex flex-col w-screen min-h-screen">
  <div class="flex-grow flex flex-col items-center justify-center p-10">
    <h1 class="text-4xl font-medium text-center text-main-blue">
      {{ "Page Not Found" | transloco }}
    </h1>
    <p class="text-xl opacity-50 mt-3 text-center">
      {{ "We can't seem to find the page you're looking for" | transloco }}
    </p>
    <div class="mt-10">
      <app-primary-button
        content="Go Back to Homepage"
        routerLink="/"
      ></app-primary-button>
    </div>
  </div>
  <app-footer></app-footer>
</div>
