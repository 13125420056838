<div class="mx-auto flex items-center justify-center h-screen bg-[#F7F7F7]">
  <div
    class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl w-[494px] p-5"
  >
    <a
      routerLink="/admin"
      class="flex items-center gap-2 cursor-pointer text-blue-500"
    >
      <mat-icon>arrow_back</mat-icon>
      <span>Back</span>
    </a>
    <h1 class="text-4xl mb-10 mt-5">{{ "Reset Password" | transloco }}</h1>
    <form [formGroup]="changeForm" (ngSubmit)="onSubmit()">
      <div class="mb-10 flex flex-col gap-5">
        <div>
          <label
            for="newPassword"
            class="block text-sm font-medium mb-2 ms-2"
            >{{ "New password" | transloco }}</label
          >
          <input
            type="password"
            id="newPassword"
            name="newPassword"
            required
            class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
            [placeholder]="'New password' | transloco"
            formControlName="newPassword"
            [ngClass]="
              (changeForm.controls['newPassword'].invalid &&
                changeForm.controls['newPassword'].touched) ||
              errMsg
                ? 'border-2 border-main-red'
                : ''
            "
          />
          <span
            class="text-main-red text-sm font-light"
            *ngIf="
              changeForm.controls['newPassword']?.invalid &&
              changeForm.controls['newPassword']?.touched
            "
            >***{{ "Filled information are incorrect" | transloco }}</span
          >
        </div>
        <div>
          <label
            for="cNewPassword"
            class="block text-sm font-medium mb-2 ms-2"
            >{{ "Confirm new password" | transloco }}</label
          >
          <input
            type="password"
            id="cNewPassword"
            name="cNewPassword"
            required
            class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
            [placeholder]="'Confirm new password' | transloco"
            formControlName="cNewPassword"
            [ngClass]="
              changeForm.controls['cNewPassword'].touched &&
              changeForm.controls['newPassword'].value !==
                changeForm.controls['cNewPassword'].value
                ? 'border-2 border-main-red'
                : ''
            "
          />
          <span
            class="text-main-red text-sm font-light"
            *ngIf="
              changeForm.controls['cNewPassword'].touched &&
              changeForm.controls['newPassword']?.value !==
                changeForm.controls['cNewPassword']?.value
            "
            >***{{ "Filled information are incorrect" | transloco }}</span
          >
        </div>
        <p class="text-main-red" *ngIf="errMsg">{{ errMsg }}</p>
      </div>
      <div class="sm:w-52">
        <app-loading-button *ngIf="isLoading"></app-loading-button>
        <app-primary-button
          *ngIf="!isLoading"
          content="Save change"
        ></app-primary-button>
      </div>
    </form>
  </div>
</div>
