<div
  [ngClass]="
    isAdjustMargin
      ? 'sm:ml-24 sm:mr-24 md:ml-44 md:mr-44 xl:ml-96 xl:mr-96'
      : ''
  "
>
  <p-carousel
    [value]="imageArr"
    [numVisible]="5"
    [numScroll]="3"
    [circular]="true"
    [responsiveOptions]="responsiveOptions"
    [autoplayInterval]="3000"
    [showIndicators]="false"
    [showNavigators]="false"
  >
    <ng-template let-image pTemplate="item">
      <div
        [ngClass]="
          image.includes('T2P') ? 'p-5 ' : isAdjustSize ? 'w-36  sm:w-52 ' : ''
        "
        class="w-24 h-24 sm:w-40 sm:h-40 overflow-hidden mx-auto"
      >
        <img
          class="w-full h-full object-contain"
          [src]="image"
          alt="sponsor image"
        />
      </div>
    </ng-template>
  </p-carousel>
</div>
