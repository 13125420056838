import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../../services/admin.service';
import { AuthService } from 'src/app/services/auth.service';
import { CompletedPopupComponent } from 'src/app/core/components/completed-popup/completed-popup.component';
import { UserService } from 'src/app/services/user.service';
import { Subscription } from 'rxjs';
import { UserAuthData } from 'src/app/shared/model/user.model';

@Component({
  selector: 'app-admin-reset-password',
  templateUrl: './admin-reset-password.component.html',
  styleUrls: ['./admin-reset-password.component.scss'],
})
export class AdminResetPasswordComponent implements OnInit, OnDestroy {
  changeForm!: FormGroup;
  errMsg = '';
  token: any;
  subscription!: Subscription;
  isLoading = false;
  constructor(
    private fb: FormBuilder,
    public matDialog: MatDialog,
    private adminService: AdminService,
    private userService: UserService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams['token'];
    this.subscription = this.authService.currentUser.subscribe(
      (data: UserAuthData | null) => {
        const isToken = Boolean(this.token);
        const isUser = Boolean(data);

        if (isUser) {
          if (data?.isFirstTime) {
          } else {
            this.router.navigate(['admin/login']);
          }
        } else if (!isToken) {
          this.router.navigate(['admin/login']);
        }
      }
    );
    this.initForm();
  }
  initForm() {
    this.changeForm = this.fb.group({
      newPassword: [null, Validators.required],
      cNewPassword: [null, Validators.required],
    });
  }

  formSummary() {
    const changeData = {
      password: this.changeForm.value.newPassword,
    };

    return changeData;
  }

  onOpenCompleted() {
    const dialogRef = this.matDialog.open(CompletedPopupComponent, {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      width: '600px',
      data: { location: window.location.pathname },
      disableClose: true,
    });
  }

  onSubmit() {
    this.errMsg = '';
    if (this.changeForm.invalid) {
      this.changeForm.markAllAsTouched();
    } else if (
      this.changeForm.controls['newPassword']?.value !==
      this.changeForm.controls['cNewPassword']?.value
    ) {
      alert('please correct the confirm password');
    } else {
      this.isLoading = true;
      const changeData = this.formSummary();
      if (this.token) {
        this.userService.resetPassword(changeData, this.token).subscribe({
          next: (data) => {
            this.isLoading = false;
            console.log(data);
          },
          error: (err) => {
            this.errMsg = err.statusMessage;
            this.isLoading = false;
          },
          complete: () => {
            this.isLoading = false;
            this.onOpenCompleted();
          },
        });
      } else {
        this.adminService.resetPassword(changeData).subscribe({
          next: (data) => {
            this.isLoading = false;
            console.log(data);
          },
          error: (err) => {
            this.errMsg = err.statusMessage;
            this.isLoading = false;
          },
          complete: () => {
            this.isLoading = false;
            this.onOpenCompleted();
          },
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
