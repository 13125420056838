import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';
import { Role } from 'src/app/shared/enums/roles';
import { UserAuthData, UserData } from 'src/app/shared/model/user.model';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss'],
})
export class AdminLoginComponent implements OnInit, OnDestroy {
  loginForm!: FormGroup;
  errmsg = '';
  subscription!: Subscription;
  isLoading = false;
  user!: UserData;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    private storageService: StorageService
  ) {}
  ngOnInit(): void {
    this.initForm();
    this.subscription = this.authService.currentUser.subscribe(
      (data: UserAuthData | null) => {
        if (data && data.roles !== Role.USER) {
          if (data.isFirstTime) {
            this.router.navigate(['admin/reset-password']);
          } else {
            this.router.navigate(['admin/dashboard']);
          }
        } else if (data && data.roles === Role.USER) {
          this.router.navigate(['']);
        }
      }
    );
  }

  initForm() {
    this.loginForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
    });
  }

  getUser() {
    this.userService.getProfile().subscribe((data) => {
      this.user = data.data;
      this.storageService.store('profile', this.user);
    });
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
    } else {
      this.isLoading = true;
      const bodyLogin = {
        email: this.loginForm.value.email,
        password: this.loginForm.value.password,
      };
      this.authService.login(bodyLogin.email, bodyLogin.password).subscribe({
        next: (user) => {
          console.log(user);
          this.getUser();
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
          this.errmsg = err.statusMessage;
        },
      });
    }
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
