import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { AdminData } from 'src/app/shared/model/admin.model';
import { AdminConfirmationComponent } from 'src/app/core/components/admin-confirmation/admin-confirmation.component';

@Component({
  selector: 'app-admin-management-detail',
  templateUrl: './admin-management-detail.component.html',
  styleUrls: ['./admin-management-detail.component.scss'],
})
export class AdminManagementDetailComponent implements OnInit {
  isLoading = false;
  id!: string;
  adminData!: AdminData;

  constructor(
    private adminService: AdminService,
    public matDialog: MatDialog,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'] as string;
    this.getAdmin();
  }

  onOpenConfirmation(name: string) {
    const dialogRef = this.matDialog.open(AdminConfirmationComponent, {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      width: '600px',
      data: { location: window.location.pathname, adminData: this.adminData },
      disableClose: true,
    });
  }

  getAdmin() {
    this.isLoading = true;
    this.adminService.getAdminDetail(this.id).subscribe({
      next: (data) => {
        console.log(data);
        this.adminData = data.data;
        this.isLoading = false;
      },
      error: (err) => {
        console.log(err);
        this.isLoading = false;
      },
    });
  }
}
