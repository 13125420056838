<div class="p-5">
  <div class="flex flex-col items-center" *ngIf="data === 'error'">
    <div class="my-8">
      <img src="../assets/icon/error.svg" alt="done icon" />
    </div>
    <h1 class="text-2xl tracking-wide text-center">
      {{ "This offer are no longer useable" | transloco }}
    </h1>

    <p class="opacity-50 mt-5 text-center">
      {{
        "This offer is no longer valid. We apologize for the inconvenience"
          | transloco
      }}
    </p>
    <div class="w-full mt-10">
      <app-primary-button content="Done" mat-dialog-close></app-primary-button>
    </div>
  </div>
  <div class="flex flex-col items-center" *ngIf="data === 'timeleft'">
    <div class="my-8">
      <img src="../assets/icon/error.svg" alt="done icon" />
    </div>
    <h1 class="text-2xl tracking-wide text-center">
      {{ "The time for using this offer code was expired" | transloco }}
    </h1>

    <!-- <p class="opacity-50 mt-5 text-center">
      {{ "" | transloco }}
    </p> -->
    <div class="w-full mt-10">
      <app-primary-button content="Done" mat-dialog-close></app-primary-button>
    </div>
  </div>
</div>
