<div class="p-5">
  <div class="flex flex-col items-center">
    <div class="mb-8">
      <img src="../assets/icon/error.svg" alt="done icon" />
    </div>

    <h1
      class="text-2xl tracking-wide text-center"
      *ngIf="data.location.includes('/admin/management')"
    >
      Are you sure to remove {{ data.adminData.firstName }} from the system?
    </h1>
    <h1
      class="text-2xl tracking-wide text-center"
      *ngIf="data.location.includes('/admin/edit-quota')"
    >
      Are you sure to update quota for this offer?
    </h1>
    <div
      *ngIf="
        data.location.includes('/admin/offer') &&
        data.offerData.action === 'pause'
      "
    >
      <h1
        *ngIf="!data.offerData.isPause"
        class="text-2xl tracking-wide text-center"
      >
        Are you sure to resume this offer for redemption?
      </h1>
      <h1
        *ngIf="data.offerData.isPause"
        class="text-2xl tracking-wide text-center"
      >
        Are you sure to pause this offer for redemption?
      </h1>
      <p *ngIf="data.offerData.isPause" class="opacity-50 mt-5 text-center">
        Customer will unable to redeem this offer while it’s pause
      </p>
    </div>
    <div
      *ngIf="
        data.location.includes('/admin/offer') &&
        data.offerData.action === 'delete'
      "
    >
      <h1 class="text-2xl tracking-wide text-center">
        Are you sure to delete this offer?
      </h1>
      <p class="opacity-50 mt-5 text-center">
        This offer will be permanent deleted
      </p>
    </div>

    <form class="mt-10 w-full" (ngSubmit)="onSubmit()">
      <p class="text-sm text-start w-full ps-2 mb-2">
        Please fill an admin password to confirm this action
      </p>
      <input
        type="password"
        id="password"
        name="password"
        required
        class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
        placeholder="Admin Password"
        [formControl]="password"
        [ngClass]="
          password.invalid && password.touched ? 'border-2 border-main-red' : ''
        "
      />
      <span
        class="text-main-red text-sm font-light"
        *ngIf="password?.invalid && password?.touched"
        >***{{ "Filled information are incorrect" | transloco }}</span
      >
      <div class="flex gap-5 w-full mt-12">
        <div class="w-full">
          <app-delete-button
            content="Confirm action"
            [isLoading]="isLoading"
          ></app-delete-button>
        </div>
        <div class="w-full">
          <app-secondary-button
            [isDisable]="isLoading"
            matDialogClose
            content="Cancel"
          ></app-secondary-button>
        </div>
      </div>
    </form>
  </div>
</div>
