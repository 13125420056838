<div class="p-5 overscroll-auto">
  <div class="flex flex-col items-center" *ngIf="data.isUsaged">
    <!-- redeem offer -->

    <h1 class="text-4xl font-medium tracking-wide text-center">
      {{ "Offer Code" | transloco }}
    </h1>
    <h1 class="text-2xl tracking-wide text-center mt-3">
      {{ lang === "th" ? data.offerName : data.offerNameEN }}
    </h1>
    <p *ngIf="data.offerEnd" class="mt-2.5 text-[#6F6F6F] text-sm">
      Exp. {{ data.offerEnd }}
    </p>

    <!-- <p class="opacity-50 mt-3 md:mt-5 text-center text-sm md:text-base mb-3">
      {{
        "You may now utilize the offer code. Futhermore, we have sent it to your inbox for your convenience in case you wish to use it later"
          | transloco
      }}
    </p> -->
    <!-- <div
      *ngIf="data.isUsaged"
      class="bg-[#F7F7F7] w-full flex items-center justify-around text-sm md:text-2xl p-2 rounded-2xl cursor-pointer mt-3"
    >
      <ng-container *ngFor="let tab of tabs; let i = index">
        <div
          class="bg-[#F7F7F7] w-full h-full flex items-center justify-center rounded-2xl py-4"
          [ngClass]="{ 'tab-item': true, active: i === selectedTab }"
          (click)="selectTab(i)"
        >
          <h1>{{ tab.title }}</h1>
        </div>
      </ng-container>
    </div> -->

    <div class="text-center w-full" *ngIf="!isUseLoading">
      <div
        *ngIf="tabs[selectedTab].value === 'barcode'"
        class="mt-10 flex justify-center items-center"
      >
        <!-- <ngx-barcode6
          [bc-format]="barcodeFormat"
          [bc-value]="data.code"
          [bc-display-value]="true"
          [bc-width]="barcodeWidth"
          [bc-text-margin]="20"
        >
        </ngx-barcode6> -->
        <div>
          <canvas #barcode id="barcode"></canvas>
        </div>
        <app-main-loading *ngIf="isBarcodeLoading"></app-main-loading>
      </div>
      <div
        *ngIf="tabs[selectedTab].value === 'qrcode'"
        class="mt-10 flex justify-center items-center"
      >
        <qrcode
          [qrdata]="data.code"
          [width]="qrWidth"
          [errorCorrectionLevel]="'M'"
          [margin]="0"
        ></qrcode>
      </div>

      <p *ngIf="tabs[selectedTab].value === 'qrcode'" class="mt-4 md:text-xl">
        Ref code: {{ data.code }}
      </p>
      <!-- <span
        *ngIf="tabs[selectedTab].value === 'qrcode' && data.offerEnd"
        class="mt-4 text-[#6F6F6F] text-sm"
        >Exp. {{ data.offerEnd }}</span
      > -->
      <!-- <span
        *ngIf="tabs[selectedTab].value === 'barcode' && data.offerEnd"
        class="mt-4 text-[#6F6F6F] text-sm"
        >Exp. {{ data.offerEnd }}</span
      > -->
      <div
        *ngIf="tabs[selectedTab].value === 'code'"
        class="mt-10 bg-[#F7F7F7] w-full rounded-2xl"
      >
        <div class="py-5">
          <div
            class="md:text-3xl font-medium flex gap-4 justify-center items-center"
          >
            <h1>{{ data.code }}</h1>
            <button
              [cdkCopyToClipboard]="data.code"
              (cdkCopyToClipboardCopied)="isCopy($event)"
              class="flex items-center justify-center active:ring-2 active:ring-green-700 rounded-xl p-2 relative"
            >
              <div
                *ngIf="copied"
                class="absolute top-[-40px] font-medium bg-green-600 rounded-full text-white p-2 text-sm md:text-base"
              >
                copied
              </div>
              <mat-icon>filter_none</mat-icon>
            </button>
          </div>
          <!-- <p *ngIf="data.offerEnd" class="mt-2.5 text-[#6F6F6F] text-sm">
            Exp. {{ data.offerEnd }}
          </p> -->
        </div>
      </div>
      <div class="mt-5">
        This code will expire in:
        <span *ngIf="minutes.toLocaleString().length === 1">0</span
        >{{ minutes }} :
        <span *ngIf="seconds.toLocaleString().length === 1 && seconds !== 0"
          >0</span
        ><span *ngIf="seconds !== 0">{{ seconds }}</span
        ><span *ngIf="seconds === 0">{{ seconds | date : "ss" }}</span>
      </div>
    </div>

    <!-- <div
      class="text-center w-full bg-[#F7F7F7] py-5 px-2.5"
      *ngIf="!data.isUsaged && !isUseLoading"
    >
      <h1 class="text-2xl tracking-wide text-center">
        {{ "Important !!" | transloco }}
      </h1>
      <h1 class="text-2xl tracking-wide text-center">
        {{ "Redeem at Shop Counter Only" | transloco }}
      </h1>

      <p class="opacity-50 mt-5 text-center">
        {{
          "Please note that this offer can only be redeemed at the shop counter. Show the code to the cashier to get your discount."
            | transloco
        }}
      </p> -->
    <!-- <button
        (click)="showCode()"
        class="bg-main-blue text-white px-6 py-2 rounded-full mt-5"
      >
        <span>{{ "Show code" | transloco }}</span>
      </button> -->
    <!-- <div class="flex justify-center items-center mt-5">
        <div class="w-48">
          <app-secondary-button
            content="Show code"
            (onClick)="onOpenConfirmShowCode()"
          ></app-secondary-button>
        </div>
      </div> -->
    <!-- </div> -->

    <!-- <div *ngIf="!data.isHistory && !data.isUsaged" class="mt-2.5">
      {{ "**If you are not ready to use it. you can use it later" | transloco }}
      <span
        class="text-main-blue underline cursor-pointer"
        routerLink="/history"
        [mat-dialog-close]="data.isUsaged"
        >{{ "Here" | transloco }}</span
      >
    </div>

    <p class="text-main-red text-sm mt-2.5">{{ errMsg | transloco }}</p> -->

    <div class="w-full mt-7 md:mt-5">
      <app-primary-button
        [isDisable]="isUseLoading"
        content="Done"
        [mat-dialog-close]="data.isUsaged"
      ></app-primary-button>
    </div>
  </div>

  <div class="flex flex-col items-center" *ngIf="!data.isUsaged">
    <p *ngIf="data.offerEnd" class="text-[#6F6F6F] text-sm">
      Exp. {{ data.offerEnd }}
    </p>
    <div class="md:w-full w-36 md:h-60">
      <img
        class="h-full w-full object-contain"
        src="../assets/Offer-code-using-guide.png"
        alt="example scan image"
      />
    </div>
    <h1 class="text-2xl tracking-wide text-center mt-5">
      {{ "Important !!" | transloco }}
    </h1>
    <h1 class="text-2xl tracking-wide text-center">
      {{ "Redeem at Shop Counter Only" | transloco }}
    </h1>
    <p class="opacity-50 mt-5 text-center text-pretty">
      <span>{{
        "Please note that this offer can only be redeemed at the shop counter."
          | transloco
      }}</span
      ><br />
      <span>{{
        "Show the code to the cashier to get your discount." | transloco
      }}</span>
    </p>

    <div class="w-full mt-10 grid grid-cols-1 sm:grid-cols-2 gap-4">
      <!-- <app-primary-button
        *ngIf="!isLoading"
        content="Confirm & Redeem"
        (onClick)="onConfirm()"
      ></app-primary-button>
      <app-loading-button *ngIf="isLoading"></app-loading-button>
      <app-secondary-button
        content="Cancel"
        [isDisable]="isLoading"
        (onClick)="onClose()"
      ></app-secondary-button> -->
      <app-primary-button
        *ngIf="!isUseLoading"
        content="Show Code"
        (onClick)="useOffer()"
      ></app-primary-button>
      <app-loading-button *ngIf="isUseLoading"></app-loading-button>
      <app-secondary-button
        content="Later"
        [isDisable]="isUseLoading"
        [mat-dialog-close]="data.isUsaged"
      ></app-secondary-button>
    </div>
    <p class="text-main-red text-sm mt-2.5">{{ errMsg | transloco }}</p>
  </div>
</div>
