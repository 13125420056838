import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import {
  IChangePassForm,
  IEditForm,
  IRegisterForm,
} from '../shared/model/form.model';
import { catchError, throwError } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  private apiUrl = environment.apiUrl; // Replace with your actual API URL
  constructor(private http: HttpClient, private route: Router) {}

  register(form: IRegisterForm) {
    return this.http
      .post<any>(`${this.apiUrl}auth/register`, form)
      .pipe(catchError(this.handleError));
  }

  getProfile() {
    return this.http
      .get<any>(`${this.apiUrl}user/profile`)
      .pipe(catchError(this.handleError));
  }

  updateProfile(editData: IEditForm) {
    return this.http
      .put<any>(`${this.apiUrl}user/profile`, editData)
      .pipe(catchError(this.handleError));
  }

  changePassword(passwordData: IChangePassForm) {
    return this.http
      .patch<any>(`${this.apiUrl}user/changePassword`, passwordData)
      .pipe(catchError(this.handleError));
  }

  forgotPassword(email: any) {
    return this.http
      .post<any>(`${this.apiUrl}auth/forgotPassword`, email)
      .pipe(catchError(this.handleError));
  }

  resetPassword(password: any, token: string) {
    return this.http
      .post<any>(`${this.apiUrl}auth/reset/password?token=${token}`, password)
      .pipe(catchError(this.handleError));
  }

  activateUser(id: string) {
    return this.http
      .get<any>(`${this.apiUrl}auth/activate/${id}`)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      console.error('service::error ', error.error);
    }
    // let errorMessage = '';
    // if (Array.isArray(error.error.statusMessage)) {
    //   errorMessage = error.error.statusMessage.toString();
    // } else {
    //   errorMessage = error.error.statusMessage;
    // }
    // console.error('offer.service::errorMessage:: ', errorMessage);
    return throwError(() => error.error);
  }
}
