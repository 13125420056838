import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ValidateBinNumberService {
  constructor() {}
  private miceVisaPrepaid = ['450586'];

  checkPrepaidCardNumber(card: any) {
    const cardNumber = card.split(' - ').join('');

    const sixDigitCardNumber = cardNumber.slice(0, 6);

    return this.prepaidValidate(sixDigitCardNumber);
  }

  private prepaidValidate(cardNumber: any) {
    return this.miceVisaPrepaid.includes(cardNumber);
  }
}
