<footer class="bg-gradient-to-r from-right-blue to-dark-blue text-white p-4">
  <div
    class="container sm:mx-auto flex flex-col justify-between md:flex-row w-4/5"
  >
    <div
      class="flex items-center sm:justify-center md:justify-start mb-4 md:mb-0"
    >
      <img
        src="../../../assets/logo/TCEB_White.png"
        alt="Logo"
        class="h-14 w-auto"
      />
    </div>
    <div class="flex justify-end max-sm:flex-col">
      <div
        class="flex flex-col sm:items-center md:items-start sm:mx-auto mb-4 md:mb-0 sm:p-6"
      >
        <span class="mb-2 text-2xl font-semibold">{{
          "More Info" | transloco
        }}</span>
        <ul class="flex flex-col gap-2">
          <li
            routerLink="/manual"
            class="flex gap-2 items-center cursor-pointer hover:underline"
          >
            <a>{{ "Manual" | transloco }}</a> <mat-icon>launch</mat-icon>
          </li>
          <li routerLink="/terms" class="cursor-pointer hover:underline">
            <a>{{ "Term and Conditions" | transloco }}</a>
          </li>
          <li
            routerLink="/privacy-policy"
            class="cursor-pointer hover:underline"
          >
            <a>{{ "Privacy Policy" | transloco }}</a>
          </li>
        </ul>
      </div>
      <div
        class="flex flex-col sm:items-center md:items-start sm:mx-auto sm:p-6"
      >
        <span class="mb-2 text-2xl font-semibold">{{
          "Contact Us" | transloco
        }}</span>
        <ul class="flex flex-col">
          <li><a>TCEB Call Center : 1105</a></li>
        </ul>
      </div>
    </div>
  </div>
  <div
    class="container sm:mx-auto flex flex-col sm:justify-between md:flex-row w-4/5 max-sm:mt-9"
  >
    <span>© 2024 Copyright</span>
  </div>
</footer>
