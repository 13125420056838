import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { interval, map, Subscription } from 'rxjs';
import { ErrorPopupComponent } from 'src/app/core/components/error-popup/error-popup.component';
import { OfferCodeComponent } from 'src/app/core/components/offer-code/offer-code.component';
import { AuthService } from 'src/app/services/auth.service';
import { OfferService } from 'src/app/services/offer.service';
import { ICode, IOfferHistory } from 'src/app/shared/model/offer.model';
import { ResponsiveService } from 'src/app/shared/utils/responsive.service';

@Component({
  selector: 'app-redemption-history',
  templateUrl: './redemption-history.component.html',
  styleUrls: ['./redemption-history.component.scss'],
})
export class RedemptionHistoryComponent {
  currentPage = 1;
  pageSize: number = 10;
  totalPages: number | undefined;
  totalCount: number | undefined;
  totalAvai!: number;
  offerHistoryList!: IOfferHistory[];
  // codeData!: ICode;
  isLoading = false;
  isCodeLoading = false;
  queryParams: any = {
    skip: this.currentPage,
    limit: this.pageSize,
    dateStart: '2024/05/01',
    dateEnd: null,
    sortBy: 'usedAt',
    sortOrderBy: 'asc',
  };
  loadingStates: { [cardId: string]: boolean } = {};
  codeDataStates: { [cardId: string]: ICode } = {};

  subscription!: Subscription;
  screenW: any;
  universalCode = ['Tops Care', 'B2S'];

  constructor(
    private authService: AuthService,
    private offerService: OfferService,
    public matDialog: MatDialog,
    private responsiveService: ResponsiveService,
    @Inject(DOCUMENT) private document: Document
  ) {
    const width = this.document.defaultView?.innerWidth;
    this.screenW = width! < 640 ? 1 : 2;
  }

  ngOnInit(): void {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = today.getDate().toString().padStart(2, '0');

    const formattedDate = `${year}/${month}/${day}`;
    this.queryParams.dateEnd = formattedDate;

    this.subscription = this.responsiveService.screenWidth$.subscribe(
      (width) => {
        this.screenW = width < 640 ? 1 : 2;
      }
    );

    this.getHistory();
  }

  getHistory() {
    this.isLoading = true;
    this.offerService
      .getOfferHistory(this.queryParams)
      .pipe(
        map((data) => {
          if (data.data.totalData.length > 0) {
            const filterIsUaged = data.data.totalData.filter(
              (item: IOfferHistory) => item.isUsaged
            );

            filterIsUaged.map((item: IOfferHistory) => {
              const dateObj: any = this.convertToDateForParsing(item.usedAt);
              const timediff = this.formatTimeDifference(
                Date.now() - Date.parse(dateObj)
              );

              const fifteenMin = 15 * 60; //15 min in second

              if (timediff >= fifteenMin) {
                item.isTimeLeft = true;
              } else {
                item.isTimeLeft = false;
              }

              return item;
            });
          }
          data.data.totalData.sort((a: IOfferHistory, b: IOfferHistory) => {
            // Prioritize isUsaged = false
            if (!a.isUsaged && b.isUsaged) {
              return -1; // a comes before b
            } else if (a.isUsaged && !b.isUsaged) {
              return 1; // b comes before a
            }

            // If isUsaged is the same, prioritize isTimeLeft = false
            if (a.isUsaged === b.isUsaged && !a.isTimeLeft && b.isTimeLeft) {
              return -1; // a comes before b
            } else if (
              a.isUsaged === b.isUsaged &&
              a.isTimeLeft &&
              !b.isTimeLeft
            ) {
              return 1; // b comes before a
            }

            // Maintain original order if both isUsaged and isTimeLeft are the same
            return 0;
          });

          return data;
        })
      )
      .subscribe({
        next: (data) => {
          this.totalAvai = data.data.totalAvailable;
          this.offerHistoryList = data.data.totalData;
          // console.log(this.offerHistoryList);

          this.isLoading = false;
        },
        error: (err) => {
          console.log(err);
          this.isLoading = false;
        },
      });
  }

  getOfferCode(detail: IOfferHistory) {
    if (!this.isCodeLoading) {
      if (!detail.isTimeLeft && detail.isUsaged) {
        this.isCodeLoading = true;
        this.loadingStates[detail._id] = true;
        this.offerService.getOfferHistoryByOffer(detail._id).subscribe({
          next: (data) => {
            const codeData = {
              offerName: detail.offerName,
              offerNameEN: detail.offerNameEN,
              merchantName: detail.merchantName,
              merchantNameEN: detail.merchantNameEN,
              code: data.data.code,
              offerEnd: detail.offerEnd,
              isUsaged: detail.isUsaged,
              isHistory: true,
              offerId: detail._id,
            };

            this.codeDataStates[detail._id] = codeData;

            const dateObj: any = this.convertToDateForParsing(detail.usedAt);
            const timediff = this.formatTimeDifference(
              Date.now() - Date.parse(dateObj)
            );

            const fifteenMin = 15 * 60; //15 min in second

            if (timediff >= fifteenMin) {
              this.codeDataStates[detail._id].isTimeLeft = true;
            } else {
              this.codeDataStates[detail._id].timeLeft = fifteenMin - timediff;
            }

            this.isCodeLoading = false;
            this.loadingStates[detail._id] = false;
          },
          error: (err) => {
            console.log(err);
            if (err.statusMessage === 'ID not found.') {
              this.onOpenError('error');
            }
            this.isCodeLoading = false;
            this.loadingStates[detail._id] = false;
          },
          complete: () => {
            this.isCodeLoading = false;
            this.loadingStates[detail._id] = false;
            if (this.codeDataStates[detail._id].isTimeLeft) {
              this.onOpenError('timeleft');
            } else {
              this.onOpenCode(detail._id);
            }
          },
        });
      } else {
        this.isCodeLoading = true;

        this.loadingStates[detail._id] = true;

        const codeData = {
          offerName: detail.offerName,
          offerNameEN: detail.offerNameEN,
          merchantName: detail.merchantName,
          merchantNameEN: detail.merchantNameEN,
          code: '',
          offerEnd: detail.offerEnd,
          isUsaged: detail.isUsaged,
          isHistory: true,
          offerId: detail._id,
        };

        this.codeDataStates[detail._id] = codeData;

        this.isCodeLoading = false;
        this.loadingStates[detail._id] = false;
        this.onOpenCode(detail._id);
      }
    } else {
      this.loadingStates[detail._id] = false;
    }
  }

  onOpenCode(id: string) {
    const optionSM = {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      minWidth: '100%',
      height: '550px',
      data: this.codeDataStates[id],
      disableClose: true,
    };
    const option = {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      width: '600px',
      data: this.codeDataStates[id],
      disableClose: true,
    };

    const selectOption = this.screenW === 1 ? optionSM : option;

    const dialog = this.matDialog.open(OfferCodeComponent, selectOption);

    dialog.afterClosed().subscribe((data) => {
      if (data) {
        this.getHistory();
      }
    });
  }

  onOpenError(subject: string) {
    const dialog = this.matDialog.open(ErrorPopupComponent, {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      width: '600px',
      data: subject,
      disableClose: true,
    });

    dialog.afterClosed().subscribe((data) => {
      if (subject === 'timeleft') {
        this.getHistory();
      }
    });
  }

  isUniversalCode(merchantName: string) {
    return this.universalCode.includes(merchantName);
  }

  onLogout() {
    this.authService.logout();
  }

  convertToDateForParsing(dateString: string): Date | null {
    const [day, month, year, hours, minutes, seconds] = dateString
      .split(/[/ :]/)
      .map(Number);

    // Month adjustment: JavaScript months are 0-based (0 = January, 11 = December)
    const adjustedMonth = Number(month) - 1;

    // Create a new Date object and return it
    const date = new Date(
      Number(year),
      Number(adjustedMonth),
      Number(day),
      Number(hours) + 7, //our db response utc+0 time
      Number(minutes),
      Number(seconds)
    );

    return isNaN(date.getTime()) ? null : date;
  }

  formatTimeDifference(milliseconds: number): number {
    const totalSeconds = Math.round(milliseconds / 1000); // Convert to seconds

    return totalSeconds; // Return in mm:ss format
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
