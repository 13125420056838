import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslocoService } from '@jsverse/transloco';
import { map, Subscription, tap } from 'rxjs';
import { CompletedPopupComponent } from 'src/app/core/components/completed-popup/completed-popup.component';
import { ConfirmPopupComponent } from 'src/app/core/components/confirm-popup/confirm-popup.component';
import { LoginComponent } from 'src/app/core/components/login/login.component';
import { SharePopupComponent } from 'src/app/core/components/share-popup/share-popup.component';
import { AuthService } from 'src/app/services/auth.service';
import { OfferService } from 'src/app/services/offer.service';
import { Role } from 'src/app/shared/enums/roles';
import { CardHolderEnum } from 'src/app/shared/enums/status';
import {
  ICode,
  IOffer,
  IOfferDetail,
  IOfferHistory,
} from 'src/app/shared/model/offer.model';
import { DataServiceService } from 'src/app/shared/utils/data-service.service';
import { ResponsiveService } from 'src/app/shared/utils/responsive.service';

@Component({
  selector: 'app-privilege-detail',
  templateUrl: './privilege-detail.component.html',
  styleUrls: ['./privilege-detail.component.scss'],
})
export class PrivilegeDetailComponent implements OnInit, OnDestroy {
  isUser = false;
  subscription!: Subscription;
  id!: string;
  offerDetail!: IOfferDetail;
  currentPage = 1;
  pageSize: number = 50;
  totalPages: number | undefined;
  totalCount: number | undefined;
  offerList: IOffer[] = [];
  offerListFull: IOffer[] = [];
  isLoading = false;
  role!: Role;
  totalAvai!: number;
  offerHistoryList!: IOfferHistory[];
  offerHistory!: IOfferHistory;
  card!: CardHolderEnum.THAI | CardHolderEnum.INTER;
  isFullQuota = true;
  isRedeemed = false;
  lang!: string;
  screenW: any;
  isQuotaLoading = false;
  codeData!: ICode;
  body = {
    offerId: '',
  };
  errMsg = '';
  isRedeemLoading = false;
  isMoreLoading = false;

  visaTag = {
    [CardHolderEnum.THAI]: 'For Thai Cardholders',
    [CardHolderEnum.INTER]: 'For International Cardholders',
  };

  queryParams: any = {
    skip: this.currentPage,
    limit: this.pageSize,
    offerType: null,
    cardHolder: null,
    sortBy: 'merchantNameEN',
    sortOrderBy: 'asc',
  };

  historyQueryParams: any = {
    skip: this.currentPage,
    limit: this.pageSize,
    dateStart: '2024/05/01',
    dateEnd: null,
    sortBy: 'usedAt',
    sortOrderBy: 'asc',
  };

  constructor(
    public matDialog: MatDialog,
    private authService: AuthService,
    private offerService: OfferService,
    private route: ActivatedRoute,
    private router: Router,
    private translocoService: TranslocoService,
    private responsiveService: ResponsiveService,
    @Inject(DOCUMENT) private document: Document,
    private dataService: DataServiceService
  ) {
    const width = this.document.defaultView?.innerWidth;
    this.screenW = width! < 640 ? 1 : 2;
  }
  ngOnInit(): void {
    this.subscription = this.authService.currentUser.subscribe((user) => {
      this.isUser = Boolean(user);

      this.role = user?.roles;
      if (this.isUser && this.role === Role.USER) {
        this.getHistory();
      }
    });

    const sub2 = this.translocoService.langChanges$.subscribe((activeLang) => {
      this.lang = activeLang;
    });

    const sub3 = this.responsiveService.screenWidth$.subscribe((width) => {
      this.screenW = width < 640 ? 1 : 2;
    });

    this.subscription.add(sub2);
    this.subscription.add(sub3);

    this.route.params.subscribe((data) => {
      this.id = data['id'];
      this.body.offerId = this.id;

      this.getOfferDetail(true);
    });

    this.route.queryParams.subscribe((data) => {
      this.card = data['card'];
    });
  }
  tabs = [
    { title: 'Offer Detail', content: '' },
    { title: 'Terms & Conditions', content: '' },
  ];
  selectedTab = 0;

  selectTab(index: number) {
    this.selectedTab = index;
  }

  getOfferList() {
    this.isMoreLoading = true;

    if (this.offerList.length === 0) {
      this.offerService
        .getOfferByType(this.queryParams)
        .pipe(
          tap((data) => {
            const numItemsToSelect = 3; // You can change this if needed
            this.offerListFull = [...data.data.totalData];
            if (this.offerListFull.length <= numItemsToSelect) {
              this.offerList = [...this.offerListFull];
            } else {
              const dataList = [...this.offerListFull];
              const randomItems = this.shuffleFuction(
                numItemsToSelect,
                dataList
              );

              this.offerList = randomItems;
            }
          })
        )
        .subscribe({
          next: (data: any) => {
            this.isMoreLoading = false;
          },
          error: (err) => {
            console.log(err);
            this.isMoreLoading = false;
          },
        });
    } else {
      if (this.offerListFull.length > 3) {
        const tempList = [...this.offerListFull];
        const ramdomItems = this.shuffleFuction(3, tempList);
        this.offerList = ramdomItems;
      }
      this.isMoreLoading = false;
    }
  }

  getOfferDetail(isInit: boolean) {
    this.isLoading = true;
    this.offerService.getOfferById(this.id).subscribe({
      next: (data) => {
        this.offerDetail = data.data;

        this.queryParams.offerType = this.offerDetail.offerType;
        this.queryParams.cardHolder = this.card || null;

        this.isLoading = false;
        if (isInit) {
          this.getOfferList();
        }
      },
      error: (err) => {
        console.log(err);
        this.isLoading = false;
      },
    });
  }

  getHistory() {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = today.getDate().toString().padStart(2, '0');

    const formattedDate = `${year}/${month}/${day}`;
    this.historyQueryParams.dateEnd = formattedDate;
    this.isQuotaLoading = true;

    this.offerService.getOfferHistory(this.historyQueryParams).subscribe({
      next: (data) => {
        this.totalAvai = data.data.totalAvailable;
        // this.isFullQuota = this.totalAvai > 0 ? false : true;
        this.offerHistoryList = data.data.totalData;

        this.isRedeemed = this.isOfferRedeemed();
        this.isQuotaLoading = false;
      },
      error: (err) => {
        console.log(err);
        this.isQuotaLoading = false;
      },
    });
  }

  isOfferRedeemed() {
    return this.offerHistoryList.some(
      (item: IOfferHistory) => item._id === this.id
    );
  }

  onOpenShareDialog() {
    const dialogRef = this.matDialog.open(SharePopupComponent, {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
    });
  }

  onConfirm() {
    this.isQuotaLoading = true;
    this.offerService.redeemOffer(this.body).subscribe({
      next: (data) => {
        this.codeData = data.data;
        this.codeData.isUsaged = false;
        this.codeData.isHistory = false;
        this.codeData.offerId = this.id;
        this.isQuotaLoading = false;
        this.isRedeemed = true;
        this.dataService.increaseOfferNotUse();
      },
      error: (err) => {
        console.log(err);
        this.isQuotaLoading = false;
        this.errMsg = err.statusMessage;
        this.onErrorDialog();
      },
      complete: () => {
        this.isQuotaLoading = false;
        this.isRedeemed = true;
        this.onOpenRedeem();
      },
    });
  }

  onOpenConfirmDialog() {
    const optionSM = {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      // minWidth: '100%',
      width: '600px',
      height: '550px',
      data: this.codeData,
      disableClose: true,
    };
    const option = {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      width: '600px',
      data: this.codeData,
      disableClose: true,
    };

    const selectOption = this.screenW === 1 ? optionSM : option;

    const dialogRef = this.matDialog.open(ConfirmPopupComponent, selectOption);

    dialogRef.afterClosed().subscribe((data) => {
      if (data?.result) {
        if (data.status === 'success') {
          this.offerDetail.isUsaged = true;
        } else {
          this.getOfferDetail(false);
        }
      }
    });
  }

  onErrorDialog() {
    // const optionSM = {
    //   enterAnimationDuration: '100ms',
    //   exitAnimationDuration: '300ms',
    //   minWidth: '100%',
    //   data: { location: 'offerDetail', msg: this.errMsg },
    //   disableClose: true,
    // };
    const option = {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      width: '600px',
      data: { location: 'offerDetail', msg: this.errMsg },
      disableClose: true,
    };

    const selectOption = this.screenW === 1 ? option : option;

    const dialogRef = this.matDialog.open(
      CompletedPopupComponent,
      selectOption
    );
  }

  onOpenLoginDialog() {
    const optionSM = {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      minWidth: '100%',
    };
    const option = {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      width: '480px',
    };

    const selectOption = this.screenW === 1 ? optionSM : option;

    const dialogRef = this.matDialog.open(LoginComponent, selectOption);
  }

  onOpenRedeem() {
    if (this.isUser && this.role === 'USER') {
      this.onOpenConfirmDialog();
    } else {
      this.onOpenLoginDialog();
    }
  }

  shuffleFuction(num: number, dataList: IOffer[]) {
    const mapData = dataList.filter(
      (item: any) => item._id !== this.id && item.status === 'start'
    );

    const shuffledData = mapData.sort(() => 0.5 - Math.random());

    const randomItems = [];

    if (shuffledData.length >= num) {
      randomItems.push(...shuffledData.slice(0, num));
    }

    return randomItems;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
