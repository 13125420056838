import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { ExportOptions } from 'src/app/shared/model/admin.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OffersAdminService {
  private apiUrl = environment.apiUrl; // Replace with your actual API URL
  private apiOffer = this.apiUrl + 'backoffice/offer';
  constructor(private http: HttpClient, private route: Router) {}

  getOfferList(queryParams: any) {
    let params = new HttpParams();
    for (let key in queryParams) {
      if (queryParams[key]) {
        params = params.set(key, queryParams[key]);
      }
    }
    const requestOptions = {
      params: params,
    };
    return this.http
      .get<any>(`${this.apiOffer}/list`, requestOptions)
      .pipe(catchError(this.handleError));
  }

  getOfferDetail(id: string) {
    return this.http
      .get<any>(`${this.apiOffer}/detail/${id}`)
      .pipe(catchError(this.handleError));
  }

  getOfferQuotaDetail(id: string, queryParams: any) {
    let params = new HttpParams();
    for (let key in queryParams) {
      if (queryParams[key]) {
        params = params.set(key, queryParams[key]);
      }
    }
    const requestOptions = {
      params: params,
    };
    return this.http
      .get<any>(`${this.apiOffer}/quota/${id}`, requestOptions)
      .pipe(catchError(this.handleError));
  }

  createOffer(formData: FormData) {
    return this.http
      .post<any>(`${this.apiOffer}`, formData)
      .pipe(catchError(this.handleError));
  }

  editOfferInformation(formData: FormData, id: string) {
    return this.http
      .put<any>(`${this.apiOffer}/${id}`, formData)
      .pipe(catchError(this.handleError));
  }

  editOfferQuota(formData: FormData, id: string) {
    return this.http
      .patch<any>(`${this.apiOffer}/quota/${id}`, formData)
      .pipe(catchError(this.handleError));
  }

  pauseOffer(id: string, body: any) {
    return this.http
      .patch<any>(`${this.apiOffer}/pause/${id}`, body)
      .pipe(catchError(this.handleError));
  }

  deleteOffer(id: string, passwordBody: any) {
    const options = {
      body: passwordBody,
    };
    return this.http
      .delete<any>(`${this.apiOffer}/${id}`, options)
      .pipe(catchError(this.handleError));
  }

  exportCode(id: string) {
    const requestOptions: ExportOptions = {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob' as 'json',
    };

    return this.http
      .get<Blob>(`${this.apiOffer}/export/quota/${id}`, requestOptions)
      .pipe(catchError(this.handleError));
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      console.error('service::error ', error.error);
    }
    let errorMessage = '';
    if (Array.isArray(error.error.statusMessage)) {
      errorMessage = error.error.statusMessage.toString();
    } else {
      errorMessage = error.error.statusMessage;
    }
    // console.error('offer.service::errorMessage:: ', errorMessage);
    return throwError(() => error.error);
  }
}
