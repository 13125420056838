<div class="container mx-auto px-5 py-10 lg:grid lg:grid-cols-2">
  <div class="lg:col-span-1" *ngIf="lang === 'en'">
    <h1 class="text-2xl">
      Privacy Policy for Thailand MICE VISA Prepaid Card : : Welcome Privileges
      Platform
    </h1>

    <article class="text-[#6F6F6F] mt-5 text-pretty">
      <b>1. Introduction</b> <br />
      This Privacy Policy ("Policy") explains how Vathanagul Group Co., Ltd.
      (“VTHG”, "we", "our", "us") collects, uses, and protects your personal
      information in compliance with the Personal Data Protection Act (PDPA) of
      Thailand when you use the Thailand MICE VISA Prepaid Card Welcome
      Privileges Platform ("Platform").
      <br />
      <br />

      <b>2. Information We Collect</b> <br />
      We collect the following types of personal information from you when you
      sign up for and use the Platform:
      <div class="ps-4">
        <b>a. Personal Identification Information:</b> This includes your name,
        phone number, and email address. <br />
        <b>b. Card Information:</b> We collect the first six (6) and last four
        (4) digits of your Thailand MICE VISA Prepaid Card ("Card") solely for
        verification purposes. We do not store or use your full Card number.
      </div>

      <br />
      <br />

      <b>3. Purpose of Collection and Use of Personal Data</b> <br />
      We use your personal information for the following purposes:
      <div class="ps-4">
        <b>a. Verification:</b> To verify your eligibility to use the Platform
        and access the privileges. <br />
        <b>b. Communication:</b> To communicate with you about the Platform,
        including updates, notifications, and customer service inquiries.<br />
        <b>c. Privilege Redemption:</b> To facilitate the redemption of Welcome
        Privileges, Partners Privileges, and VISA Privileges. <br />
      </div>

      <br />
      <br />

      <b> 4. Disclosure of Personal Data</b> <br />
      We do not sell, trade, or otherwise transfer your personal information to
      any third parties. Your personal information is kept confidential and will
      only be used for the purposes outlined in this Policy.
      <br />
      <br />

      <b> 5. Data Security</b> <br />
      We implement a variety of security measures to maintain the safety of your
      personal information. Your personal information is stored on secure
      servers and is only accessible by authorized personnel who are required to
      keep the information confidential.
      <br />
      <br />
      <b> 6. Data Retention</b> <br />
      We will retain your personal information for as long as necessary to
      fulfill the purposes outlined in this Policy, unless a longer retention
      period is required or permitted by law.
      <br />
      <br />
      <b> 7. Your Rights Under the PDPA</b> <br />
      You have the following rights regarding your personal information under
      the PDPA:
      <div class="ps-4">
        <b>a. Right to Access:</b> You have the right to request access to the
        personal information we hold about you. <br />
        <b>b. Right to Rectification:</b> You have the right to request
        correction of any inaccurate or incomplete personal information.<br />
        <b>c. Right to Erasure:</b> You have the right to request the deletion
        of your personal information, subject to certain legal exceptions.
        <br />
        <b>d. Right to Restriction of Processing:</b> You have the right to
        request the restriction of processing of your personal information under
        certain circumstances.<br />
        <b>e. Right to Data Portability:</b> You have the right to receive your
        personal information in a structured, commonly used, and
        machine-readable format, and to transmit that information to another
        controller.<br />
        <b>f. Right to Object:</b> You have the right to object to the
        processing of your personal information under certain circumstances.<br />
      </div>
      <br />
      <br />
      <b> 8. Changes to This Privacy Policy</b> <br />
      VTHG reserves the right to update or modify this Policy at any time. Any
      changes will be posted on the Platform, and the revised Policy will take
      effect immediately upon posting. Your continued use of the Platform
      constitutes your acceptance of the revised Policy.
      <br />
      <br />
      <b> 9. Contact Us</b> <br />
      If you have any questions or concerns about this Privacy Policy or our
      data practices, please contact us at
      <div class="ps-4">
        <p>
          Vathanagul Group Co., Ltd. 9/9 Soi Serithai 77, Khan Na Yao, Bangkok,
          10230 Tel: 02-9199108 ext.135 from Monday to Friday (9.00 AM – 5.00
          PM)
        </p>
        <p>Email: sun@vathanagul.com</p>
      </div>
      <br />
      <br />
      <b> 10. Governing Law</b> <br />
      This Privacy Policy shall be governed by and construed in accordance with
      the laws of Thailand, without regard to its conflict of law provisions.
      <br />
      <br />

      <p>
        By using the Platform, you acknowledge that you have read, understood,
        and agreed to this Privacy Policy in accordance with the PDPA.
      </p>
    </article>
  </div>
  <div class="lg:col-span-1" *ngIf="lang === 'th'">
    <h1 class="text-2xl">นโยบายการคุ้มครองข้อมูลส่วนบุคคล</h1>

    <article class="text-[#6F6F6F] mt-5 text-pretty">
      <b>1. บทนำ</b> <br />
      นโยบายความเป็นส่วนตัวนี้ ("นโยบาย") อธิบายว่า บริษัท วัฒนากุลกรุ๊ป จำกัด
      (“VTHG”, "เรา", "ของเรา", "พวกเรา") รวบรวม ใช้
      และปกป้องข้อมูลส่วนบุคคลของคุณอย่างไร
      โดยปฏิบัติตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล (PDPA) ของประเทศไทย
      เมื่อคุณใช้แพลตฟอร์ม Thailand MICE VISA Prepaid Card Welcome Privileges
      ("แพลตฟอร์ม")
      <br />
      <br />

      <b>2. ข้อมูลที่เรารวบรวม</b> <br />
      เรารวบรวมข้อมูลส่วนบุคคลประเภทต่อไปนี้จากคุณเมื่อคุณลงทะเบียนและใช้แพลตฟอร์ม:
      <div class="ps-4">
        <b>ก. ข้อมูลระบุตัวตนส่วนบุคคล:</b> รวมถึงชื่อ เบอร์โทรศัพท์
        และที่อยู่อีเมลของคุณ <br />
        <b>ข. ข้อมูลบัตร:</b>เรารวบรวมเลขที่หก (6) ตัวแรกและเลขที่สี่ (4)
        ตัวสุดท้ายของบัตร Thailand MICE VISA Prepaid Card ("บัตร")
        ของคุณเพื่อวัตถุประสงค์ในการตรวจสอบเท่านั้น
        เราจะไม่เก็บหรือใช้หมายเลขบัตรทั้งหมดของคุณ
      </div>

      <br />
      <br />

      <b>3. วัตถุประสงค์ในการรวบรวมและใช้ข้อมูลส่วนบุคคล</b> <br />
      เราจะใช้ข้อมูลส่วนบุคคลของคุณเพื่อวัตถุประสงค์ดังต่อไปนี้:
      <div class="ps-4">
        <b>ก. การตรวจสอบ: </b>
        เพื่อตรวจสอบคุณสมบัติของคุณในการใช้แพลตฟอร์มและเข้าถึงสิทธิประโยชน์
        <br />
        <b>ข. การสื่อสาร:</b> เพื่อสื่อสารกับคุณเกี่ยวกับแพลตฟอร์ม
        รวมถึงการอัปเดต การแจ้งเตือน และการสอบถามบริการลูกค้า<br />
        <b>ค. การแลกสิทธิประโยชน์: </b> เพื่ออำนวยความสะดวกในการแลกสิทธิประโยชน์
        Welcome Offer, Partners Offer และ VISA Offer <br />
      </div>

      <br />
      <br />

      <b>4. การเปิดเผยข้อมูลส่วนบุคคล</b> <br />
      เราจะไม่ขาย แลกเปลี่ยน หรือถ่ายโอนข้อมูลส่วนบุคคลของคุณไปยังบุคคลที่สาม
      ข้อมูลส่วนบุคคลของคุณจะถูกเก็บเป็นความลับและจะใช้เฉพาะตามวัตถุประสงค์ที่ระบุในนโยบายนี้
      <br />
      <br />

      <b>5. ความปลอดภัยของข้อมูล</b> <br />
      เราดำเนินมาตรการรักษาความปลอดภัยหลายประการเพื่อรักษาความปลอดภัยของข้อมูลส่วนบุคคลของคุณ
      ข้อมูลส่วนบุคคลของคุณถูกเก็บไว้ในเซิร์ฟเวอร์ที่ปลอดภัยและสามารถเข้าถึงได้เฉพาะบุคลากรที่ได้รับอนุญาตซึ่งต้องรักษาความลับของข้อมูล
      <br />
      <br />
      <b>6. การเก็บรักษาข้อมูล</b> <br />
      เราจะเก็บข้อมูลส่วนบุคคลของคุณไว้ตามระยะเวลาที่จำเป็นเพื่อดำเนินการตามวัตถุประสงค์ที่ระบุในนโยบายนี้
      เว้นแต่จะมีการกำหนดหรืออนุญาตระยะเวลาการเก็บรักษาที่นานกว่านั้นตามกฎหมาย
      <br />
      <br />
      <b>7. สิทธิตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล (PDPA)</b> <br />
      คุณมีสิทธิต่อไปนี้เกี่ยวกับข้อมูลส่วนบุคคลของคุณตาม PDPA:
      <div class="ps-4">
        <b>ก. สิทธิในการเข้าถึง:</b>
        คุณมีสิทธิขอเข้าถึงข้อมูลส่วนบุคคลที่เรามีเกี่ยวกับคุณ <br />
        <b>ข. สิทธิในการแก้ไข:</b>
        คุณมีสิทธิขอแก้ไขข้อมูลส่วนบุคคลที่ไม่ถูกต้องหรือไม่ครบถ้วน<br />
        <b>ค. สิทธิในการลบข้อมูล: </b> คุณมีสิทธิขอลบข้อมูลส่วนบุคคลของคุณ
        โดยอาจมีข้อยกเว้นตามกฎหมายบางประการ
        <br />
        <b>ง. สิทธิในการจำกัดการประมวลผล:</b>
        คุณมีสิทธิขอจำกัดการประมวลผลข้อมูลส่วนบุคคลของคุณในบางกรณี<br />
        <b>จ. สิทธิในการพกพาข้อมูล: </b> คุณมีสิทธิได้รับข้อมูลส่วนบุคคลของคุณ
        และส่งข้อมูลนั้นไปยังผู้ควบคุมข้อมูลอื่น<br />
        <b>ฉ. สิทธิคัดค้าน: </b>
        คุณมีสิทธิคัดค้านการประมวลผลข้อมูลส่วนบุคคลของคุณในบางกรณี<br />
      </div>
      <br />
      <br />
      <b> 8. การเปลี่ยนแปลงนโยบายความเป็นส่วนตัวนี้</b> <br />
      VTHG ขอสงวนสิทธิ์ในการอัปเดตหรือแก้ไขนโยบายนี้ได้ตลอดเวลา
      การเปลี่ยนแปลงใดๆ จะถูกโพสต์บนแพลตฟอร์ม
      และนโยบายที่แก้ไขจะมีผลทันทีเมื่อเผยแพร่
      การใช้แพลตฟอร์มของคุณต่อไปถือเป็นการยอมรับนโยบายที่แก้ไขแล้ว
      <br />
      <br />
      <b> 9. ติดต่อเรา</b> <br />
      หากคุณมีคำถามหรือข้อกังวลเกี่ยวกับนโยบายความเป็นส่วนตัวนี้หรือการปฏิบัติด้านข้อมูลของเรา
      โปรดติดต่อเราที่:
      <div class="ps-4">
        <p>
          บริษัท วัฒนากุลกรุ๊ป จำกัด 9/9 ซอยเสรีไทย 77 แขวง/เขตคันนายาว กรุงเทพฯ
          10230 โทร: 02-9199108 ต่อ 135 ตั้งแต่วันจันทร์ถึงวันศุกร์ (9.00 น. –
          17.00 น.)
        </p>
        <p>อีเมล: sun@vathanagul.com</p>
      </div>
      <br />
      <br />
      <b> 10. กฎหมายที่ใช้บังคับ</b> <br />
      นโยบายความเป็นส่วนตัวนี้จะถูกควบคุมและตีความตามกฎหมายของประเทศไทย
      โดยไม่คำนึงถึงการขัดกันของกฎหมาย
      <br />
      <br />

      <p>
        การใช้แพลตฟอร์มถือว่าคุณได้อ่าน เข้าใจ
        และตกลงตามนโยบายความเป็นส่วนตัวนี้ตาม PDPA
      </p>
    </article>
  </div>
</div>
