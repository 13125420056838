<div class="container mx-auto pt-10 bg-white px-20 mb-20">
  <a
    routerLink="/admin/customer-list"
    class="flex items-center gap-2 cursor-pointer text-main-blue mb-10"
  >
    <mat-icon>arrow_back</mat-icon>
    <span>Customer List</span>
  </a>
  <div class="flex justify-between items-center mb-10">
    <h1 class="text-4xl">Customer info</h1>
  </div>
  <div
    *ngIf="!isLoading && customerDetail"
    class="bg-[#F7F7F7] rounded-3xl p-6"
  >
    <h1 class="text-3xl mb-1">
      {{ customerDetail.firstName }} {{ customerDetail.lastName }}
    </h1>
    <div class="grid grid-cols-1 md:grid-cols-2 mt-5">
      <div class="flex flex-col gap-1">
        <p class="text-sm">Phone number</p>
        <p>{{ customerDetail.phoneNumber }}</p>
      </div>
      <div class="flex flex-col gap-1">
        <p class="text-sm">Email</p>
        <p>{{ customerDetail.email }}</p>
      </div>
      <!-- <div class="flex flex-col mt-5 gap-1">
        <p class="text-sm">Card number</p>
        <p>1234-5678-XXXX-1234</p>
      </div> -->
    </div>
  </div>

  <h1 class="text-2xl mt-20 mb-10">Redemption history</h1>

  <div class="overflow-x-auto">
    <table
      class="table-fixed border-separate border-spacing-2 min-w-[650px] w-full"
    >
      <thead>
        <tr class="text-left cursor-pointer">
          <th
            class="pe-2 bg-[#F4F4F4] font-light relative text-center w-[35%]"
            (click)="sortBy('offerName')"
          >
            <span class="text-center">Offer</span>
            <mat-icon
              class="absolute right-0 opacity-30 transform scale-75"
              *ngIf="queryParams.sortBy !== 'offerName'"
              >arrow_upward</mat-icon
            >
            <mat-icon
              class="absolute right-0 transform scale-75"
              *ngIf="
                queryParams.sortBy === 'offerName' &&
                queryParams.sortOrderBy === 'asc'
              "
              >arrow_upward</mat-icon
            >
            <mat-icon
              class="absolute right-0 transform scale-75"
              *ngIf="
                queryParams.sortBy === 'offerName' &&
                queryParams.sortOrderBy === 'desc'
              "
              >arrow_downward</mat-icon
            >
          </th>

          <th
            class="pe-2 bg-[#F4F4F4] font-light relative text-center w-[30%]"
            (click)="sortBy('usedAt')"
          >
            <span class="text-center">Date/time redeemed</span>
            <mat-icon
              class="absolute right-0 opacity-30 transform scale-75"
              *ngIf="queryParams.sortBy !== 'usedAt'"
              >arrow_upward</mat-icon
            >
            <mat-icon
              class="absolute right-0 transform scale-75"
              *ngIf="
                queryParams.sortBy === 'usedAt' &&
                queryParams.sortOrderBy === 'asc'
              "
              >arrow_upward</mat-icon
            >
            <mat-icon
              class="absolute right-0 transform scale-75"
              *ngIf="
                queryParams.sortBy === 'usedAt' &&
                queryParams.sortOrderBy === 'desc'
              "
              >arrow_downward</mat-icon
            >
          </th>
          <!-- <th
            class="pe-2 bg-[#F4F4F4] font-light relative text-center w-[25%]"
            (click)="sortBy('type')"
          >
            <span class="text-center">Offer type</span>
            <mat-icon
              class="absolute right-0 opacity-30 transform scale-75"
              *ngIf="queryParams.sortBy !== 'type'"
              >arrow_upward</mat-icon
            >
            <mat-icon
              class="absolute right-0 transform scale-75"
              *ngIf="
                queryParams.sortBy === 'type' &&
                queryParams.sortOrderBy === 'asc'
              "
              >arrow_upward</mat-icon
            >
            <mat-icon
              class="absolute right-0 transform scale-75"
              *ngIf="
                queryParams.sortBy === 'type' &&
                queryParams.sortOrderBy === 'desc'
              "
              >arrow_downward</mat-icon
            >
          </th> -->
          <th class="pe-2 bg-[#F4F4F4] font-light relative text-center w-[25%]">
            <span class="text-center">Action</span>
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          class="bg-white border-2 shadow shadow-gray-300 rounded-lg mb-3 transition duration-150 ease-in-out hover:bg-gray-100"
          *ngFor="let offer of redeemedList"
        >
          <td
            class="px-6 py-4 rounded-tl-lg rounded-bl-lg truncate text-blue-600"
          >
            {{ offer.offerName }}
          </td>
          <td class="px-6 py-4 truncate text-center">
            {{ offer.usedAt | transformDate }}
          </td>
          <!-- <td class="px-6 py-4 truncate text-center">Partners Offer</td> -->

          <td class="py-4 px-2 rounded-tr-lg rounded-br-lg truncate">
            <div
              class="flex items-center justify-center gap-1 text-blue-600 cursor-pointer"
              [routerLink]="['/admin/offer', offer._id]"
            >
              <span>Offer info</span><mat-icon>arrow_forward</mat-icon>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="flex justify-center items-center mt-4">
    <div class="flex items-center cursor-pointer">
      <mat-icon
        (click)="changePage(currentPage - 1)"
        [ngClass]="{ disabled: currentPage === 1 }"
        >chevron_left</mat-icon
      >
      <span>{{ currentPage }}/{{ totalPages }}</span>
      <mat-icon
        (click)="changePage(currentPage + 1)"
        [ngClass]="{ disabled: currentPage === totalPages }"
        >chevron_right</mat-icon
      >
    </div>
  </div>

  <ng-container *ngIf="isLoading">
    <app-main-loading></app-main-loading>
  </ng-container>
</div>
