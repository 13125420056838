<div class="container mx-auto pt-10 bg-white px-20 mb-20">
  <div class="flex justify-between items-center mb-10">
    <a
      routerLink="/admin/offers"
      class="flex items-center gap-2 cursor-pointer text-main-blue"
    >
      <mat-icon>arrow_back</mat-icon>
      <span>Offers</span>
    </a>
    <div class="flex flex-col items-center">
      <div
        *ngIf="offerDetail"
        class="px-5 py-2 rounded-full flex gap-2 items-center"
      >
        <span class="relative flex h-3 w-3">
          <span
            class="animate-ping absolute inline-flex h-full w-full rounded-full opacity-75"
            [ngClass]="
              offerDetail.status === 'start'
                ? 'bg-[#20C371]'
                : offerDetail.status === 'pause'
                ? 'bg-yellow-500'
                : 'bg-main-red '
            "
          ></span>
          <span
            class="animate-ping absolute inline-flex h-full w-full rounded-full opacity-75"
            [ngClass]="
              offerDetail.status === 'start'
                ? 'bg-[#20C371]'
                : offerDetail.status === 'pause'
                ? 'bg-yellow-500'
                : 'bg-main-red '
            "
            class="relative inline-flex rounded-full h-3 w-3"
          ></span>
        </span>
        <h1 class="font-semibold">
          {{ offerDetail.status.toLocaleUpperCase() }}
        </h1>
      </div>
      <span *ngIf="offerDetail" class="text-sm opacity-50">{{
        offerDetail.offerType
      }}</span>
    </div>
  </div>

  <div class="flex justify-between items-center mb-10">
    <h1 class="text-4xl">Offer info</h1>
    <div class="w-60" *ngIf="offerDetail && offerDetail.status !== 'cancel'">
      <button
        class="flex justify-between border-2 border-gray-500 w-full p-2 px-4 rounded-full"
        [matMenuTriggerFor]="actionMenu"
      >
        <div>Action</div>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <!-- dropdown for icon -->
      <mat-menu #actionMenu="matMenu" xPosition="before" class="w-60">
        <!-- <button mat-menu-item><span>Edit Admin Role</span></button> -->
        <button
          mat-menu-item
          [routerLink]="['/admin/edit-offer', offerDetail._id]"
          [fragment]="'edit'"
        >
          <span>Edit Information</span>
        </button>
        <button
          mat-menu-item
          [routerLink]="['/admin/edit-quota', offerDetail._id]"
          *ngIf="offerDetail.offerType === 'WELCOME'"
          [disabled]="
            disableEditQuota.includes(offerDetail.status) ||
            offerDetail.quotaType === 'AUTO'
          "
        >
          <span>Edit Redemption Quota</span>
        </button>
        <button
          mat-menu-item
          (click)="onPauseOffer()"
          *ngIf="
            offerDetail.status !== 'pause' &&
            offerDetail.offerType === 'WELCOME'
          "
          [disabled]="disablePause.includes(offerDetail.status)"
        >
          <span>Pause Offer Redemption</span>
        </button>
        <button
          mat-menu-item
          (click)="onPauseOffer()"
          *ngIf="
            offerDetail.status === 'pause' &&
            offerDetail.offerType === 'WELCOME'
          "
          [disabled]="disablePause.includes(offerDetail.status)"
        >
          <span>Resume Offer Redemption</span>
        </button>
        <button mat-menu-item (click)="onDeleteOffer()">
          <span class="text-main-red">Delete Offer</span>
        </button>
      </mat-menu>
    </div>
  </div>

  <div
    class="mt-14 mb-10 flex gap-16 max-sm:justify-around text-center text-2xl lg:text-4xl font-medium"
    *ngIf="offerDetail"
  >
    <ng-container *ngIf="offerDetail.offerType === 'WELCOME'">
      <div
        *ngFor="let tab of tabs; let i = index"
        (click)="selectTab(i)"
        class="cursor-pointer transition ease-in-out active:scale-110 duration-150 text-gray-300 font-[Ontima]"
        [ngClass]="{ 'tab-item': true, active: i === selectedTab }"
      >
        {{ tab.title }}
      </div>
    </ng-container>
    <ng-container *ngIf="offerDetail.offerType !== 'WELCOME'">
      <div
        class="cursor-pointer transition ease-in-out active:scale-110 duration-150 text-gray-300 font-[Ontima]"
        [ngClass]="{ 'tab-item': true, active: true }"
      >
        Information
      </div>
    </ng-container>
  </div>
  <!-- ##information -->
  <ng-container [ngSwitch]="tabs[selectedTab].title" *ngIf="!isLoading">
    <div *ngSwitchCase="'Information'">
      <div class="w-3/12 mb-2.5">
        <mat-select
          id="langSelect"
          name="langSelect"
          [(ngModel)]="infoLang"
          class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
        >
          <mat-option
            *ngFor="let lang of infoLangList"
            [value]="lang.viewValue"
          >
            {{ lang.view }}
          </mat-option>
        </mat-select>
      </div>
      <div class="flex flex-col gap-4 lg:flex-row" *ngIf="offerDetail">
        <div
          class="bg-[#F7F7F7] w-full p-3 rounded-2xl flex items-center justify-center min-h-72 md:h-96"
        >
          <div class="relative bg-white w-full h-full rounded-xl">
            <img
              [ngSrc]="offerDetail.offerImage"
              alt="image"
              class="w-full h-full object-cover rounded-xl"
              fill
              priority
            />
            <div
              *ngIf="offerDetail?.category"
              class="absolute bg-white text-xs z-40 p-1.5 top-4 left-4 rounded-lg"
            >
              {{ offerDetail.category?.toLocaleUpperCase() }}
            </div>
          </div>
        </div>
        <div
          class="bg-[#F7F7F7] w-full p-5 rounded-2xl flex flex-col justify-between"
        >
          <div>
            <div class="opacity-50 mb-5">
              Offer ID : {{ offerDetail.offerNumber }}
            </div>

            <div class="flex items-center gap-5">
              <div
                class="w-14 h-14 shrink-0 grow-0 rounded-full shadow-md shadow-gray-300 bg-white border-t-2 overflow-hidden relative"
              >
                <img
                  [ngSrc]="offerDetail.merchantImage"
                  class="w-full h-full object-contain"
                  alt="logo"
                  fill
                  priority
                />
              </div>
              <span>{{
                infoLang === "th"
                  ? offerDetail.merchantName
                  : offerDetail.merchantNameEN
              }}</span>
            </div>
            <div class="mt-5">
              <h1 class="text-3xl">
                {{
                  infoLang === "th"
                    ? offerDetail.offerName
                    : offerDetail.offerNameEN
                }}
              </h1>
              <h1
                *ngIf="
                  offerDetail.shortDescription || offerDetail.shortDescriptionEN
                "
                class="mt-5"
              >
                Short Description
              </h1>
              <p class="opacity-50 mt-2.5">
                {{
                  infoLang === "th"
                    ? offerDetail.shortDescription
                    : offerDetail.shortDescriptionEN
                }}
              </p>
            </div>
          </div>

          <div class="mt-5">
            <h1 class="mb-2">Duration</h1>
            <div class="flex gap-2">
              <mat-icon>access_time</mat-icon>
              <div>
                {{ offerDetail.offerStart }} - {{ offerDetail.offerEnd }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-[#F7F7F7] w-full p-5 rounded-2xl min-h-40 mt-5">
        <h1 class="text-3xl">Offer detail</h1>
        <div
          *ngIf="infoLang === 'th'"
          class="text-[#6F6F6F] text-pretty mt-5"
          [innerHTML]="offerDetail.offerDetail | transformInnerHTML"
        ></div>
        <div
          *ngIf="infoLang === 'en'"
          class="text-[#6F6F6F] text-pretty mt-5"
          [innerHTML]="offerDetail.offerDetailEN | transformInnerHTML"
        ></div>
      </div>
      <div class="bg-[#F7F7F7] w-full p-5 rounded-2xl min-h-40 mt-5">
        <h1 class="text-3xl">Terms & Conditions</h1>
        <div
          *ngIf="infoLang === 'th'"
          class="text-[#6F6F6F] text-pretty mt-5"
          [innerHTML]="offerDetail.terms | transformInnerHTML"
        ></div>
        <div
          *ngIf="infoLang === 'en'"
          class="text-[#6F6F6F] text-pretty mt-5"
          [innerHTML]="offerDetail.termsEN | transformInnerHTML"
        ></div>
      </div>
    </div>
    <!-- Redemption quota -->
    <div *ngSwitchCase="'Redemption quota'">
      <div
        class="bg-[#F7F7F7] w-full p-5 rounded-2xl mt-5 mb-2.5"
        *ngIf="offerDetail.status === 'pause'"
      >
        <p class="text-main-red">This offer redemption has been paused</p>
      </div>

      <div class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl p-6">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div class="mb-5 mt-10 md:m-0 relative">
            <label for="offerStart" class="block text-sm font-medium mb-2 ms-2">
              From
            </label>
            <input
              id="offerStart"
              name="offerStart"
              class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
              matInput
              [matDatepicker]="offerStart"
              placeholder="DD/MM/YY"
              [(ngModel)]="dateSelect.dateStart"
              (dateChange)="toggleSelection($event)"
            />
            <div class="absolute right-0 top-7">
              <mat-datepicker-toggle matIconSuffix [for]="offerStart">
              </mat-datepicker-toggle>
            </div>
            <mat-datepicker #offerStart></mat-datepicker>
          </div>
          <div class="mb-5 mt-10 md:m-0 relative">
            <label for="offerEnded" class="block text-sm font-medium mb-2 ms-2">
              To
            </label>
            <input
              id="offerEnded"
              name="offerEnded"
              class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
              matInput
              [matDatepicker]="offerEnded"
              placeholder="DD/MM/YY"
              [(ngModel)]="dateSelect.dateEnd"
              (dateChange)="toggleSelection($event)"
            />
            <div class="absolute right-0 top-7">
              <mat-datepicker-toggle matIconSuffix [for]="offerEnded">
              </mat-datepicker-toggle>
            </div>
            <mat-datepicker #offerEnded></mat-datepicker>
          </div>
          <div class="mb-5 mt-10 md:m-0 relative">
            <mat-select
              id="type"
              [(ngModel)]="selectMonth"
              name="type"
              placeholder="Select month"
              class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
              (selectionChange)="onMonthSelectionChange($event.value)"
            >
              <mat-option
                *ngFor="let month of months"
                [value]="month.viewValue"
              >
                {{ month.view }}
              </mat-option>
            </mat-select>
          </div>
          <div class="w-full">
            <app-primary-button
              content="Apply Filters"
              (onClick)="onDateSelect()"
            ></app-primary-button>
          </div>
        </div>
        <hr class="my-5" />
        <h1 class="text-2xl">Offer redeemed</h1>
        <div class="flex justify-between items-center mt-5">
          <div class="opacity-50" *ngIf="!isQuotaLoading">
            {{ offerRedeem.offerUsed }}/{{ offerRedeem.offerLimit }}
          </div>
          <div *ngIf="isQuotaLoading" class="w-20">
            <svg
              aria-hidden="true"
              class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-main-blue"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span class="sr-only">Loading...</span>
          </div>
          <div class="flex gap-5">
            <div>
              <app-secondary-button
                content="Export Code"
                icon="get_app"
                [isDisable]="isExportLoading"
                (onClick)="exportCode()"
              ></app-secondary-button>
            </div>
          </div>
        </div>
      </div>
      <h1 class="text-2xl my-10">Redeemed customer list ({{ totalCount }})</h1>
      <div class="overflow-x-auto" *ngIf="customerRedeem">
        <table class="min-w-full table-fixed border-separate border-spacing-2">
          <thead>
            <tr class="text-left cursor-pointer">
              <th
                class="pe-2 bg-[#F4F4F4] font-light relative text-center w-[40%]"
                (click)="sortBy('firstName')"
              >
                <span class="text-center">Customer name</span>
                <mat-icon
                  class="absolute right-0 opacity-30 transform scale-75"
                  *ngIf="queryParams.sortBy !== 'firstName'"
                  >arrow_upward</mat-icon
                >
                <mat-icon
                  class="absolute right-0 transform scale-75"
                  *ngIf="
                    queryParams.sortBy === 'firstName' &&
                    queryParams.sortOrderBy === 'asc'
                  "
                  >arrow_upward</mat-icon
                >
                <mat-icon
                  class="absolute right-0 transform scale-75"
                  *ngIf="
                    queryParams.sortBy === 'firstName' &&
                    queryParams.sortOrderBy === 'desc'
                  "
                  >arrow_downward</mat-icon
                >
              </th>

              <th
                class="pe-2 bg-[#F4F4F4] font-light relative text-center w-[35%]"
                (click)="sortBy('usedAt')"
              >
                <span class="text-center">Date/time redeemed</span>
                <mat-icon
                  class="absolute right-0 opacity-30 transform scale-75"
                  *ngIf="queryParams.sortBy !== 'usedAt'"
                  >arrow_upward</mat-icon
                >
                <mat-icon
                  class="absolute right-0 transform scale-75"
                  *ngIf="
                    queryParams.sortBy === 'usedAt' &&
                    queryParams.sortOrderBy === 'asc'
                  "
                  >arrow_upward</mat-icon
                >
                <mat-icon
                  class="absolute right-0 transform scale-75"
                  *ngIf="
                    queryParams.sortBy === 'usedAt' &&
                    queryParams.sortOrderBy === 'desc'
                  "
                  >arrow_downward</mat-icon
                >
              </th>

              <th
                class="pe-2 bg-[#F4F4F4] font-light relative text-center w-[25%]"
              >
                <span class="text-center">Action</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="bg-white border-2 shadow shadow-gray-300 rounded-lg mb-3 transition duration-150 ease-in-out hover:bg-gray-100"
              *ngFor="let customer of customerRedeem"
            >
              <td
                class="px-6 py-4 rounded-tl-lg rounded-bl-lg truncate text-blue-600"
              >
                {{ customer.firstName }} {{ customer.lastName }}
              </td>
              <td class="px-6 py-4 truncate text-center">
                {{ customer.usedAt | transformDate }}
              </td>

              <td class="py-4 px-2 rounded-tr-lg rounded-br-lg truncate">
                <div
                  class="flex items-center justify-end gap-1 text-blue-600 cursor-pointer"
                  [routerLink]="['/admin/customer', customer.userId]"
                >
                  <span>Customer info</span><mat-icon>arrow_forward</mat-icon>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="flex justify-center items-center mt-4">
        <div class="flex items-center cursor-pointer">
          <mat-icon
            (click)="changePage(currentPage - 1)"
            [ngClass]="{ disabled: currentPage === 1 }"
            >chevron_left</mat-icon
          >
          <span>{{ currentPage }}/{{ totalPages }}</span>
          <mat-icon
            (click)="changePage(currentPage + 1)"
            [ngClass]="{ disabled: currentPage === totalPages }"
            >chevron_right</mat-icon
          >
        </div>
      </div>
    </div>
  </ng-container>
  <app-main-loading *ngIf="isLoading"></app-main-loading>
</div>
