import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Role } from 'src/app/shared/enums/roles';
import { IInviteAdmin } from 'src/app/shared/model/form.model';
import { AdminService } from '../../services/admin.service';
import { MatDialog } from '@angular/material/dialog';
import { CompletedPopupComponent } from 'src/app/core/components/completed-popup/completed-popup.component';
import { DiscardFormComponent } from 'src/app/core/components/discard-form/discard-form.component';
import { CanComponentDeactivate } from '../../services/candeactivate.guard';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-admin-invite-admin',
  templateUrl: './admin-invite-admin.component.html',
  styleUrls: ['./admin-invite-admin.component.scss'],
})
export class AdminInviteAdminComponent implements CanComponentDeactivate {
  inviteForm!: FormGroup;
  isLoading = false;
  isSuccess = false;

  roles: any[] = [
    { value: Role.ADMIN, viewValue: 'Admin' },
    { value: Role.SUPERADMIN, viewValue: 'Super Admin' },
  ];

  constructor(
    private fb: FormBuilder,
    private adminService: AdminService,
    public matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.initForm();
  }
  initForm() {
    this.inviteForm = this.fb.group({
      name: [null, Validators.required],
      lastName: [null, Validators.required],
      role: [null, Validators.required],
      phoneNumber: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
    });
  }

  onOpenCompleted() {
    const dialogRefComplete = this.matDialog.open(CompletedPopupComponent, {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      width: '600px',
      data: {
        location: window.location.pathname,
      },
      disableClose: true,
    });
  }

  formSummary(): IInviteAdmin {
    const formSummary = {
      firstName: this.inviteForm.value.name,
      lastName: this.inviteForm.value.lastName,
      email: this.inviteForm.value.email,
      phoneNumber: this.inviteForm.value.phoneNumber,
      role: this.inviteForm.value.role,
    };

    return formSummary;
  }

  onSubmit() {
    if (this.inviteForm.invalid) {
      this.inviteForm.markAllAsTouched();
    } else {
      this.isLoading = true;
      const formSummary = this.formSummary();
      this.adminService.inviteAdmin(formSummary).subscribe({
        next: (data) => {
          console.log(data);
        },
        error: (err) => {
          console.log(err);
        },
        complete: () => {
          this.isSuccess = true;
          this.onOpenCompleted();
        },
      });
    }
  }

  openDiscardForm() {
    const dialogRef = this.matDialog.open(DiscardFormComponent, {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      width: '600px',
      disableClose: true,
    });

    return dialogRef.afterClosed();
  }

  canDeactivate():
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (this.inviteForm.touched && !this.isSuccess) {
      return this.openDiscardForm();
    } else {
      return true;
    }
  }
}
