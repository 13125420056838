import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DataServiceService {
  private offerNotUseSubject: BehaviorSubject<number> =
    new BehaviorSubject<number>(0);
  offerNotUse$ = this.offerNotUseSubject.asObservable();
  constructor() {}

  updateOfferUse(num: number) {
    this.offerNotUseSubject.next(num);
  }

  decreaseOfferNotUse() {
    let value = 0;
    if (this.offerNotUseSubject.value > 0) {
      value = this.offerNotUseSubject.value - 1;
    }
    this.offerNotUseSubject.next(value);
  }

  increaseOfferNotUse() {
    const value = this.offerNotUseSubject.value + 1;
    this.offerNotUseSubject.next(value);
  }
}
