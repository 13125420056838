<div class="container mx-auto px-5 py-10 lg:grid lg:grid-cols-2">
  <div class="lg:col-span-1" *ngIf="lang === 'en'">
    <h1 class="text-2xl">Terms & Conditions</h1>
    <article class="text-[#6F6F6F] mt-5 text-pretty">
      <b>1. Introduction</b> <br />
      Welcome to the Thailand MICE VISA Prepaid Card Welcome Privileges Platform
      ("Platform"). These Terms and Conditions ("Terms") govern your access to
      and use of the Platform, provided by Vathanagul Group Co., Ltd. (VTHG). By
      accessing or using the Platform, you agree to be bound by these Terms.
      <br />
      <br />

      <b>2. Eligibility</b> <br />
      To be eligible to use the Platform, you must be a TCEB member who has
      received a Thailand MICE VISA Prepaid Card . You must provide your name,
      phone number, email, and the first 6 and last 4 digits of your Card to
      sign up for the Platform.

      <br />
      <br />
      <b>3. Campaign Duration</b> <br />
      The campaign will run for three (3) years from the start date.

      <br />
      <br />

      <b>4. Privileges Categories</b> <br />
      The Platform offers three categories of privileges:
      <div class="ps-4">
        <b>a. Welcome Privileges:</b> You can redeem five (5) privileges from
        five (5) different merchants throughout the entire campaign duration.
        Each privilege will be provided as a code, QR code, or bar code, which
        can be used to obtain a discount at the merchant's shop. Note that each
        Welcome Privilege is subject to a quota limit set by the respective
        merchant. <br />
        <b>b. Partners Privileges:</b> Information about TCEB partner privileges
        will be provided on the Platform. You can visit the partner shops to
        redeem these privileges directly. <br />
        <b>c. VISA Privileges:</b> Information about other VISA privileges will
        be available on the Platform. These privileges are for your information
        only, and details on how to redeem them will be provided.
      </div>

      <br />
      <br />

      <b>5. Redemption Process</b> <br />
      All privileges and promotions must be redeemed at the respective merchant
      shops. For Welcome Privileges, you will receive a code, QR code, or bar
      code upon redemption on the Platform, which must be presented at the
      merchant shop to receive the discount.

      <br />
      <br />
      <b>6. Quota Limits</b> <br />
      Each Welcome Privilege is subject to a quota limit, which varies depending
      on the merchant. Once the quota for a specific privilege is reached, no
      further redemptions will be allowed for that privilege.

      <br />
      <br />
      <b>7. User Responsibilities</b> <br />
      a. You are responsible for ensuring the accuracy of the information you
      provide during sign-up. <br />
      b. You must not share your codes, QR codes, or bar codes with others.
      <br />
      c. You must comply with all applicable laws and regulations when using the
      Platform and redeeming privileges.

      <br />
      <br />
      <b>8. Merchant Terms</b> <br />
      Each merchant providing Welcome Offer, Partners Offer, or VISA Offer may
      have its own terms and conditions, which will apply in addition to these
      Terms. You are responsible for reviewing and complying with the merchant's
      terms and conditions.

      <br />
      <br />
      <b>9. Privacy</b> <br />
      Your personal information will be collected, used, and protected in
      accordance with our Privacy Policy. By using the Platform, you consent to
      the collection and use of your personal information as described in the
      Privacy Policy.

      <br />
      <br />
      <b>10. Changes to Terms</b> <br />
      VTHG reserves the right to modify these Terms at any time. Any changes
      will be posted on the Platform, and your continued use of the Platform
      constitutes your acceptance of the revised Terms.

      <br />
      <br />
      <b>11. Termination</b> <br />
      VTHG may terminate or suspend your access to the Platform at any time,
      without prior notice or liability, for any reason, including if you breach
      these Terms.

      <br />
      <br />
      <b>12. Limitation of Liability</b> <br />
      VTHG is not responsible for any loss or damage arising from your use of
      the Platform or the redemption of any privileges. The Platform and all
      privileges are provided "as is" without any warranties of any kind.
      <br />
      <br />
      <b>13. Governing Law</b> <br />
      These Terms shall be governed by and construed in accordance with the laws
      of Thailand, without regard to its conflict of law provisions.

      <br />
      <br />

      <b>14. Contact Information</b> <br />
      If you have any questions about these Terms, please contact us at
      <div class="ps-4">
        <p>
          Vathanagul Group Co., Ltd. 9/9 Soi Serithai 77, Khan Na Yao, Bangkok,
          10230 Tel: 02-9199108 ext.135 from Monday to Friday (9.00 AM – 5.00
          PM)
        </p>
        <p>Email: sun@vathanagul.com</p>
      </div>
      <br /><br />

      By using the website, you acknowledge that you have read, understood, and
      agreed to these Terms and Conditions.
    </article>
  </div>
  <div class="lg:col-span-1" *ngIf="lang === 'th'">
    <h1 class="text-2xl">เงื่อนไขและข้อกำหนด</h1>
    <article class="text-[#6F6F6F] mt-5 text-pretty">
      <b>1. บทนำ</b> <br />
      ยินดีต้อนรับสู่แพลตฟอร์มสิทธิประโยชน์สำหรับบัตร Thailand MICE Visa Prepaid
      ("แพลตฟอร์ม") เงื่อนไขและข้อกำหนดเหล่านี้ ("ข้อกำหนด") กำหนดการเข้าถึง
      และการใช้แพลตฟอร์มของคุณซึ่งจัดทำโดย บริษัท วัฒนกูล กรุ๊ป จำกัด (VTHG)
      โดยการเข้าถึง หรือใช้แพลตฟอร์ม คุณตกลงที่จะปฏิบัติตามข้อกำหนดเหล่านี้
      <br />
      <br />

      <b>2. คุณสมบัติ</b> <br />
      ในการใช้แพลตฟอร์ม คุณต้องเป็นสมาชิกของ TCEB ที่ได้รับบัตร Thailand MICE
      Visa Prepaid คุณต้องให้ชื่อ หมายเลขโทรศัพท์ อีเมล และตัวเลข 6 หลักแรก และ
      4 หลักสุดท้าย ของบัตรเพื่อสมัครใช้งานแพลตฟอร์ม

      <br />
      <br />
      <b>3. ระยะเวลาของแคมเปญ</b> <br />
      แคมเปญจะเริ่มต้นเป็นระยะเวลา 3 ปี นับจากวันที่เริ่มต้น

      <br />
      <br />

      <b>4. ประเภทของสิทธิประโยชน์</b> <br />
      สิทธิประโยชน์แบ่งเป็น 3 ประเภท:
      <div class="ps-4">
        <b>ก. Welcome Offer: </b> คุณสามารถแลกรับสิทธิประโยชน์จากร้านค้าต่างๆ
        ตลอดระยะเวลาแคมเปญ สิทธิประโยชน์แต่ละรายการจะถูกจัดเตรียมในรูปแบบรหัส,
        QR code หรือบาร์โค้ด ซึ่งสามารถใช้รับส่วนลดที่ร้านค้า และ Welcome Offer
        แต่ละรายการมีการจำกัดสิทธิ์ตามที่ร้านค้ากำหนด
        <br />
        <b>ข. Partner Offer:</b> ข้อมูลเกี่ยวกับสิทธิประโยชน์จากพันธมิตรของ TCEB
        จะถูกจัดเตรียมบนแพลตฟอร์ม
        และสามารถตรวจสอบร้านค้าพันธมิตรเพื่อรับสิทธิประโยชน์ได้บนแพลตฟอร์ม
        <br />
        <b>ค. VISA Offer:</b> ข้อมูลเกี่ยวกับสิทธิประโยชน์จาก VISA อื่นๆ
        จะมีอยู่บนแพลตฟอร์ม สิทธิประโยชน์เหล่านี้เป็นเพียงข้อมูลเบื้องต้น
        และสามารถตรวจสอบรายละเอียด, วิธีการแลกรับได้บนแพลตฟอร์ม
      </div>

      <br />
      <br />

      <b>5. ขั้นตอนการแลกสิทธิประโยชน์</b> <br />
      สิทธิประโยชน์ และโปรโมชั่นทั้งหมดต้องแลก ณ ร้านค้า ตามที่กำหนด สำหรับ
      Welcome Offer คุณจะได้รับรหัส, QR code หรือบาร์โค้ด เมื่อแลกบนแพลตฟอร์ม
      ซึ่งต้องแสดง ณ ร้านค้าเพื่อรับส่วนลด

      <br />
      <br />
      <b>6. การจำกัดสิทธิ์</b> <br />
      Welcome Offer แต่ละรายการมีการจำกัดสิทธิ์ ซึ่งแตกต่างกันไปตามแต่ละร้านค้า
      เมื่อสิทธิ์สำหรับสิทธิประโยชน์รายการใดรายการหนึ่งหมดแล้ว
      จะไม่สามารถแลกสิทธิประโยชน์นั้นๆ ได้อีก

      <br />
      <br />
      <b>7. ความรับผิดชอบของผู้ใช้</b> <br />
      ก.
      คุณมีหน้าที่รับผิดชอบในการตรวจสอบความถูกต้องของข้อมูลที่คุณให้ไว้ระหว่างการลงทะเบียน<br />
      ข. คุณไม่สามารถแชร์รหัส, QR Code, หรือบาร์โค้ดของคุณกับผู้อื่น
      <br />
      ค. คุณต้องปฏิบัติตามกฎหมาย และระเบียบข้อบังคับที่เกี่ยวข้องทั้งหมด
      เมื่อใช้แพลตฟอร์ม และการแลกสิทธิประโยชน์

      <br />
      <br />
      <b>8. ข้อกำหนดของร้านค้า</b> <br />
      ร้านค้าที่มอบสิทธิประโยชน์ Welcome Offer, Partners Offer, หรือ VISA Offer
      อาจมีข้อกำหนดและเงื่อนไขของตนเอง ซึ่งจะใช้ร่วมกับข้อกำหนดเหล่านี้
      คุณมีหน้าที่รับผิดชอบในการตรวจสอบ
      และปฏิบัติตามข้อกำหนดและเงื่อนไขของร้านค้า

      <br />
      <br />
      <b>9. ความเป็นส่วนตัว</b> <br />
      ข้อมูลส่วนบุคคลของคุณจะถูกรวบรวม ใช้
      และปกป้องตามนโยบายความเป็นส่วนตัวของเรา โดยการใช้แพลตฟอร์ม
      คุณตกลงให้รวบรวมและใช้ข้อมูลส่วนบุคคลของคุณตามที่ระบุในนโยบายความเป็นส่วนตัว

      <br />
      <br />
      <b>10. การเปลี่ยนแปลงข้อกำหนด</b> <br />
      VTHG ขอสงวนสิทธิ์ในการแก้ไขข้อกำหนดเหล่านี้ได้ตลอดเวลา การเปลี่ยนแปลงใดๆ
      จะถูกโพสต์บนแพลตฟอร์ม
      และการใช้แพลตฟอร์มต่อไปของคุณถือเป็นการยอมรับข้อกำหนดที่แก้ไขแล้ว

      <br />
      <br />
      <b>11. การยกเลิก</b> <br />
      VTHG อาจยกเลิกหรือระงับการเข้าถึงแพลตฟอร์มของคุณได้ตลอดเวลา
      โดยไม่ต้องแจ้งให้ทราบล่วงหน้าหรือรับผิดชอบใดๆ ด้วยเหตุผลใดๆ
      รวมถึงกรณีที่คุณละเมิดข้อกำหนดเหล่านี้
      <br />
      <br />
      <b>12. ข้อจำกัดความรับผิด</b> <br />
      VTHG ไม่รับผิดชอบต่อความสูญเสียหรือความเสียหายใดๆ
      ที่เกิดจากการใช้แพลตฟอร์มหรือการแลกสิทธิประโยชน์ใดๆ
      แพลตฟอร์มและสิทธิประโยชน์ทั้งหมดจัดให้ "ตามสภาพ" โดยไม่มีการรับประกันใดๆ
      <br />
      <br />
      <b>13. กฎหมายที่ใช้บังคับ</b> <br />
      เงื่อนไขการให้บริการนี้จะถูกควบคุมและตีความตามกฎหมายของประเทศไทย
      โดยไม่คำนึงถึงการขัดกันของกฎหมาย
      <br />
      <br />

      <b>14. ข้อมูลการติดต่อ</b> <br />
      หากคุณมีคำถามเกี่ยวกับข้อกำหนดเหล่านี้ โปรดติดต่อเราที่
      <div class="ps-4">
        <p>
          บริษัท วัฒนกูล กรุ๊ป จำกัด 9/9 ซอย เสรีไทย 77 แขวง/เขต คันนายาว
          กรุงเทพมหานคร 10230 โทร: 02-9199108 ต่อ 135 (วันเวลาทำการ จันทร์-ศุกร์
          09.00 – 17.00 น.)
        </p>
        <p>อีเมล: sun@vathanagul.com</p>
      </div>
      <br /><br />

      การใช้เว็บไซต์นี้ถือว่าคุณได้อ่าน เข้าใจ
      และตกลงตามข้อกำหนดและเงื่อนไขข้างต้นแล้ว
    </article>
  </div>
</div>
