export enum OfferTypeEnum {
  WELCOME = 'WELCOME',
  PARTNER = 'PARTNER',
  VISA = 'VISA',
}

export enum ApproveStatusEnum {
  approved = 'approved',
  rejected = 'rejected',
}

export enum QuotaTypeEnum {
  AUTO = 'AUTO',
  FORM = 'FORM',
}

export enum CardHolderEnum {
  THAI = 'THAI',
  INTER = 'INTER',
}
