import { Component } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { scales } from 'chart.js';
import {
  DashboardStats,
  GraphData,
  TopFiveData,
} from 'src/app/shared/model/admin.model';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss'],
})
export class AdminDashboardComponent {
  tabs = [{ title: 'Welcome Offer' }];
  selectedTab = 0;
  isLoading = false;

  monthConstant = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  garphData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  graphData!: GraphData[];
  registrationStat!: DashboardStats;
  redemptionStat!: DashboardStats;
  topFiveData!: TopFiveData[];
  graph: any;
  options: any;

  constructor(private adminService: AdminService) {}

  ngOnInit(): void {
    this.getDashboard();
  }

  getDashboard() {
    this.isLoading = true;
    this.adminService.getDashboard().subscribe({
      next: (data) => {
        console.log(data);
        this.graphData = data.data.registerData.graphRight;
        this.registrationStat = data.data.registerData.tableLeft;
        this.redemptionStat = data.data.offerData.tableLeft;
        this.topFiveData = data.data.offerData.top5Data;
        this.initGarph();
      },

      error: (err) => {
        console.log(err);
        this.isLoading = false;
      },
    });
  }

  initGarph() {
    this.graphData?.forEach((item: any) => {
      this.garphData[item.monthNumber - 1] = item.count;
    });

    this.graph = {
      labels: this.monthConstant,
      datasets: [
        {
          label: 'Customer Registration (no. of customer)',
          backgroundColor: '#006DAD',
          data: this.garphData,
        },
      ],
    };

    this.options = {
      scales: {
        x: { grid: { display: false } },
        y: {
          min: 0, // Enforce a minimum y-value of 0
          suggestedMax: 10, // Suggest 60 as the maximum, but allow higher if data requires
        },
      },
    };

    this.isLoading = false;
  }

  selectTab(index: number) {
    this.selectedTab = index;
  }
}
