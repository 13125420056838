<div class="container mx-auto pt-10 bg-white px-20 mb-20">
  <div class="flex justify-between items-center mb-10">
    <h1 class="text-4xl">Admin manage</h1>
    <div class="w-52">
      <app-primary-button
        content="Invite Admin"
        icon="add"
        routerLink="/admin/invite-admin"
      ></app-primary-button>
    </div>
  </div>
  <div class="overflow-x-auto">
    <table
      class="table-fixed border-separate border-spacing-2 min-w-[650px] w-full"
    >
      <thead>
        <tr class="text-left cursor-pointer">
          <th
            class="pe-2 bg-[#F4F4F4] font-light relative text-center w-[40%]"
            (click)="sortBy('firstName')"
          >
            <div class="flex items-center justify-center group">
              <span class="text-center">Admin name</span>
              <mat-icon
                class="absolute right-0 opacity-30 transform scale-75 invisible group-hover:visible"
                *ngIf="queryParams.sortBy !== 'firstName'"
                >arrow_upward</mat-icon
              >
              <mat-icon
                class="absolute right-0 transform scale-75"
                *ngIf="
                  queryParams.sortBy === 'firstName' &&
                  queryParams.sortOrderBy === 'asc'
                "
                >arrow_upward</mat-icon
              >
              <mat-icon
                class="absolute right-0 transform scale-75"
                *ngIf="
                  queryParams.sortBy === 'firstName' &&
                  queryParams.sortOrderBy === 'desc'
                "
                >arrow_downward</mat-icon
              >
            </div>
          </th>

          <th
            class="pe-2 bg-[#F4F4F4] font-light relative text-center w-[25%]"
            (click)="sortBy('role')"
          >
            <div class="flex items-center justify-center group">
              <span class="text-center">Role</span>
              <mat-icon
                class="absolute right-0 opacity-30 transform scale-75 invisible group-hover:visible"
                *ngIf="queryParams.sortBy !== 'role'"
                >arrow_upward</mat-icon
              >
              <mat-icon
                class="absolute right-0 transform scale-75"
                *ngIf="
                  queryParams.sortBy === 'role' &&
                  queryParams.sortOrderBy === 'asc'
                "
                >arrow_upward</mat-icon
              >
              <mat-icon
                class="absolute right-0 transform scale-75"
                *ngIf="
                  queryParams.sortBy === 'role' &&
                  queryParams.sortOrderBy === 'desc'
                "
                >arrow_downward</mat-icon
              >
            </div>
          </th>
          <th
            class="pe-2 bg-[#F4F4F4] font-light relative text-center w-[20%]"
            (click)="sortBy('createdAt')"
          >
            <div class="flex items-center justify-center group">
              <span class="text-center">Date added</span>
              <mat-icon
                class="absolute right-0 opacity-30 transform scale-75 invisible group-hover:visible"
                *ngIf="queryParams.sortBy !== 'createdAt'"
                >arrow_upward</mat-icon
              >
              <mat-icon
                class="absolute right-0 transform scale-75"
                *ngIf="
                  queryParams.sortBy === 'createdAt' &&
                  queryParams.sortOrderBy === 'asc'
                "
                >arrow_upward</mat-icon
              >
              <mat-icon
                class="absolute right-0 transform scale-75"
                *ngIf="
                  queryParams.sortBy === 'createdAt' &&
                  queryParams.sortOrderBy === 'desc'
                "
                >arrow_downward</mat-icon
              >
            </div>
          </th>
          <th class="pe-2 bg-[#F4F4F4] font-light relative text-center w-[15%]">
            <span class="text-center">Action</span>
          </th>
        </tr>
      </thead>
      <tbody *ngIf="adminList">
        <ng-container *ngFor="let admin of adminList">
          <tr
            class="bg-white border-2 shadow shadow-gray-300 rounded-lg mb-3 transition duration-150 ease-in-out hover:bg-gray-100"
          >
            <td
              class="px-6 py-4 rounded-tl-lg rounded-bl-lg truncate text-blue-600"
            >
              {{ admin.firstName }} {{ admin.lastName }}
            </td>
            <td class="px-6 py-4 truncate text-center">{{ admin.roles }}</td>
            <td class="px-6 py-4 truncate text-center">
              {{ admin.createdAt }}
            </td>

            <td class="py-4 px-2 rounded-tr-lg rounded-br-lg truncate">
              <div
                class="flex items-center justify-end gap-1 text-blue-600 cursor-pointer"
                [routerLink]="['/admin/management', admin._id]"
              >
                <span>Admin info</span><mat-icon>arrow_forward</mat-icon>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="flex justify-center items-center mt-4">
    <div class="flex items-center cursor-pointer">
      <mat-icon
        (click)="changePage(currentPage - 1)"
        [ngClass]="{ disabled: currentPage === 1 }"
        >chevron_left</mat-icon
      >
      <span>{{ currentPage }}/{{ totalPages }}</span>
      <mat-icon
        (click)="changePage(currentPage + 1)"
        [ngClass]="{ disabled: currentPage === totalPages }"
        >chevron_right</mat-icon
      >
    </div>
  </div>
</div>
