<div class="container mx-auto pt-10 bg-white px-20 mb-20">
  <a
    routerLink="/admin/management"
    class="flex items-center gap-2 cursor-pointer text-blue-500 mb-10"
  >
    <mat-icon>arrow_back</mat-icon>
    <span>Admin manage</span>
  </a>
  <h1 class="text-4xl mb-10">Invite admin</h1>
  <form [formGroup]="inviteForm" (ngSubmit)="onSubmit()">
    <div class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl p-6">
      <div class="grid grid-cols-2 gap-x-6 gap-y-4">
        <div>
          <label for="name" class="block text-sm font-medium mb-2 ms-2"
            >Firstname</label
          >
          <input
            type="text"
            id="name"
            name="name"
            required
            class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
            placeholder="Firstname"
            formControlName="name"
            [ngClass]="
              inviteForm.controls['name'].invalid &&
              inviteForm.controls['name'].touched
                ? 'border-2 border-main-red'
                : ''
            "
          />
          <span
            class="text-main-red text-sm font-light"
            *ngIf="
              inviteForm.controls['name']?.invalid &&
              inviteForm.controls['name']?.touched
            "
            >***{{ "Filled information are incorrect" | transloco }}</span
          >
        </div>
        <div>
          <label for="lastName" class="block text-sm font-medium mb-2 ms-2"
            >Lastname</label
          >
          <input
            type="text"
            id="lastName"
            name="lastName"
            required
            class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
            placeholder="Lastname"
            formControlName="lastName"
            [ngClass]="
              inviteForm.controls['lastName'].invalid &&
              inviteForm.controls['lastName'].touched
                ? 'border-2 border-main-red'
                : ''
            "
          />
          <span
            class="text-main-red text-sm font-light"
            *ngIf="
              inviteForm.controls['lastName']?.invalid &&
              inviteForm.controls['lastName']?.touched
            "
            >***{{ "Filled information are incorrect" | transloco }}</span
          >
        </div>

        <div>
          <label for="phone" class="block text-sm font-medium mb-2 ms-2"
            >Phone number</label
          >
          <input
            type="text"
            id="phone"
            name="phone"
            required
            class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
            placeholder="Phone number"
            formControlName="phoneNumber"
            [ngClass]="
              inviteForm.controls['phoneNumber'].invalid &&
              inviteForm.controls['phoneNumber'].touched
                ? 'border-2 border-main-red'
                : ''
            "
          />
          <span
            class="text-main-red text-sm font-light"
            *ngIf="
              inviteForm.controls['phoneNumber']?.invalid &&
              inviteForm.controls['phoneNumber']?.touched
            "
            >***{{ "Filled information are incorrect" | transloco }}</span
          >
        </div>
        <div>
          <label for="email" class="block text-sm font-medium mb-2 ms-2"
            >Email</label
          >
          <input
            type="email"
            id="email"
            name="email"
            required
            class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
            placeholder="Email"
            formControlName="email"
            [ngClass]="
              inviteForm.controls['email'].invalid &&
              inviteForm.controls['email'].touched
                ? 'border-2 border-main-red'
                : ''
            "
          />
          <span
            class="text-main-red text-sm font-light"
            *ngIf="
              inviteForm.controls['email']?.invalid &&
              inviteForm.controls['email']?.touched
            "
            >***{{ "Filled information are incorrect" | transloco }}</span
          >
        </div>
        <div>
          <label for="role" class="block text-sm font-medium mb-2 ms-2"
            >Admin role</label
          >
          <mat-select
            id="role"
            formControlName="role"
            name="role"
            placeholder="Select admin role"
            class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
            [ngClass]="
              inviteForm.controls['role'].invalid &&
              inviteForm.controls['role'].touched
                ? 'border-2 border-main-red'
                : ''
            "
          >
            <mat-option *ngFor="let role of roles" [value]="role.value">
              {{ role.viewValue }}
            </mat-option>
          </mat-select>

          <span
            class="text-main-red text-sm font-light"
            *ngIf="
              inviteForm.controls['role']?.invalid &&
              inviteForm.controls['role']?.touched
            "
            >***{{ "Filled information are incorrect" | transloco }}</span
          >
        </div>
      </div>
    </div>
    <div class="flex gap-5 mt-10">
      <div class="w-56">
        <app-primary-button
          content="Send Invitation"
          *ngIf="!isLoading"
        ></app-primary-button>
        <app-loading-button *ngIf="isLoading"></app-loading-button>
      </div>
      <div class="w-40">
        <app-secondary-button
          routerLink="/admin/management"
          [isDisable]="isLoading"
          content="Cancel"
        ></app-secondary-button>
      </div>
    </div>
  </form>
</div>
