<div class="flex flex-col min-h-screen">
  <header
    class="bg-white p-4 flex justify-center items-center shadow-lg z-20 fixed w-screen"
  >
    <nav class="flex justify-between items-center w-full pr-32">
      <a
        ><img class="w-36" src="../../../assets/logo/tceb-logo.png" alt=""
      /></a>
      <button
        class="flex gap-1 rounded-full px-2 transition ease-in-out delay-150 hover:scale-110 duration-150"
        [matMenuTriggerFor]="profileMenu"
      >
        <mat-icon>person_outline</mat-icon>
        <div *ngIf="user">{{ user.name }}</div>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
    </nav>
  </header>
  <!-- dropdown for icon -->
  <mat-menu #profileMenu="matMenu" xPosition="before" class="w-64">
    <button routerLink="/admin/profile" mat-menu-item>View Profile</button>
    <button routerLink="/admin/change-password" mat-menu-item>
      {{ "Change Password" | transloco }}
    </button>
    <button mat-menu-item (click)="onLogout()">
      <span class="text-main-red">{{ "Logout" | transloco }}</span>
    </button>
  </mat-menu>
  <!-- side nav -->
  <aside
    class="bg-[#F7F7F7] shadow-lg fixed top-0 left-0 h-screen w-72 p-4 pb-10 flex flex-col space-y-4 z-0 pt-32"
  >
    <ul class="space-y-8 text-xl text-[#AAAAAA]">
      <li>
        <a
          routerLink="/admin/dashboard"
          routerLinkActive="text-[#006DAD] "
          [routerLinkActiveOptions]="{ exact: true }"
          class="flex items-center p-2 gap-4 rounded-lg hover:bg-white group transition ease-in-out active:scale-110 duration-150"
        >
          <mat-icon class="transform scale-[1.5]">dashboard</mat-icon>
          <span>Dashboard</span>
        </a>
      </li>
      <li>
        <a
          routerLink="/admin/offers"
          routerLinkActive="text-[#006DAD] "
          [routerLinkActiveOptions]="{ exact: true }"
          class="flex items-center p-2 gap-4 rounded-lg hover:bg-white group transition ease-in-out active:scale-110 duration-150"
        >
          <mat-icon class="transform scale-[1.5]">local_offer</mat-icon>
          <span>Offers</span>
        </a>
      </li>
      <li>
        <a
          routerLink="/admin/customer-list"
          routerLinkActive="text-[#006DAD] "
          [routerLinkActiveOptions]="{ exact: true }"
          class="flex items-center p-2 gap-4 rounded-lg hover:bg-white group transition ease-in-out active:scale-110 duration-150"
        >
          <mat-icon class="transform scale-[1.5]">people_outline</mat-icon>
          <span>Customer list</span>
        </a>
      </li>
      <li *ngIf="role === 'SUPERADMIN'">
        <a
          routerLink="/admin/management"
          routerLinkActive="text-[#006DAD] "
          [routerLinkActiveOptions]="{ exact: true }"
          class="flex items-center p-2 gap-4 rounded-lg hover:bg-white group transition ease-in-out active:scale-110 duration-150"
        >
          <mat-icon class="transform scale-[1.5]">assignment_ind</mat-icon>
          <span>Admin manage</span>
        </a>
      </li>
    </ul>
  </aside>
  <div class="flex-grow ps-72 2xl:ps-60 pt-24">
    <router-outlet></router-outlet>
  </div>
  <footer class="bg-gradient-to-r from-right-blue to-dark-blue text-white p-4">
    <div class="container flex w-full ps-96">
      <span>© 2024 Copyright</span>
    </div>
  </footer>
</div>
