import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-secondary-button',
  templateUrl: './secondary-button.component.html',
  styleUrls: ['./secondary-button.component.scss'],
})
export class SecondaryButtonComponent {
  @Input() content: any = '';
  @Input() isDisable: boolean = false;
  @Output() onClick = new EventEmitter<any>();
  @Input() icon: any = '';

  constructor() {}

  onClickButton(event: any) {
    this.onClick.emit(event);
  }
}
