import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';
import { MessageService } from 'primeng/api';
import { CompletedPopupComponent } from 'src/app/core/components/completed-popup/completed-popup.component';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';
import { ValidateBinNumberService } from 'src/app/services/validate-bin-number.service';
import { IRegisterForm } from 'src/app/shared/model/form.model';
import { UserData } from 'src/app/shared/model/user.model';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  regisForm!: FormGroup;
  successMsg = '';
  isLoading = false;
  errMsg = '';
  errMsgSV = '';
  user!: UserData;
  constructor(
    private fb: FormBuilder,
    public matDialog: MatDialog,
    private userService: UserService,
    private translate: TranslocoService,
    private validateService: ValidateBinNumberService,
    private authService: AuthService,
    private storageService: StorageService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.regisForm = this.fb.group({
      name: [null, Validators.required],
      lastName: [null, Validators.required],
      card: [null, Validators.required],
      phone: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
      cpassword: [null, Validators.required],
      acceptPrivacy: [false],
    });
  }

  formatCardNumber(event: any) {
    const inputValue = event.target.value;

    // Remove non-numeric characters from the input
    const numericValue = inputValue.replace(/\D/g, '');

    // Format the numeric value as desired
    let formattedValue = '';
    for (let i = 0; i < numericValue.length; i++) {
      if (i < 4) {
        formattedValue += numericValue[i];
      } else if (i === 4) {
        formattedValue += ' - ' + numericValue[i];
      } else if (i === 5) {
        formattedValue += numericValue[i];
      } else if (i === 6) {
        formattedValue += 'XX - XXXX - ' + numericValue[i];
      } else if (i >= 7 && i <= 9) {
        formattedValue += numericValue[i];
      }
    }

    // Update the card input field with the formatted value
    this.regisForm.get('card')?.setValue(formattedValue);
  }

  onOpenCompleted() {
    const dialogRef = this.matDialog.open(CompletedPopupComponent, {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      width: '600px',
      disableClose: true,
      data: { location: window.location.pathname, msg: this.successMsg },
    });

    dialogRef.afterClosed().subscribe((data) => {
      const email = this.regisForm.get('email')?.value;
      localStorage.setItem('rememberEmail', email);
      this.loginAfterRegis();
    });
  }

  loginAfterRegis() {
    const bodyLogin = {
      email: this.regisForm.value.email,
      password: this.regisForm.value.password,
    };

    this.authService.login(bodyLogin.email, bodyLogin.password).subscribe({
      next: (data) => {
        this.getUser();
      },
      error: (err) => {
        console.log(err);

        this.messageService.add({
          severity: 'error',
          summary: 'Login Failed',
          detail: 'Please try again!',
          life: 2000,
        });
      },
      complete: () => {
        this.messageService.add({
          severity: 'success',
          summary: 'Login Success',
          detail: 'You are already login!',
          life: 2000,
        });
      },
    });
  }

  getUser() {
    this.userService.getProfile().subscribe((data) => {
      this.user = data.data;
      this.storageService.store('profile', this.user);
    });
  }

  summaryForm(): IRegisterForm {
    const card = this.regisForm.value.card;
    const formatCard = card.split(' - ').join('');
    const regisForm: IRegisterForm = {
      firstName: this.regisForm.value.name,
      lastName: this.regisForm.value.lastName,
      cardNumber: formatCard,
      phoneNumber: this.regisForm.value.phone,
      email: this.regisForm.value.email,
      password: this.regisForm.value.password,
    };

    return regisForm;
  }

  validateCard() {
    return this.validateService.checkPrepaidCardNumber(
      this.regisForm.value.card
    );
  }

  onSubmit() {
    this.errMsg = '';
    this.errMsgSV = '';
    if (this.regisForm.invalid) {
      this.regisForm.markAllAsTouched();
    } else if (
      this.regisForm.controls['password']?.value !==
      this.regisForm.controls['cpassword']?.value
    ) {
      this.errMsg = 'Please correct the confirm password';
    } else if (!this.regisForm.value.acceptPrivacy) {
      this.errMsg = 'Please accept Privacy Policy agreement';
    } else if (!this.validateCard()) {
      this.errMsg = 'Invalid card number.';

      this.regisForm.get('card')?.setErrors({ invalid: true });
    } else {
      this.isLoading = true;
      const regisData = this.summaryForm();
      this.userService.register(regisData).subscribe({
        next: (data) => {
          this.successMsg = data.msg;
          this.isLoading = false;
        },
        error: (err) => {
          console.log(err);
          this.errMsgSV = err.statusMessage;
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
          this.onOpenCompleted();
        },
      });
    }
  }
}
