import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sponsor-banner',
  templateUrl: './sponsor-banner.component.html',
  styleUrls: ['./sponsor-banner.component.scss'],
})
export class SponsorBannerComponent {
  @Input('imageArr') imageArr: string[] = [];
  @Input('isAdjustSize') isAdjustSize: boolean = false;
  @Input('isAdjustMargin') isAdjustMargin: boolean = false;
  responsiveOptions: any[] | undefined;

  ngOnInit() {
    this.responsiveOptions = [
      {
        breakpoint: '1199px',
        numVisible: 5,
        numScroll: 1,
      },
      {
        breakpoint: '991px',
        numVisible: 4,
        numScroll: 1,
      },
      {
        breakpoint: '767px',
        numVisible: 3,
        numScroll: 1,
      },
    ];
  }
}
