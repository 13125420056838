<div
  (click)="onClickButton($event)"
  class="max-w-md mx-auto shadow-md shadow-gray-300 border-2 rounded-2xl overflow-hidden cursor-pointer lg:h-52 lg:max-w-2xl hover:shadow-2xl"
>
  <div
    class="lg:flex lg:h-full"
    [ngClass]="isLoading ? 'relative items-center block' : ''"
  >
    <div
      class="lg:shrink-0 lg:h-full lg:w-72"
      [ngClass]="isLoading ? 'opacity-25 ' : ''"
      [ngClass]="isUsed ? 'relative ' : ''"
    >
      <img
        class="rounded-tl-2xl lg:rounded-bl-2xl max-lg:rounded-tr-2xl h-48 w-full object-cover lg:h-full"
        [src]="image"
        alt="offer image"
        [ngClass]="isUsed && isTimeLeft ? ' opacity-40' : ''"
      />
      <h1
        *ngIf="isUsed && isTimeLeft"
        class="bg-main-red text-white px-8 py-1 left-[-25px] top-3 absolute origin-center -rotate-45 overflow-hidden"
      >
        {{ "Used" | transloco }}
      </h1>
      <h1
        *ngIf="isUsed && !isTimeLeft"
        class="bg-main-blue text-white px-8 py-1 left-[-25px] top-3 absolute origin-center -rotate-45 overflow-hidden"
      >
        {{ "Using" | transloco }}
      </h1>
    </div>

    <div
      class="px-6 py-4 flex flex-col justify-between"
      [ngClass]="isLoading ? 'opacity-25 ' : ''"
    >
      <div class="flex flex-col justify-between h-full">
        <div>
          <h1 class="text-xl">{{ lang === "th" ? offerName : offerNameEN }}</h1>
          <span class="text-xs">{{ lang === "th" ? name : nameEN }}</span>
        </div>
        <p class="text-base opacity-50 mt-4">
          {{ redeemDate | transformDate }}
        </p>
      </div>
      <!-- <p class="text-xs flex gap-2 items-center text-blue-600 max-lg:mt-10">
        {{ "View offer code" | transloco }}<mat-icon>arrow_right_alt</mat-icon>
      </p> -->
    </div>
    <div
      class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
      *ngIf="isLoading"
    >
      <div class="flex space-x-2 justify-center items-center h-full py-20">
        <span class="sr-only">Loading...</span>
        <div
          class="h-8 w-8 bg-dark-blue rounded-full animate-bounce [animation-delay:-0.3s]"
        ></div>
        <div
          class="h-8 w-8 bg-dark-blue rounded-full animate-bounce [animation-delay:-0.15s]"
        ></div>
        <div class="h-8 w-8 bg-dark-blue rounded-full animate-bounce"></div>
      </div>
    </div>
  </div>
</div>
