import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-primary-button',
  templateUrl: './primary-button.component.html',
  styleUrls: ['./primary-button.component.scss'],
})
export class PrimaryButtonComponent {
  @Input() content: any = '';
  @Input() isDisable: boolean = false;
  @Output() onClick = new EventEmitter<any>();
  @Input() icon: any = '';
  @Input() type: any = null;
  @Input() isIconLeft: boolean = true;

  constructor() {}

  onClickButton(event: any) {
    this.onClick.emit(event);
  }
}
