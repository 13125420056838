import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PublicRoutingModule } from '../modules/public/public-routing.module';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../shared/material.module';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharePopupComponent } from './components/share-popup/share-popup.component';
import { CompletedPopupComponent } from './components/completed-popup/completed-popup.component';
import { ConfirmPopupComponent } from './components/confirm-popup/confirm-popup.component';
import { OfferCodeComponent } from './components/offer-code/offer-code.component';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { QRCodeModule } from 'angularx-qrcode';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { AdminConfirmationComponent } from './components/admin-confirmation/admin-confirmation.component';
import { ErrorPopupComponent } from './components/error-popup/error-popup.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { MessageService } from 'primeng/api';
import { DiscardFormComponent } from './components/discard-form/discard-form.component';
import { SponsorBannerComponent } from './components/sponsor-banner/sponsor-banner.component';
import { ConfirmCodeComponent } from './components/confirm-code/confirm-code.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    SharePopupComponent,
    CompletedPopupComponent,
    ConfirmPopupComponent,
    OfferCodeComponent,
    AdminLayoutComponent,
    EditProfileComponent,
    AdminConfirmationComponent,
    ErrorPopupComponent,
    NotFoundComponent,
    DiscardFormComponent,
    SponsorBannerComponent,
    ConfirmCodeComponent,
  ],
  imports: [
    CommonModule,
    PublicRoutingModule,
    SharedModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxBarcode6Module,
    QRCodeModule,
  ],
  exports: [FooterComponent, SponsorBannerComponent],
  providers: [MessageService],
})
export class CoreModule {}
