import { Component } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  constructor(private translocoService: TranslocoService) {}

  ngOnInit(): void {
    const localLang = localStorage.getItem('lang');
    if (localLang) {
      this.translocoService.setActiveLang(localLang);
    } else {
      const activeLang = this.translocoService.getActiveLang();
      this.translocoService.setActiveLang(activeLang);
      localStorage.setItem('lang', activeLang);
    }
  }
}
