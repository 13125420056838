<div class="p-5 w-full">
  <h1 class="text-5xl font-[Ontima-bold] font-bold">
    {{ "Login" | transloco }}
  </h1>
  <button
    class="focus:outline-none absolute top-4 right-4"
    [mat-dialog-close]="false"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M6 18L18 6M6 6l12 12"
      ></path>
    </svg>
  </button>
  <form [formGroup]="userLoginForm" (ngSubmit)="onSubmit()" class="w-full">
    <div class="mt-8 w-full">
      <div class="mb-4 w-full">
        <label for="email" class="block text-sm font-medium mb-2 ms-2">{{
          "Email" | transloco
        }}</label>
        <input
          type="email"
          id="email"
          name="email"
          required
          class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
          [placeholder]="'Email' | transloco"
          formControlName="email"
          [ngClass]="
            (userLoginForm.controls['email'].invalid &&
              userLoginForm.controls['email'].touched) ||
            errmsg
              ? 'border-2 border-main-red'
              : ''
          "
        />
        <span
          class="text-main-red text-sm font-light"
          *ngIf="
            userLoginForm.controls['email']?.invalid &&
            userLoginForm.controls['email']?.touched
          "
          >***{{ "Filled information are incorrect" | transloco }}</span
        >
      </div>
      <div class="sm:mb-6 mb-2 w-full">
        <label for="password" class="block text-sm font-medium mb-2 ms-2">{{
          "Password" | transloco
        }}</label>
        <input
          type="password"
          id="password"
          name="password"
          required
          class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
          [placeholder]="'Password' | transloco"
          formControlName="password"
          [ngClass]="errmsg ? 'border-2 border-main-red' : ''"
        />
      </div>
      <p class="text-main-red font-light ps-2 mb-2" *ngIf="errmsg">
        {{ errmsg }}
      </p>
      <div
        class="flex flex-col items-baseline sm:flex-row sm:items-center justify-between w-full"
      >
        <div class="flex items-center">
          <mat-checkbox
            [color]="'primary'"
            class="example-margin"
            formControlName="isRemember"
          >
          </mat-checkbox>
          <p>{{ "Remember me" | transloco }}</p>
        </div>
        <button
          [mat-dialog-close]="false"
          routerLink="/forgot-password"
          class="hover:underline text-main-blue ps-2"
        >
          {{ "Forgot Password" | transloco }}
        </button>
      </div>
    </div>

    <div class="sm:flex gap-4 mt-10 grid grid-cols-1 w-full">
      <div class="sm:w-44">
        <app-primary-button
          *ngIf="!isLoading"
          [content]="'Login'"
        ></app-primary-button>
        <app-loading-button *ngIf="isLoading"></app-loading-button>
      </div>
      <button
        [mat-dialog-close]="false"
        routerLink="/register"
        class="text-main-blue hover:underline md:text-lg"
      >
        {{ "Register Account" | transloco }}
      </button>
    </div>
  </form>
  <p-toast
    [breakpoints]="{ '767px': { width: '100%', right: '0', left: '0' } }"
  ></p-toast>
</div>
