import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-share-popup',
  templateUrl: './share-popup.component.html',
  styleUrls: ['./share-popup.component.scss'],
})
export class SharePopupComponent implements OnInit {
  url: string = '';
  copied: boolean = false;
  isTimeoutRunning = false;
  copyRunning: number = 0;
  ngOnInit(): void {
    this.url = window.location.href;
  }

  isCopy(event: any) {
    this.copyRunning = 0;
    this.copied = event;
    if (!this.isTimeoutRunning) {
      this.isTimeoutRunning = true;
      const interval = setInterval(() => {
        this.copyRunning++;

        if (this.copyRunning > 3) {
          this.copied = false;
          this.isTimeoutRunning = false;

          clearInterval(interval);
          this.copyRunning = 0;
        }
      }, 1000);
    }
  }

  shareOnSocial(platform: string) {
    if (platform === 'facebook') {
      const facebookShareURL = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        this.url
      )}`;
      window.open(facebookShareURL, '_blank');
    } else if (platform === 'twitter') {
      const twitterShareURL = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
        this.url
      )}`;
      window.open(twitterShareURL, '_blank');
    } else if (platform === 'line') {
      const lineShareURL = `https://line.me/R/msg/text/?${encodeURIComponent(
        this.url
      )}`;
      window.open(lineShareURL, '_blank');
    }
  }
}
