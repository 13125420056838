import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PrimaryButtonComponent } from './components/primary-button/primary-button.component';
import { TranslocoRootModule } from '../transloco-root.module';
import { MainCardComponent } from './components/main-card/main-card.component';
import { SecondaryButtonComponent } from './components/secondary-button/secondary-button.component';
import { SecondaryCardComponent } from './components/secondary-card/secondary-card.component';
import { MaterialModule } from './material.module';
import { LoadingButtonComponent } from './components/loading-button/loading-button.component';
import { MainLoadingComponent } from './components/main-loading/main-loading.component';
import { DeleteButtonComponent } from './components/delete-button/delete-button.component';
import { TransformInnerHTMLPipe } from './utils/transform-inner-html.pipe';
import { ToastModule } from 'primeng/toast';
import { CarouselModule } from 'primeng/carousel';
import { NgOptimizedImage } from '@angular/common';
import { TransformDatePipe } from './utils/transform-date.pipe';

@NgModule({
  declarations: [
    PrimaryButtonComponent,
    MainCardComponent,
    SecondaryButtonComponent,
    SecondaryCardComponent,
    LoadingButtonComponent,
    MainLoadingComponent,
    DeleteButtonComponent,
    TransformInnerHTMLPipe,
    TransformDatePipe,
  ],
  imports: [
    CommonModule,
    TranslocoRootModule,
    MaterialModule,
    ToastModule,
    NgOptimizedImage,
  ],
  exports: [
    PrimaryButtonComponent,
    TranslocoRootModule,
    MainCardComponent,
    SecondaryButtonComponent,
    SecondaryCardComponent,
    LoadingButtonComponent,
    MainLoadingComponent,
    DeleteButtonComponent,
    TransformInnerHTMLPipe,
    ToastModule,
    CarouselModule,
    NgOptimizedImage,
    TransformDatePipe,
  ],
  providers: [DatePipe],
})
export class SharedModule {}
