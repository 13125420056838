import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { ICode } from 'src/app/shared/model/offer.model';
import { ResponsiveService } from 'src/app/shared/utils/responsive.service';
import { ConfirmCodeComponent } from '../confirm-code/confirm-code.component';
import { OfferService } from 'src/app/services/offer.service';
import { TranslocoService } from '@jsverse/transloco';
import { DataServiceService } from 'src/app/shared/utils/data-service.service';

@Component({
  selector: 'app-offer-code',
  templateUrl: './offer-code.component.html',
  styleUrls: ['./offer-code.component.scss'],
})
export class OfferCodeComponent {
  selectedTab = 0;
  barcodeWidth: number = 1;
  qrWidth = 200;
  subscription!: Subscription;
  barcodeFormat!: string;
  onlyBarcode = ['B2S', 'Tops Care', 'Supersports'];
  onlyQrCode = ['Starbuck'];
  onlyCode = ['Tops Online'];
  isBarcodeLoading = false;
  copied: boolean = false;
  isTimeoutRunning = false;
  copyRunning: number = 0;
  timeLeft: any = 15 * 60; // 15 minutes in seconds
  minutes: number = 15;
  seconds: number = 0;
  timeSubscription!: Subscription;
  isUseLoading = false;
  errMsg = '';
  lang!: string;
  isFirstTime = false;

  @ViewChild('barcode', { static: false })
  barcodeCanvas!: ElementRef<HTMLCanvasElement>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ICode,
    @Inject(DOCUMENT) private document: Document,
    public dialogRef: MatDialogRef<OfferCodeComponent>,
    private router: Router,
    private responsiveService: ResponsiveService,
    private changeDetector: ChangeDetectorRef,
    public matDialog: MatDialog,
    private offerService: OfferService,
    private translocoService: TranslocoService,
    private dataService: DataServiceService
  ) {
    const width = this.document.defaultView?.innerWidth;
    this.barcodeWidth = width! < 460 ? 1 : width! < 750 ? 2 : 2;
    this.qrWidth = width! < 500 ? 200 : 256;
  }

  defaultTabs = [
    { title: 'Barcode', value: 'barcode' },
    { title: 'QR code', value: 'qrcode' },
    { title: 'Code', value: 'code' },
  ];

  tabs = [
    { title: 'Barcode', value: 'barcode' },
    { title: 'QR code', value: 'qrcode' },
    { title: 'Code', value: 'code' },
  ];

  ngOnInit(): void {
    this.filterTypeShow();
    this.subscription = this.responsiveService.screenWidth$.subscribe(
      (width) => {
        this.barcodeWidth = width < 460 ? 1 : width < 750 ? 2 : 2;

        this.qrWidth = width! < 500 ? 200 : 256;
      }
    );

    const sub2 = this.translocoService.langChanges$.subscribe((activeLang) => {
      this.lang = activeLang;
    });

    this.subscription.add(sub2);

    switch (this.data.merchantNameEN) {
      case 'Tops Care':
        this.barcodeFormat = 'code93';
        break;
      case 'Supersports':
        this.barcodeFormat = 'code39';
        break;
      default:
        this.barcodeFormat = 'code128';
    }

    if (!this.data.isTimeLeft && this.data.isUsaged) {
      this.timeLeft = this.data.timeLeft;
      this.minutes = Math.floor(this.timeLeft / 60);
      this.seconds = this.timeLeft % 60;

      this.showCode();
    }
  }

  // ngAfterViewInit() {
  //   if (this.tabs[this.selectedTab].value === 'barcode' && this.data.isUsaged) {
  //     this.generateBarcode();
  //   }
  // }

  filterTypeShow() {
    if (this.onlyBarcode.includes(this.data.merchantNameEN)) {
      this.tabs = this.defaultTabs.filter((item) => item.value === 'barcode');
    } else if (this.onlyQrCode.includes(this.data.merchantNameEN)) {
      this.tabs = this.defaultTabs.filter((item) => item.value === 'qrcode');
    } else if (this.onlyCode.includes(this.data.merchantNameEN)) {
      this.tabs = this.defaultTabs.filter((item) => item.value === 'code');
    }
  }

  selectTab(index: number) {
    this.selectedTab = index;
    if (this.tabs[this.selectedTab].value === 'barcode') {
      this.changeDetector.detectChanges();
      this.generateBarcode();
    }
  }

  async generateBarcode() {
    this.isBarcodeLoading = true;
    const canvas = this.barcodeCanvas.nativeElement;

    try {
      // Dynamically import bwip-js for browser usage
      const bwipjs: any = await import('bwip-js');
      const scale = this.dynamicWidth(this.barcodeFormat);
      const height = scale > 1 ? 10 : 20;

      // Generate the barcode
      this.isBarcodeLoading = false;
      bwipjs.toCanvas(canvas, {
        bcid: this.barcodeFormat,
        text: this.data.code,
        scale: scale,
        height: height,
        includetext: true, // Include human-readable text
        textxalign: 'center',
        textyoffset: 5,
      });

      console.log('Barcode generated successfully!');
    } catch (error) {
      this.isBarcodeLoading = false;
      console.error('Error generating barcode:', error);
    }
  }

  dynamicWidth(format: string): number {
    if (format === 'code128') {
      if (this.data.code.length <= 15) {
        return this.barcodeWidth + 1;
      } else {
        return this.barcodeWidth;
      }
    } else {
      if (this.data.code.length <= 13) {
        return this.barcodeWidth + 1;
      } else {
        return this.barcodeWidth;
      }
    }
  }
  isCopy(event: any) {
    this.copyRunning = 0;
    this.copied = event;
    if (!this.isTimeoutRunning) {
      this.isTimeoutRunning = true;
      const interval = setInterval(() => {
        this.copyRunning++;

        if (this.copyRunning > 3) {
          this.copied = false;
          this.isTimeoutRunning = false;

          clearInterval(interval);
          this.copyRunning = 0;
        }
      }, 1000);
    }
  }

  // onOpenConfirmShowCode() {
  //   const option = {
  //     enterAnimationDuration: '100ms',
  //     exitAnimationDuration: '300ms',
  //     disableClose: true,
  //     width: '550px',
  //   };

  //   const dialog = this.matDialog.open(ConfirmCodeComponent, option);
  //   dialog.afterClosed().subscribe((data) => {
  //     if (data) {
  //       this.useOffer();
  //     }
  //   });
  // }

  showCode() {
    this.timeSubscription = interval(1000).subscribe(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
        this.minutes = Math.floor(this.timeLeft / 60);
        this.seconds = this.timeLeft % 60;
      } else {
        this.data.isTimeLeft = true;
        this.dialogRef.close(this.data.isUsaged);
      }
    });
    if (this.tabs[this.selectedTab].value === 'barcode') {
      this.changeDetector.detectChanges();
      this.generateBarcode();
    }
  }

  useOffer() {
    this.errMsg = '';
    this.isUseLoading = true;
    const id = { offerId: this.data.offerId };
    this.offerService.setIsUsaged(id).subscribe({
      next: (res) => {
        this.data.code = res.data.code;
        this.data.isUsaged = true;
        this.data.isTimeLeft = false;
        this.isUseLoading = false;
        this.dataService.decreaseOfferNotUse();
      },
      error: (err) => {
        console.log(err);
        this.isUseLoading = false;
        this.errMsg =
          "We're experiencing a temporary issue. Please try again in a few minutes.";
      },
      complete: () => {
        this.isUseLoading = false;
        this.showCode();
      },
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (this.data.isUsaged) {
      this.timeSubscription.unsubscribe();
    }
  }
}
