import { Component } from '@angular/core';
import { OfferService } from 'src/app/services/offer.service';
import { OffersAdminService } from '../../services/offers-admin.service';
import { OfferTypeEnum } from 'src/app/shared/enums/status';
import { IOfferList } from 'src/app/shared/model/offer.model';

@Component({
  selector: 'app-admin-offers',
  templateUrl: './admin-offers.component.html',
  styleUrls: ['./admin-offers.component.scss'],
})
export class AdminOffersComponent {
  currentPage = 1;
  pageSize: number = 10;
  totalPages: number | undefined;
  totalCount: number | undefined;
  offerList!: IOfferList[];
  isLoading = false;

  queryParams: any = {
    skip: this.currentPage,
    limit: this.pageSize,
    search: null,
    offerType: 'all',
    status: 'all',
    category: null,
    sortBy: 'createdAt',
    sortOrderBy: 'asc',
  };

  offerType = [
    { view: 'All Type', viewValue: 'all' },
    { view: 'Welcome Offer', viewValue: OfferTypeEnum.WELCOME },
    { view: 'Partner Offer', viewValue: OfferTypeEnum.PARTNER },
    { view: 'VISA Offer', viewValue: OfferTypeEnum.VISA },
  ];

  status = [
    { view: 'All Status', viewValue: 'all' },
    { view: 'Start', viewValue: 'start' },
    { view: 'Pause', viewValue: 'pause' },
    { view: 'Full', viewValue: 'full' },
    { view: 'Expired', viewValue: 'expired' },
    { view: 'Cancel', viewValue: 'cancel' },
  ];

  categories = [
    { view: 'Dining', viewValue: 'dining' },
    { view: 'Shopping', viewValue: 'shopping' },
    { view: 'Health&Spa', viewValue: 'health&spa' },
    { view: 'Airport Luggage', viewValue: 'airport luggage' },
    { view: 'Transportation', viewValue: 'transportation' },
    { view: 'Logistic', viewValue: 'logistic' },
    { view: 'Education', viewValue: 'education' },
    { view: 'Accommodation', viewValue: 'accommodation' },
    { view: 'Activity', viewValue: 'activity' },
    { view: 'Meeting Room', viewValue: 'meeting room' },
  ];

  constructor(private offerService: OffersAdminService) {}

  ngOnInit(): void {
    this.getOfferList();
  }

  getOfferList() {
    this.isLoading = true;
    this.offerService.getOfferList(this.queryParams).subscribe({
      next: (data) => {
        console.log(data);
        this.totalPages =
          data.data.totalCount > 0
            ? Math.ceil(data.data.totalCount / this.pageSize)
            : 1;
        this.offerList = data.data.totalData;
        this.totalCount = data.data.totalCount;
        this.isLoading = false;
      },
      error: (err) => {
        console.log(err);
        this.isLoading = false;
      },
    });
  }

  sortBy(sortType: string) {
    if (sortType === this.queryParams.sortBy) {
      if (this.queryParams.sortOrderBy === 'asc') {
        this.queryParams.sortOrderBy = 'desc';
      } else {
        this.queryParams.sortOrderBy = 'asc';
      }
    } else {
      this.queryParams.sortBy = sortType;
      this.queryParams.sortOrderBy = 'asc';
    }
    this.currentPage = 1;
    this.queryParams.skip = this.currentPage;
    this.getOfferList();
  }

  changePage(newPage: number): void {
    if (newPage >= 1 && newPage <= this.totalPages!) {
      this.currentPage = newPage;
      this.queryParams.skip = this.currentPage;
      this.getOfferList();
    }
  }

  applyFilter() {
    console.log(this.queryParams);
    this.currentPage = 1;
    this.queryParams.skip = this.currentPage;
    this.getOfferList();
  }

  resetFilter() {
    this.currentPage = 1;

    this.queryParams = {
      skip: this.currentPage,
      limit: this.pageSize,
      search: null,
      offerType: 'all',
      status: 'all',
      category: null,
      sortBy: 'createdAt',
      sortOrderBy: 'asc',
    };
    this.getOfferList();
  }
}
