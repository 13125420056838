import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CompletedPopupComponent } from 'src/app/core/components/completed-popup/completed-popup.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-activate-callback',
  templateUrl: './activate-callback.component.html',
  styleUrls: ['./activate-callback.component.scss'],
})
export class ActivateCallbackComponent implements OnInit {
  id!: string;
  status!: boolean;
  constructor(
    private route: ActivatedRoute,
    private userService: UserService,
    public matDialog: MatDialog,
    private router: Router
  ) {}

  onOpenCompleted() {
    const dialogRef = this.matDialog.open(CompletedPopupComponent, {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      width: '600px',
      data: { location: window.location.pathname, status: this.status },
      disableClose: true,
    });
  }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'] as string;
    this.userService.activateUser(this.id).subscribe({
      next: (data) => {
        this.status = data.result;
        this.onOpenCompleted();
        this.router.navigate(['/']);
      },
      error: (err) => {
        this.status = err.result;
        this.onOpenCompleted();
        this.router.navigate(['/']);
      },
    });
  }
}
