<div class="p-5">
  <div class="flex flex-col items-center">
    <div class="my-8">
      <!-- <mat-icon class="transform scale-[5]">error_outline</mat-icon> -->
      <img src="../assets/icon/check_circle.svg" alt="done icon" />
    </div>
    <!-- redeem offer -->
    <div>
      <!-- <h1 class="text-2xl tracking-wide text-center mt-10">
        {{ "Important !!" | transloco }}
      </h1>
      <h1 class="text-2xl tracking-wide text-center">
        {{ "Redeem at Shop Counter Only" | transloco }}
      </h1>

      <p class="opacity-50 mt-5 text-center">
        {{
          "Please note that this offer can only be redeemed at the shop counter. Show the code to the cashier to get your discount."
            | transloco
        }}
      </p> -->
      <!-- <h1 class="text-2xl tracking-wide text-center mt-10">
        {{ "Are you sure you want to redeem this offer?" | transloco }}
      </h1>
      <p class="opacity-50 mt-5 text-center">
        {{ "This offer can only be redeemed once" | transloco }}
      </p> -->
      <h1 class="text-2xl tracking-wide text-center">
        {{ "Offer Code Redeemed!" | transloco }}
      </h1>
      <p class="mt-5 text-center">
        <span class="opacity-50">
          {{
            "Your offer code has been successfully redeemed. You can use it now or access it later in the"
              | transloco
          }}
        </span>
        <span
          class="text-main-blue underline cursor-pointer"
          (click)="onClose()"
          routerLink="/history"
          >{{ "Wallet" | transloco }}</span
        >.
      </p>
    </div>

    <p class="text-main-red mt-2">{{ errMsg }}</p>

    <div class="w-full mt-10 grid grid-cols-1 md:grid-cols-2 gap-4">
      <!-- <app-primary-button
        *ngIf="!isLoading"
        content="Confirm & Redeem"
        (onClick)="onConfirm()"
      ></app-primary-button>
      <app-loading-button *ngIf="isLoading"></app-loading-button>
      <app-secondary-button
        content="Cancel"
        [isDisable]="isLoading"
        (onClick)="onClose()"
      ></app-secondary-button> -->
      <app-primary-button
        *ngIf="!isLoading"
        content="Use Now"
        (onClick)="onOpenCode()"
      ></app-primary-button>
      <app-loading-button *ngIf="isLoading"></app-loading-button>
      <app-secondary-button
        content="Later"
        [isDisable]="isLoading"
        (onClick)="onClose()"
      ></app-secondary-button>
    </div>
  </div>
</div>
