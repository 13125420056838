import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'transformInnerHTML',
})
export class TransformInnerHTMLPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(content: string): unknown {
    const sanitizedHtml = this.sanitizer.sanitize(
      SecurityContext.HTML,
      content
    );
    const transformToTailwindFormat = sanitizedHtml
      ?.replace('<ol>', '<ol class="list-disc list-inside">')
      .replace('<li>', '<li class="list-item">')
      .replace(/<a href="(www\.[^"]+)"/g, '<a href="https://$1"')
      .replace('<p><a', '<p class="text-main-blue underline"><a');

    if (transformToTailwindFormat) {
      return transformToTailwindFormat;
    } else {
      return '';
    }
  }
}
