<div class="container mx-auto py-12 px-6 md:p-20">
  <div class="md:grid md:grid-cols-12 md:gap-10">
    <div class="md:col-span-4 max-md:hidden">
      <div class="shadow-md shadow-gray-300 border-2 rounded-2xl w-full p-6">
        <h2 class="mb-4 opacity-50 cursor-pointer" routerLink="/profile">
          {{ "Profile" | transloco }}
        </h2>
        <h2 routerLink="/history" class="mb-4 opacity-50 cursor-pointer">
          {{ "Redemption History" | transloco }}
        </h2>
        <h2 class="mb-4 text-blue-500 cursor-pointer">
          {{ "Change Password" | transloco }}
        </h2>
        <h2 class="text-main-red cursor-pointer" (click)="onLogout()">
          {{ "Logout" | transloco }}
        </h2>
      </div>
    </div>

    <div class="md:col-span-8">
      <h1 class="text-5xl font-[Ontima-bold] font-bold">
        {{ "Change Password" | transloco }}
      </h1>
      <div
        class="shadow-md shadow-gray-300 border-2 rounded-2xl w-full p-6 mt-10"
      >
        <form [formGroup]="changeForm" (ngSubmit)="onSubmit()">
          <div class="mb-5">
            <label
              for="oldPassword"
              class="block text-sm font-medium mb-2 ms-2"
              >{{ "Your password" | transloco }}</label
            >
            <input
              type="password"
              id="oldPassword"
              name="oldPassword"
              required
              class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
              [placeholder]="'Your password' | transloco"
              formControlName="oldPassword"
              [ngClass]="
                (changeForm.controls['oldPassword'].invalid &&
                  changeForm.controls['oldPassword'].touched) ||
                errMsg
                  ? 'border-2 border-main-red'
                  : ''
              "
            />
            <span
              class="text-main-red text-sm font-light"
              *ngIf="
                changeForm.controls['oldPassword']?.invalid &&
                changeForm.controls['oldPassword']?.touched
              "
              >***{{ "Filled information are incorrect" | transloco }}</span
            >
          </div>
          <div class="mb-5">
            <label
              for="newPassword"
              class="block text-sm font-medium mb-2 ms-2"
              >{{ "New password" | transloco }}</label
            >
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              required
              class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
              [placeholder]="'New password' | transloco"
              formControlName="newPassword"
              [ngClass]="
                (changeForm.controls['newPassword'].invalid &&
                  changeForm.controls['newPassword'].touched) ||
                errMsg
                  ? 'border-2 border-main-red'
                  : ''
              "
            />
            <span
              class="text-main-red text-sm font-light"
              *ngIf="
                changeForm.controls['newPassword']?.invalid &&
                changeForm.controls['newPassword']?.touched
              "
              >***{{ "Filled information are incorrect" | transloco }}</span
            >
          </div>
          <div class="mb-5">
            <label
              for="cNewPassword"
              class="block text-sm font-medium mb-2 ms-2"
              >{{ "Confirm new password" | transloco }}</label
            >
            <input
              type="password"
              id="cNewPassword"
              name="cNewPassword"
              required
              class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
              [placeholder]="'Confirm new password' | transloco"
              formControlName="cNewPassword"
              [ngClass]="
                changeForm.controls['cNewPassword'].touched &&
                changeForm.controls['newPassword'].value !==
                  changeForm.controls['cNewPassword'].value
                  ? 'border-2 border-main-red'
                  : ''
              "
            />
            <span
              class="text-main-red text-sm font-light"
              *ngIf="
                changeForm.controls['cNewPassword'].touched &&
                changeForm.controls['newPassword']?.value !==
                  changeForm.controls['cNewPassword']?.value
              "
              >***{{
                "Passwords do not match. Please re-enter your password to confirm."
                  | transloco
              }}</span
            >
          </div>
          <p class="text-main-red" *ngIf="errMsg">{{ errMsg }}</p>
          <div class="md:w-48 mt-10">
            <app-primary-button
              content="Save change"
              *ngIf="!isLoading"
            ></app-primary-button>
            <app-loading-button *ngIf="isLoading"></app-loading-button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
