<div class="container mx-auto pt-10 bg-white px-20 mb-20">
  <a
    routerLink="/admin/management"
    class="flex items-center gap-2 cursor-pointer text-main-blue mb-10"
  >
    <mat-icon>arrow_back</mat-icon>
    <span>Admin manage</span>
  </a>
  <div class="flex justify-between items-center mb-10">
    <h1 class="text-4xl">Admin info</h1>
    <div class="w-56">
      <button
        class="flex justify-between border-2 border-gray-500 w-full p-2 px-4 rounded-full"
        [matMenuTriggerFor]="actionMenu"
      >
        <div>Action</div>
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <!-- dropdown for icon -->
      <mat-menu #actionMenu="matMenu" xPosition="before" class="w-56">
        <!-- <button mat-menu-item><span>Edit Admin Role</span></button> -->
        <button mat-menu-item (click)="onOpenConfirmation(adminData.firstName)">
          <span class="text-main-red">Remove Admin</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <div *ngIf="!isLoading && adminData" class="bg-[#F7F7F7] rounded-3xl p-6">
    <h1 class="text-2xl mb-1">
      {{ adminData.firstName }} {{ adminData.lastName }}
    </h1>
    <p class="opacity-50">{{ adminData.roles }}</p>
    <div class="grid grid-cols-1 md:grid-cols-2 mt-5">
      <div class="flex flex-col">
        <p>Phone number</p>
        <p>{{ adminData.phoneNumber }}</p>
      </div>
      <div class="flex flex-col">
        <p>Email</p>
        <p>{{ adminData.email }}</p>
      </div>
    </div>
  </div>
  <ng-container *ngIf="isLoading">
    <app-main-loading></app-main-loading>
  </ng-container>
</div>
