<div class="container mx-auto pt-10 bg-white px-20 mb-20">
  <div class="flex justify-between items-center mb-10">
    <h1 class="text-4xl">Profile</h1>
    <div class="w-44">
      <app-secondary-button
        content="Edit Profile"
        (onClick)="onOpenEditDialog()"
      ></app-secondary-button>
    </div>
  </div>
  <div
    *ngIf="!isLoading"
    class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl p-6"
  >
    <h1 class="text-4xl mb-1">{{ user.name }}</h1>
    <p class="opacity-50">{{ user.roles }}</p>
    <div class="grid grid-cols-1 md:grid-cols-2 mt-10">
      <div class="flex flex-col">
        <p>Phone number</p>
        <p>{{ user.phoneNumber }}</p>
      </div>
      <div class="flex flex-col">
        <p>Email</p>
        <p>{{ user.email }}</p>
      </div>
    </div>
  </div>
  <ng-container *ngIf="isLoading">
    <app-main-loading></app-main-loading>
  </ng-container>
</div>
