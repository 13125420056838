import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  EMPTY,
  Observable,
  catchError,
  filter,
  finalize,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  refreshUrl = '/auth/refresh';
  private refreshingToken = false; // Flag to track if token refresh is in progress

  private base_path = environment.apiUrl;
  private partner_path =
    this.base_path + 'user/offer/list?skip=1&limit=50&offerType=PARTNER';
  private visa_path =
    this.base_path + 'user/offer/list?skip=1&limit=50&offerType=VISA';
  private welcome_path =
    this.base_path + 'user/offer/list?skip=1&limit=50&offerType=WELCOME';
  private urlToCache = [this.partner_path, this.visa_path, this.welcome_path];

  private responseCache = new Map<
    string,
    { response: HttpEvent<any>; timestamp: number }
  >();
  private cacheExpirationTime = 1800000; // 30 minutes in milliseconds

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const requestWithToken = this.addToken(request);

    const cache = this.responseCache.get(request.urlWithParams);

    if (cache && this.isCacheValid(cache.timestamp)) {
      return of(cache.response);
    }

    if (!this.canCache(request)) {
      return next
        .handle(requestWithToken)
        .pipe(
          catchError((error: HttpErrorResponse) =>
            this.errorAuthHandle(error, request, next)
          )
        );
    } else {
      return next.handle(requestWithToken).pipe(
        tap((res) => {
          // console.log(res);

          this.responseCache.set(request.urlWithParams, {
            response: res,
            timestamp: Date.now(),
          });
        }),
        catchError((error: HttpErrorResponse) =>
          this.errorAuthHandle(error, request, next)
        )
      );
    }
  }

  private addToken(request: HttpRequest<any>): HttpRequest<any> {
    const accessToken = localStorage.getItem('currentUser');
    let value;
    if (accessToken) {
      value = JSON.parse(accessToken);
    } else {
      value = null;
    }
    if (value && !request.url.includes(this.refreshUrl)) {
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${value.accessToken}`,
        },
      });
    }
    return request;
  }

  private canCache(request: HttpRequest<unknown>) {
    return this.urlToCache.some((keyword) =>
      request.urlWithParams.includes(keyword)
    );
  }

  private isCacheValid(timestamp: number): boolean {
    return Date.now() - timestamp < this.cacheExpirationTime;
  }

  private errorAuthHandle(
    error: HttpErrorResponse,
    request: HttpRequest<unknown>,
    next: HttpHandler
  ) {
    if (
      (error.status === 401 || error.error.statusCode === 401) &&
      error.error.statusMessage === 'Unauthorized'
    ) {
      return this.refreshTokenMethod(request, next);
    } else if (
      error.status === 401 &&
      error.error.statusMessage === 'AccessToken is invalid.'
    ) {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('profile');
      window.location.replace('/');

      return EMPTY;
    } else if (
      ((error.status === 403 || error.error.statusCode === 403) &&
        error.error.statusMessage === 'Forbidden resource') ||
      ((error.status === 403 || error.error.statusCode === 403) &&
        error.error.statusMessage === 'Forbidden')
    ) {
      localStorage.removeItem('currentUser');
      localStorage.removeItem('profile');
      window.location.replace('/');

      return EMPTY;
    } else {
      return throwError((): any => error);
    }
  }

  private refreshTokenMethod(request: HttpRequest<unknown>, next: HttpHandler) {
    if (!this.refreshingToken) {
      this.refreshingToken = true; // Set flag to indicate token refresh is in progress
      this.authService.notifyRefreshTokenUpdated(null);

      return this.authService.RefreshToken().pipe(
        switchMap((res: any) => {
          const currentUser = localStorage.getItem('currentUser');

          const value = JSON.parse(currentUser!);

          value.accessToken = res.data.accessToken;
          value.refreshToken = res.data.refreshToken;
          window.localStorage.setItem('currentUser', JSON.stringify(value));
          this.authService.notifyRefreshTokenUpdated(res.data.accessToken);

          request = request.clone({
            setHeaders: { Authorization: 'Bearer ' + res.data.accessToken },
          });

          return next.handle(request);
        }),
        catchError((error) => {
          // Reset the flag if there's an error during token refresh

          this.refreshingToken = false;

          if (
            ((error.status === 401 || error.error.statusCode === 401) &&
              error.error.statusMessage === 'Unauthorized') ||
            ((error.status === 403 || error.error.statusCode === 403) &&
              error.error.statusMessage === 'Access Denied') ||
            ((error.status === 403 || error.error.statusCode === 403) &&
              error.error.statusMessage === 'Forbidden resource') ||
            ((error.status === 403 || error.error.statusCode === 403) &&
              error.error.statusMessage === 'Forbidden')
          ) {
            localStorage.removeItem('currentUser');
            localStorage.removeItem('profile');
            window.location.replace('/');

            return EMPTY;
          } else {
            return throwError(() => error);
          }
        }),
        finalize(() => {
          this.refreshingToken = false;
        })
      );
    } else {
      // If a refresh token request is already in progress, wait for it to complete

      return this.authService.refreshTokenObservable.pipe(
        filter((token) => {
          return token !== null;
        }),
        switchMap((): Observable<HttpEvent<any>> => {
          return next
            .handle(this.addToken(request))
            .pipe(
              catchError((error: HttpErrorResponse) =>
                this.errorAuthHandle(error, request, next)
              )
            );
        })
      );
    }
  }
}
