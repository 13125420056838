import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-completed-popup',
  templateUrl: './completed-popup.component.html',
  styleUrls: ['./completed-popup.component.scss'],
})
export class CompletedPopupComponent {
  closeDialogUrl = [
    '/forgot-password',
    '/profile',
    '/admin/forgot-password',
    '/admin/profile',
    'offerDetail',
  ];

  adminManagementUrl = ['/admin/management', '/admin/invite-admin'];
  offersUrl = [
    '/admin/offer',
    '/admin/create-offer',
    '/admin/edit-offer',
    '/admin/edit-quota',
  ];
  isLogin: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CompletedPopupComponent>,
    private router: Router,
    private authService: AuthService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.isLogin = Boolean(this.authService.currentUserValue);
    // console.log(this.data);
  }

  isAdminManagement(location: string): boolean {
    return this.adminManagementUrl.some((path) => location.startsWith(path));
  }

  isAdminOffers(location: string): boolean {
    return this.offersUrl.some((path) => location.includes(path));
  }

  adminResetPassword() {
    this.dialog.closeAll();
    if (this.isLogin) {
      this.authService.logout(true);
    } else {
      this.router.navigate(['admin/login']);
    }
  }

  onClose() {
    if (this.closeDialogUrl.includes(this.data.location)) {
      this.dialog.closeAll();
    } else if (this.data.location === '/admin/reset-password') {
      this.adminResetPassword();
    } else if (this.data.location.includes('/admin/change-password')) {
      this.dialog.closeAll();
      this.router.navigate(['admin/dashboard']);
    } else if (this.isAdminManagement(this.data.location)) {
      this.dialog.closeAll();
      this.router.navigate(['admin/management']);
    } else if (this.isAdminOffers(this.data.location)) {
      this.dialog.closeAll();
      this.router.navigate(['admin/offers']);
    } else if (this.data.location === '/register') {
      this.dialogRef.close(true);
      this.router.navigate(['/']);
    } else {
      this.dialog.closeAll();
      this.router.navigate(['/']);
    }
  }
}
