<div class="container mx-auto pt-10 bg-white px-20 mb-20">
  <h1 class="text-4xl mb-10">Change Password</h1>

  <form [formGroup]="changeForm" (ngSubmit)="onSubmit()">
    <div class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl p-6">
      <div class="grid grid-cols-2 gap-6">
        <div>
          <label
            for="oldPassword"
            class="block text-sm font-medium mb-2 ms-2"
            >{{ "Current password" | transloco }}</label
          >
          <input
            type="password"
            id="oldPassword"
            name="oldPassword"
            required
            class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
            [placeholder]="'Current password' | transloco"
            formControlName="oldPassword"
            [ngClass]="
              changeForm.controls['oldPassword'].invalid &&
              changeForm.controls['oldPassword'].touched
                ? 'border-2 border-main-red'
                : ''
            "
          />
          <span
            class="text-main-red text-sm font-light"
            *ngIf="
              changeForm.controls['oldPassword']?.invalid &&
              changeForm.controls['oldPassword']?.touched
            "
            >***{{ "Filled information are incorrect" | transloco }}</span
          >
        </div>
        <div></div>
        <div>
          <label
            for="newPassword"
            class="block text-sm font-medium mb-2 ms-2"
            >{{ "New password" | transloco }}</label
          >
          <input
            type="password"
            id="newPassword"
            name="newPassword"
            required
            class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
            [placeholder]="'New password' | transloco"
            formControlName="newPassword"
            [ngClass]="
              changeForm.controls['newPassword'].invalid &&
              changeForm.controls['newPassword'].touched
                ? 'border-2 border-main-red'
                : ''
            "
          />
          <span
            class="text-main-red text-sm font-light"
            *ngIf="
              changeForm.controls['newPassword']?.invalid &&
              changeForm.controls['newPassword']?.touched
            "
            >***{{ "Filled information are incorrect" | transloco }}</span
          >
        </div>
        <div>
          <label
            for="cNewPassword"
            class="block text-sm font-medium mb-2 ms-2"
            >{{ "Confirm new password" | transloco }}</label
          >
          <input
            type="password"
            id="cNewPassword"
            name="cNewPassword"
            required
            class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
            [placeholder]="'Confirm new password' | transloco"
            formControlName="cNewPassword"
            [ngClass]="
              changeForm.controls['cNewPassword'].touched &&
              changeForm.controls['newPassword'].value !==
                changeForm.controls['cNewPassword'].value
                ? 'border-2 border-main-red'
                : ''
            "
          />
          <span
            class="text-main-red text-sm font-light"
            *ngIf="
              changeForm.controls['cNewPassword'].touched &&
              changeForm.controls['newPassword']?.value !==
                changeForm.controls['cNewPassword']?.value
            "
            >***{{ "Filled information are incorrect" | transloco }}</span
          >
        </div>
      </div>
    </div>
    <div class="w-64 mt-10">
      <app-primary-button
        content="Save change"
        *ngIf="!isLoading"
      ></app-primary-button>
      <app-loading-button *ngIf="isLoading"></app-loading-button>
    </div>
  </form>
</div>
