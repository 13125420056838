import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { EditProfileComponent } from 'src/app/core/components/edit-profile/edit-profile.component';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';
import { AdminData } from 'src/app/shared/model/admin.model';

@Component({
  selector: 'app-admin-profile',
  templateUrl: './admin-profile.component.html',
  styleUrls: ['./admin-profile.component.scss'],
})
export class AdminProfileComponent implements OnInit, OnDestroy {
  isLoading = false;
  user!: AdminData;
  subscription!: Subscription;
  constructor(
    private userService: UserService,
    private storageService: StorageService,
    public matDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.subscription = this.storageService.changes.subscribe((data) => {
      if (data.key === 'profile') {
        this.user = data.value;
      }
    });
    this.getUser();
  }

  getUser() {
    this.isLoading = true;
    this.userService.getProfile().subscribe({
      next: (data) => {
        console.log(data.data);
        this.user = data.data;
        this.storageService.store('profile', this.user);
        this.isLoading = false;
      },
      error: (err) => {
        console.log(err);
        this.isLoading = false;
      },
    });
  }

  onOpenEditDialog() {
    const dialogRef = this.matDialog.open(EditProfileComponent, {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      data: {
        location: window.location.pathname,
        data: this.user,
        isAdmin: true,
      },
    });
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
