import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminLoginComponent } from './pages/admin-login/admin-login.component';
import { HeaderComponent } from 'src/app/core/header/header.component';
import { AdminForgetPasswordComponent } from './pages/admin-forget-password/admin-forget-password.component';
import { AdminLayoutComponent } from 'src/app/core/admin-layout/admin-layout.component';
import { AdminOffersComponent } from './pages/admin-offers/admin-offers.component';
import { AdminDashboardComponent } from './pages/admin-dashboard/admin-dashboard.component';
import { AdminCustomerListComponent } from './pages/admin-customer-list/admin-customer-list.component';
import { AdminManagementComponent } from './pages/admin-management/admin-management.component';
import { AdminProfileComponent } from './pages/admin-profile/admin-profile.component';
import { AdminChangePasswordComponent } from './pages/admin-change-password/admin-change-password.component';
import { AdminInviteAdminComponent } from './pages/admin-invite-admin/admin-invite-admin.component';
import { AdminCreateOfferComponent } from './pages/admin-create-offer/admin-create-offer.component';
import {
  authAdminGuard,
  authAdminGuardChild,
} from './services/auth-admin.guard';
import { Role } from 'src/app/shared/enums/roles';
import { AdminResetPasswordComponent } from './pages/admin-reset-password/admin-reset-password.component';
import { AdminManagementDetailComponent } from './pages/admin-management-detail/admin-management-detail.component';
import { AdminCustomerDetailComponent } from './pages/admin-customer-detail/admin-customer-detail.component';
import { AdminOfferDetailComponent } from './pages/admin-offer-detail/admin-offer-detail.component';
import { AdminEditQuotaComponent } from './pages/admin-edit-quota/admin-edit-quota.component';
import { candeactivateGuard } from './services/candeactivate.guard';

const routes: Routes = [
  {
    path: 'login',
    component: AdminLoginComponent,
  },
  {
    path: 'forgot-password',
    component: AdminForgetPasswordComponent,
  },
  {
    path: 'reset-password',
    component: AdminResetPasswordComponent,
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [authAdminGuard],
    canActivateChild: [authAdminGuardChild],
    data: { requiredRoles: [Role.SUPERADMIN, Role.ADMIN] },
    children: [
      {
        path: 'dashboard',
        component: AdminDashboardComponent,
        data: { requiredRoles: [Role.SUPERADMIN, Role.ADMIN] },
      },
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'offers',
        component: AdminOffersComponent,
        data: { requiredRoles: [Role.SUPERADMIN, Role.ADMIN] },
      },
      {
        path: 'offer/:id',
        component: AdminOfferDetailComponent,
        data: { requiredRoles: [Role.SUPERADMIN, Role.ADMIN] },
      },
      {
        path: 'customer-list',
        component: AdminCustomerListComponent,
        data: { requiredRoles: [Role.SUPERADMIN, Role.ADMIN] },
      },
      {
        path: 'customer/:id',
        component: AdminCustomerDetailComponent,
        data: { requiredRoles: [Role.SUPERADMIN, Role.ADMIN] },
      },
      {
        path: 'management',
        component: AdminManagementComponent,
        data: { requiredRoles: [Role.SUPERADMIN] },
      },
      {
        path: 'management/:id',
        component: AdminManagementDetailComponent,
        data: { requiredRoles: [Role.SUPERADMIN] },
      },
      {
        path: 'profile',
        component: AdminProfileComponent,
        data: { requiredRoles: [Role.SUPERADMIN, Role.ADMIN] },
      },
      {
        path: 'change-password',
        component: AdminChangePasswordComponent,
        data: { requiredRoles: [Role.SUPERADMIN, Role.ADMIN] },
      },
      {
        path: 'invite-admin',
        component: AdminInviteAdminComponent,
        canDeactivate: [candeactivateGuard],
        data: { requiredRoles: [Role.SUPERADMIN] },
      },
      {
        path: 'create-offer',
        component: AdminCreateOfferComponent,
        canDeactivate: [candeactivateGuard],
        data: { requiredRoles: [Role.SUPERADMIN, Role.ADMIN] },
      },
      {
        path: 'edit-offer/:id',
        component: AdminCreateOfferComponent,
        canDeactivate: [candeactivateGuard],
        data: { requiredRoles: [Role.SUPERADMIN, Role.ADMIN] },
      },
      {
        path: 'edit-quota/:id',
        component: AdminEditQuotaComponent,
        data: { requiredRoles: [Role.SUPERADMIN, Role.ADMIN] },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
