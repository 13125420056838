<div class="container mx-auto pt-10 bg-white px-20 mb-20">
  <a
    *ngIf="!isEdit"
    routerLink="/admin/offers"
    class="flex items-center gap-2 cursor-pointer text-main-blue"
  >
    <mat-icon>arrow_back</mat-icon>
    <span>Offers</span>
  </a>
  <a
    *ngIf="isEdit"
    [routerLink]="['/admin/offer', id]"
    class="flex items-center gap-2 cursor-pointer text-main-blue"
  >
    <mat-icon>arrow_back</mat-icon>
    <span>Offer info</span>
  </a>

  <h1 class="text-4xl mt-10 mb-14">
    {{ isEdit ? "Edit information" : "Create Offer" }}
  </h1>
  <h1 *ngIf="!isEdit" class="text-4xl mb-5">Offer information</h1>
  <form [formGroup]="createForm" (ngSubmit)="onSubmit()">
    <div class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl p-6">
      <div class="mb-10" *ngIf="!isEdit">
        <label for="offerType" class="block text-sm font-medium mb-2 ms-2">
          Offer type
        </label>
        <mat-select
          id="offerType"
          name="offerType"
          placeholder="Select offer type"
          formControlName="offerType"
          class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
          [ngClass]="
            createForm.controls['offerType'].invalid &&
            createForm.controls['offerType'].touched
              ? 'border-2 border-main-red'
              : ''
          "
        >
          <mat-option *ngFor="let type of offerType" [value]="type.viewValue">
            {{ type.view }}
          </mat-option>
        </mat-select>
      </div>
      <!-- ##Offer Name -->
      <div class="mb-10">
        <label for="offerName" class="block text-sm font-medium mb-2 ms-2">
          Offer name TH
        </label>
        <input
          type="text"
          id="offerName"
          name="offerName"
          required
          class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
          placeholder="Offer name TH"
          formControlName="offerName"
          [ngClass]="
            createForm.controls['offerName'].invalid &&
            createForm.controls['offerName'].touched
              ? 'border-2 border-main-red'
              : ''
          "
        />
        <span
          class="text-main-red text-sm font-light"
          *ngIf="
            createForm.controls['offerName']?.invalid &&
            createForm.controls['offerName']?.touched
          "
          >***{{ "Filled information are incorrect" | transloco }}</span
        >
      </div>
      <div class="mb-10">
        <label for="offerNameEN" class="block text-sm font-medium mb-2 ms-2">
          Offer name EN
        </label>
        <input
          type="text"
          id="offerNameEN"
          name="offerNameEN"
          required
          class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
          placeholder="Offer name EN"
          formControlName="offerNameEN"
          [ngClass]="
            createForm.controls['offerNameEN'].invalid &&
            createForm.controls['offerNameEN'].touched
              ? 'border-2 border-main-red'
              : ''
          "
        />
        <span
          class="text-main-red text-sm font-light"
          *ngIf="
            createForm.controls['offerNameEN']?.invalid &&
            createForm.controls['offerNameEN']?.touched
          "
          >***{{ "Filled information are incorrect" | transloco }}</span
        >
      </div>
      <!-- ##Category -->
      <div class="mb-10">
        <label for="category" class="block text-sm font-medium mb-2 ms-2">
          Category
        </label>
        <mat-select
          id="category"
          name="category"
          placeholder="Category"
          formControlName="category"
          class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
          [ngClass]="
            createForm.controls['category'].invalid &&
            createForm.controls['category'].touched
              ? 'border-2 border-main-red'
              : ''
          "
        >
          <mat-option
            *ngFor="let category of categories"
            [value]="category.viewValue"
          >
            {{ category.view }}
          </mat-option>
        </mat-select>
      </div>
      <!-- ##Offer image -->
      <div class="mb-10">
        <label for="offerImage" class="block text-sm font-medium mb-2 ms-2">
          Offer image
        </label>
        <input
          #offerImageRef
          type="file"
          id="offerImage"
          name="offerImage"
          required
          placeholder="offerImage"
          (change)="onImageSelected($event, 'offerImage')"
          hidden
          accept="image/*"
        />

        <div
          class="bg-[#F7F7F7] p-5 flex items-center justify-between"
          [ngClass]="
            createForm.controls['offerImage'].invalid &&
            createForm.controls['offerImage'].touched
              ? 'border-2 border-main-red'
              : ''
          "
        >
          <h1 *ngIf="!selectedOfferImageName && !isEdit">Upload image</h1>
          <h1 *ngIf="selectedOfferImageName">{{ selectedOfferImageName }}</h1>
          <h1 *ngIf="isEdit && !selectedOfferImageName">
            Upload replace image
          </h1>
          <div class="w-36" *ngIf="!isEdit">
            <app-primary-button
              content="Browse"
              (onClick)="onBrowseClick('offerImage')"
              type="button"
            ></app-primary-button>
          </div>
          <div class="w-52" *ngIf="isEdit">
            <app-secondary-button
              content="Replace Image"
              (onClick)="onBrowseClick('offerImage')"
            ></app-secondary-button>
          </div>
        </div>
        <!-- <div
          *ngIf="previewOfferImage"
          class="mt-4 flex items-center justify-center bg-white shadow-md shadow-gray-300 border-2 rounded-2xl relative"
        >
          <img [src]="previewOfferImage" alt="Preview Image" class="max-h-52" />
          <button
            class="absolute right-1 top-1"
            type="button"
            (click)="onDeleteFile('offerImage')"
          >
            <mat-icon class="opacity-50">close</mat-icon>
          </button>
        </div> -->
        <div
          class="my-3 border-2 p-2 lg:w-[50%] w-96 flex gap-2 items-center relative"
          *ngIf="previewOfferImage || previousOfferImage"
        >
          <div class="w-28 h-28 flex items-center justify-center">
            <img
              *ngIf="previewOfferImage"
              [src]="previewOfferImage"
              alt="offer image"
              class="object-cover h-full w-full"
            />
            <img
              *ngIf="previousOfferImage && !previewOfferImage"
              [src]="previousOfferImage"
              alt="offer image"
              class="object-cover h-full w-full"
            />
          </div>
          <div class="flex flex-col gap-1 w-[60%] overflow-hidden">
            <h1 class="truncate">{{ selectedOfferImageName }}</h1>
            <span *ngIf="selectedOfferImageSize" class="text-xs opacity-50"
              >{{ selectedOfferImageSize }} KB</span
            >
          </div>
          <button
            class="absolute right-0 top-0"
            type="button"
            (click)="onDeleteFile('offerImage')"
            *ngIf="!isEdit || selectedOfferImage"
          >
            <mat-icon class="opacity-50">close</mat-icon>
          </button>
        </div>
        <span
          class="text-main-red text-sm font-light"
          *ngIf="
            createForm.controls['offerImage']?.invalid &&
            createForm.controls['offerImage']?.touched
          "
          >***{{ "Filled information are incorrect" | transloco }}</span
        >
      </div>
      <!-- ##Merchant image -->
      <div class="mb-10">
        <label for="merchantName" class="block text-sm font-medium mb-2 ms-2">
          Merchant name TH
        </label>
        <input
          type="text"
          id="merchantName"
          name="merchantName"
          required
          class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
          placeholder="Merchant name TH"
          formControlName="merchantName"
          [ngClass]="
            createForm.controls['merchantName'].invalid &&
            createForm.controls['merchantName'].touched
              ? 'border-2 border-main-red'
              : ''
          "
        />
        <span
          class="text-main-red text-sm font-light"
          *ngIf="
            createForm.controls['merchantName']?.invalid &&
            createForm.controls['merchantName']?.touched
          "
          >***{{ "Filled information are incorrect" | transloco }}</span
        >
      </div>
      <div class="mb-10">
        <label for="merchantNameEN" class="block text-sm font-medium mb-2 ms-2">
          Merchant name EN
        </label>
        <input
          type="text"
          id="merchantNameEN"
          name="merchantNameEN"
          required
          class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
          placeholder="Merchant name EN"
          formControlName="merchantNameEN"
          [ngClass]="
            createForm.controls['merchantNameEN'].invalid &&
            createForm.controls['merchantNameEN'].touched
              ? 'border-2 border-main-red'
              : ''
          "
        />
        <span
          class="text-main-red text-sm font-light"
          *ngIf="
            createForm.controls['merchantNameEN']?.invalid &&
            createForm.controls['merchantNameEN']?.touched
          "
          >***{{ "Filled information are incorrect" | transloco }}</span
        >
      </div>
      <!-- ##Merchant Logo -->
      <div class="mb-10">
        <label for="merchantImage" class="block text-sm font-medium mb-2 ms-2">
          Merchant logo
        </label>
        <input
          #merchantLogoRef
          type="file"
          id="merchantImage"
          name="merchantImage"
          required
          placeholder="merchantImage"
          (change)="onImageSelected($event, 'merchantImage')"
          hidden
          accept="image/*"
        />

        <div
          class="bg-[#F7F7F7] p-5 flex items-center justify-between"
          [ngClass]="
            createForm.controls['merchantImage'].invalid &&
            createForm.controls['merchantImage'].touched
              ? 'border-2 border-main-red'
              : ''
          "
        >
          <h1 *ngIf="!selectedMerchantImageName && !isEdit">Upload image</h1>
          <h1 *ngIf="isEdit && !selectedMerchantImageName">
            Upload replace image
          </h1>
          <h1 *ngIf="selectedMerchantImageName">
            {{ selectedMerchantImageName }}
          </h1>
          <div class="w-36" *ngIf="!isEdit">
            <app-primary-button
              content="Browse"
              (onClick)="onBrowseClick('merchantImage')"
              type="button"
            ></app-primary-button>
          </div>
          <div class="w-52" *ngIf="isEdit">
            <app-secondary-button
              content="Replace Image"
              (onClick)="onBrowseClick('merchantImage')"
            ></app-secondary-button>
          </div>
        </div>
        <!-- <div
          *ngIf="previewMerchantImage"
          class="mt-4 flex items-center justify-center bg-white shadow-md shadow-gray-300 border-2 rounded-2xl relative"
        >
          <img
            [src]="previewMerchantImage"
            alt="Preview Image"
            class="max-h-52"
          />
          <button
            class="absolute right-1 top-1"
            type="button"
            (click)="onDeleteFile('merchantImage')"
          >
            <mat-icon class="opacity-50">close</mat-icon>
          </button>
        </div> -->
        <div
          class="my-3 border-2 p-2 lg:w-[50%] w-96 flex gap-2 items-center relative"
          *ngIf="previewMerchantImage || previousMerchantImage"
        >
          <div class="w-28 h-28 flex items-center justify-center">
            <div
              class="w-14 h-14 rounded-full shadow-md shadow-gray-300 bg-white absolute"
            >
              <img
                *ngIf="previewMerchantImage"
                [src]="previewMerchantImage"
                alt="merchant image"
                class="rounded-full object-contain w-full h-full"
              />
              <img
                *ngIf="previousMerchantImage && !previewMerchantImage"
                [src]="previousMerchantImage"
                alt="merchant image"
                class="rounded-full object-contain w-full h-full"
              />
            </div>
          </div>
          <div
            class="flex flex-col gap-1 w-[60%] overflow-hidden"
            *ngIf="selectedMerchantImage"
          >
            <h1 class="truncate">{{ selectedMerchantImageName }}</h1>
            <span class="text-xs opacity-50"
              >{{ selectedMerchantImageSize }} KB</span
            >
          </div>
          <button
            *ngIf="!isEdit || selectedMerchantImage"
            class="absolute right-0 top-0"
            type="button"
            (click)="onDeleteFile('merchantImage')"
          >
            <mat-icon class="opacity-50">close</mat-icon>
          </button>
        </div>
        <span
          class="text-main-red text-sm font-light"
          *ngIf="
            createForm.controls['merchantImage']?.invalid &&
            createForm.controls['merchantImage']?.touched
          "
          >***{{ "Filled information are incorrect" | transloco }}</span
        >
      </div>
      <!-- ##short desc -->
      <!-- <div class="mb-5">
        <label
          for="shortDescription"
          class="block text-sm font-medium mb-2 ms-2"
        >
          Short Description
        </label>
        <textarea
          name="shortDescription"
          id="shortDescription"
          formControlName="shortDescription"
          placeholder="Short Description Here..."
          rows="5"
          class="w-full resize-y rounded-md border-2 border-gray-400 font-light p-2"
          [ngClass]="
            createForm.controls['shortDescription'].invalid &&
            createForm.controls['shortDescription'].touched
              ? 'border-2 border-main-red'
              : ''
          "
        ></textarea>
        <span
          class="text-main-red text-sm font-light"
          *ngIf="
            createForm.controls['shortDescription']?.invalid &&
            createForm.controls['shortDescription']?.touched
          "
          >***{{ "Filled information are incorrect" | transloco }}</span
        >
      </div> -->
      <div>
        <label class="block text-sm font-medium mb-2 ms-2">
          Offer Period
        </label>
        <div class="relative">
          <mat-date-range-input
            [rangePicker]="picker"
            class="border-2 border-gray-400 w-full p-2 px-4 rounded-full"
            [ngClass]="
              (createForm.controls['offerStart'].invalid &&
                createForm.controls['offerStart'].touched) ||
              (createForm.controls['offerEnd'].invalid &&
                createForm.controls['offerEnd'].touched)
                ? 'border-2 border-main-red'
                : ''
            "
          >
            <input
              matStartDate
              formControlName="offerStart"
              placeholder="Start date"
            />
            <input
              matEndDate
              formControlName="offerEnd"
              placeholder="End date"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            class="absolute right-0 top-0"
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </div>
        <span
          class="text-main-red text-sm font-light"
          *ngIf="
            (createForm.controls['offerStart']?.invalid &&
              createForm.controls['offerStart']?.touched) ||
            (createForm.controls['offerEnd']?.invalid &&
              createForm.controls['offerEnd']?.touched)
          "
          >***{{ "Filled information are incorrect" | transloco }}</span
        >
      </div>
      <div
        class="mt-5"
        *ngIf="
          offerDetail?.offerType === 'VISA' ||
          createForm.get('offerType')?.value === 'VISA'
        "
      >
        <label for="cardHolder" class="block text-sm font-medium mb-2 ms-2">
          Cardholders
        </label>
        <mat-select
          id="cardHolder"
          name="cardHolder"
          placeholder="Select Cardholders"
          formControlName="cardHolder"
          class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
          [ngClass]="
            createForm.controls['cardHolder'].invalid &&
            createForm.controls['cardHolder'].touched
              ? 'border-2 border-main-red'
              : ''
          "
        >
          <mat-option *ngFor="let type of cardHolder" [value]="type.viewValue">
            {{ type.view }}
          </mat-option>
        </mat-select>
      </div>
    </div>
    <div
      class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl p-6 mt-5"
    >
      <div class="mb-5">
        <label for="offerDetail" class="block text-sm font-medium mb-2 ms-2">
          Offer detail TH
        </label>
        <p-editor
          #offerDetailEditor
          formControlName="offerDetail"
          [style]="{ height: '208px' }"
          [readonly]="false"
        >
          <ng-template pTemplate="header">
            <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button>
              <button class="ql-list" value="bullet"></button>
              <button class="ql-link"></button>
            </span> </ng-template
        ></p-editor>
        <span
          class="text-main-red text-sm font-light"
          *ngIf="
            createForm.controls['offerDetail']?.invalid &&
            createForm.controls['offerDetail']?.touched
          "
          >***{{ "Filled information are incorrect" | transloco }}</span
        >
      </div>
      <div>
        <label for="offerDetailEN" class="block text-sm font-medium mb-2 ms-2">
          Offer detail EN
        </label>
        <p-editor
          #offerDetailEditorEN
          formControlName="offerDetailEN"
          [style]="{ height: '208px' }"
          [readonly]="false"
        >
          <ng-template pTemplate="header">
            <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button>
              <button class="ql-list" value="bullet"></button>
              <button class="ql-link"></button>
            </span> </ng-template
        ></p-editor>
        <span
          class="text-main-red text-sm font-light"
          *ngIf="
            createForm.controls['offerDetailEN']?.invalid &&
            createForm.controls['offerDetailEN']?.touched
          "
          >***{{ "Filled information are incorrect" | transloco }}</span
        >
      </div>
    </div>
    <div
      class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl p-6 mt-5"
    >
      <div class="mb-5">
        <label for="terms" class="block text-sm font-medium mb-2 ms-2">
          Terms & Conditions TH
        </label>
        <p-editor
          #termsEditor
          formControlName="terms"
          [style]="{ height: '208px' }"
          [readonly]="false"
        >
          <ng-template pTemplate="header">
            <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button>
              <button class="ql-list" value="bullet"></button>
              <button class="ql-link"></button>
            </span> </ng-template
        ></p-editor>
        <span
          class="text-main-red text-sm font-light"
          *ngIf="
            createForm.controls['terms']?.invalid &&
            createForm.controls['terms']?.touched
          "
          >***{{ "Filled information are incorrect" | transloco }}</span
        >
      </div>
      <div>
        <label for="termsEN" class="block text-sm font-medium mb-2 ms-2">
          Terms & Conditions EN
        </label>
        <p-editor
          #termsEditorEN
          formControlName="termsEN"
          [style]="{ height: '208px' }"
          [readonly]="false"
        >
          <ng-template pTemplate="header">
            <span class="ql-formats">
              <button class="ql-bold"></button>
              <button class="ql-italic"></button>
              <button class="ql-underline"></button>
              <button class="ql-list" value="bullet"></button>
              <button class="ql-link"></button>
            </span> </ng-template
        ></p-editor>
        <span
          class="text-main-red text-sm font-light"
          *ngIf="
            createForm.controls['termsEN']?.invalid &&
            createForm.controls['termsEN']?.touched
          "
          >***{{ "Filled information are incorrect" | transloco }}</span
        >
      </div>
    </div>
    <ng-container
      *ngIf="createForm.get('offerType')?.value === 'WELCOME' && !isEdit"
    >
      <h1 class="text-4xl mb-5 mt-20">Redemtion quota</h1>
      <div class="mb-5 w-96">
        <label for="quotaType" class="block text-sm font-medium mb-2 ms-2">
          Code Generating
        </label>
        <mat-select
          id="quotaType"
          name="quotaType"
          placeholder="Select offer type"
          formControlName="quotaType"
          class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
          [ngClass]="
            createForm.controls['quotaType'].invalid &&
            createForm.controls['quotaType'].touched
              ? 'border-2 border-main-red'
              : ''
          "
        >
          <mat-option *ngFor="let type of quotaType" [value]="type.viewValue">
            {{ type.view }}
          </mat-option>
        </mat-select>
      </div>
      <ng-container [ngSwitch]="createForm.get('quotaType')?.value">
        <div
          class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl p-6 mt-5"
          *ngIf="createForm.get('quotaType')?.value"
        >
          <div class="mb-5" *ngSwitchCase="'FORM'">
            <label for="formUpload" class="block text-sm font-medium mb-2 ms-2">
              Upload Code File
            </label>
            <input
              #fileRef
              type="file"
              id="formUpload"
              name="formUpload"
              (change)="onFileSelected($event)"
              hidden
              accept=".csv"
            />

            <div
              class="my-3 border-2 p-2 w-96 flex gap-2 items-center relative"
              *ngIf="selectedFile"
            >
              <div class="w-20 h-20 flex items-center justify-center">
                <img src="../assets/icon/csv.png" alt="csv icon" />
              </div>
              <div class="flex flex-col gap-1">
                <h1>{{ selectedFileName }}</h1>
                <span class="text-xs opacity-50"
                  >{{ selectedFileSize }} KB</span
                >
              </div>
              <button
                class="absolute right-0 top-0"
                type="button"
                (click)="onDeleteFile('formUpload')"
              >
                <mat-icon class="opacity-50">close</mat-icon>
              </button>
            </div>

            <div
              class="bg-[#F7F7F7] p-5 flex items-center justify-between"
              [ngClass]="
                createForm.controls['formUpload'].invalid &&
                createForm.controls['formUpload'].touched
                  ? 'border-2 border-main-red'
                  : ''
              "
            >
              <h1>Upload only .csv file</h1>

              <div class="w-36">
                <app-primary-button
                  content="Browse"
                  (onClick)="onBrowseClick('formUpload')"
                  type="button"
                ></app-primary-button>
              </div>
            </div>

            <span
              class="text-main-red text-sm font-light"
              *ngIf="
                createForm.controls['formUpload']?.invalid &&
                createForm.controls['formUpload']?.touched
              "
              >***{{ "Filled information are incorrect" | transloco }}</span
            >
            <div class="mt-2 mb-4">
              <h1 class="mb-2 text-center">
                *Your CSV file MUST contain the following columns in this order:
                "no" and "code". (lower case)*
              </h1>
              <mat-accordion>
                <mat-expansion-panel
                  (opened)="panelOpenState = true"
                  (closed)="panelOpenState = false"
                >
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      Click to {{ panelOpenState ? "close" : "show" }} the
                      example file pattern
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <img
                    src="../assets/examplecsv.png"
                    alt="example csv"
                  /> </mat-expansion-panel
              ></mat-accordion>
            </div>
            <div class="text-sm">
              <h1>CUATION!!</h1>
              <ul class="list-disc ps-4">
                <li class="list item">
                  Duplicate codes within the file or from previous uploads will
                  be accepted, BUT:
                  <ol class="list-decimal ps-4">
                    <li>This will result in duplicate redemption codes</li>
                    <li>
                      Duplicate redemption codes may cause issues or errors when
                      users attempt to use them.
                    </li>
                  </ol>
                </li>
                <li class="list item">
                  To avoid potential problems for your users, carefully review
                  your data for duplicate codes before uploading.
                </li>
              </ul>
            </div>
          </div>
          <div>
            <label for="offerLimit" class="block text-sm font-medium mb-2 ms-2">
              Number of code
            </label>
            <input
              type="text"
              id="offerLimit"
              name="offerLimit"
              (input)="onlyNumberAccept($event)"
              class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
              placeholder="Number of code"
              formControlName="offerLimit"
              [ngClass]="
                createForm.controls['offerLimit'].invalid &&
                createForm.controls['offerLimit'].touched
                  ? 'border-2 border-main-red'
                  : ''
              "
            />
            <span
              class="text-main-red text-sm font-light"
              *ngIf="
                createForm.controls['offerLimit']?.invalid &&
                createForm.controls['offerLimit']?.touched
              "
              >***{{ "Filled information are incorrect" | transloco }}</span
            >
          </div>
        </div>
      </ng-container>
    </ng-container>
    <p *ngIf="errMsg" class="mt-2.5 text-main-red">{{ errMsg }}</p>
    <div class="flex items-center gap-5 mt-10">
      <div class="w-56">
        <app-primary-button
          [content]="isEdit ? 'Save change' : 'Create Offer'"
          *ngIf="!isLoading"
        ></app-primary-button>
        <app-loading-button *ngIf="isLoading"></app-loading-button>
      </div>
      <div class="w-40">
        <app-secondary-button
          content="Cancel"
          [isDisable]="isLoading"
          [routerLink]="isEdit ? ['/admin/offer', id] : ['/admin/offers']"
        ></app-secondary-button>
      </div>
    </div>
  </form>
</div>
