import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PublicRoutingModule } from './public-routing.module';
import { CoreModule } from 'src/app/core/core.module';
import { HomeComponent } from './pages/home/home.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { PrimaryButtonComponent } from 'src/app/shared/components/primary-button/primary-button.component';
import { TranslocoService } from '@jsverse/transloco';
import { PrivilegeDetailComponent } from './pages/privilege-detail/privilege-detail.component';
import { RegisterComponent } from './pages/register/register.component';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { MaterialModule } from 'src/app/shared/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileComponent } from './pages/profile/profile.component';
import { RedemptionHistoryComponent } from './pages/redemption-history/redemption-history.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { QRCodeModule } from 'angularx-qrcode';
import { TransfromCardPipe } from 'src/app/shared/utils/transfrom-card.pipe';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ActivateCallbackComponent } from './pages/activate-callback/activate-callback.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { TermsComponent } from './pages/terms/terms.component';
import { PolicyComponent } from './pages/policy/policy.component';
import { ManualComponent } from './pages/manual/manual.component';

@NgModule({
  declarations: [
    HomeComponent,
    PrivilegeDetailComponent,
    RegisterComponent,
    ForgetPasswordComponent,
    ProfileComponent,
    RedemptionHistoryComponent,
    ChangePasswordComponent,
    TransfromCardPipe,
    ResetPasswordComponent,
    ActivateCallbackComponent,
    TermsComponent,
    PolicyComponent,
    ManualComponent,
  ],
  imports: [
    CommonModule,
    PublicRoutingModule,
    SharedModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxBarcode6Module,
    QRCodeModule,
    CoreModule,
    InfiniteScrollModule,
  ],
  providers: [TranslocoService],
})
export class PublicModule {}
