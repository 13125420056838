<div class="p-5 w-80 md:w-96">
  <h1 class="text-5xl font-[Ontima-bold] font-bold">
    {{ "Share Offer" | transloco }}
  </h1>
  <button
    class="focus:outline-none absolute top-4 right-4"
    mat-dialog-close="true"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M6 18L18 6M6 6l12 12"
      ></path>
    </svg>
  </button>
  <div class="mt-8">
    <label for="url" class="block text-sm font-medium mb-2 ms-2">{{
      "URL" | transloco
    }}</label>
    <div class="mb-4 flex items-center gap-2">
      <input
        type="text"
        id="url"
        [(ngModel)]="url"
        name="url"
        required
        class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light max-sm:text-xs"
        readonly
      />
      <button
        [cdkCopyToClipboard]="url"
        (cdkCopyToClipboardCopied)="isCopy($event)"
        class="flex items-center justify-center active:ring-2 active:ring-green-700 rounded-xl p-2 relative"
      >
        <div
          *ngIf="copied"
          class="absolute bottom-[-40px] font-medium bg-green-600 rounded-full text-white p-2 text-sm md:text-base"
        >
          copied
        </div>
        <mat-icon>filter_none</mat-icon>
      </button>
    </div>
  </div>
  <hr />
  <div class="mt-4">
    <h2 class="text-2xl">Share to</h2>
    <div class="flex items-center gap-3 mt-4">
      <button
        class="transition ease-in-out delay-150 hover:scale-110 duration-150"
        (click)="shareOnSocial('facebook')"
      >
        <img
          src="../assets/logo/facebook-6338507_1280.png"
          alt="logo"
          class="w-10 h-10 rounded-full object-cover"
        />
      </button>
      <button
        class="transition ease-in-out delay-150 hover:scale-110 duration-150"
        (click)="shareOnSocial('twitter')"
      >
        <img
          src="../assets/logo/twitter.jpg"
          class="w-10 h-10 rounded-full object-cover"
          alt="logo"
        />
      </button>
      <button
        class="transition ease-in-out delay-150 hover:scale-110 duration-150"
        (click)="shareOnSocial('line')"
      >
        <img
          src="../assets/logo/LINE_logo.svg.png"
          class="w-10 h-10 rounded-full object-cover"
          alt="logo"
        />
      </button>
    </div>
  </div>
</div>
