import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CompletedPopupComponent } from 'src/app/core/components/completed-popup/completed-popup.component';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
  changeForm!: FormGroup;
  errMsg = '';
  token: any;
  isLoading = false;
  constructor(
    private fb: FormBuilder,
    public matDialog: MatDialog,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams['token'];
    if (!this.token) {
      this.router.navigate(['']);
    }
    this.initForm();
  }
  initForm() {
    this.changeForm = this.fb.group({
      newPassword: [null, Validators.required],
      cNewPassword: [null, Validators.required],
    });
  }

  onOpenCompleted() {
    const dialogRef = this.matDialog.open(CompletedPopupComponent, {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      width: '600px',
      data: { location: window.location.pathname },
      disableClose: true,
    });
  }

  formSummary() {
    const changeData = {
      password: this.changeForm.value.newPassword,
    };

    return changeData;
  }

  onSubmit() {
    this.errMsg = '';
    if (this.changeForm.invalid) {
      this.changeForm.markAllAsTouched();
    } else if (
      this.changeForm.controls['newPassword']?.value !==
      this.changeForm.controls['cNewPassword']?.value
    ) {
      alert('please correct the confirm password');
    } else {
      this.isLoading = true;
      const changeData = this.formSummary();
      this.userService.resetPassword(changeData, this.token).subscribe({
        next: (data) => {
          this.isLoading = false;
        },
        error: (err) => {
          this.errMsg = err.statusMessage;
          this.isLoading = false;
        },
        complete: () => {
          this.isLoading = false;
          this.onOpenCompleted();
        },
      });
    }
  }
}
