<div class="container mx-auto py-12 px-6 md:p-20">
  <a routerLink="/" class="flex items-center gap-2 cursor-pointer">
    <mat-icon>arrow_back</mat-icon>
    <span>{{ "Back to home" | transloco }}</span>
  </a>
  <div class="shadow-md shadow-gray-300 border-2 rounded-2xl w-full mt-10 p-6">
    <h1 class="text-5xl font-bold font-[Ontima-bold]">
      {{ "Register" | transloco }}
    </h1>
    <form [formGroup]="regisForm" (ngSubmit)="onSubmit()">
      <div class="md:grid md:grid-cols-2 md:gap-6 md:mt-8">
        <div class="mb-5 mt-10 md:m-0">
          <label for="name" class="block text-sm font-medium mb-2 ms-2">{{
            "Firstname" | transloco
          }}</label>
          <input
            type="text"
            id="name"
            name="name"
            required
            class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
            [placeholder]="'Firstname' | transloco"
            formControlName="name"
            [ngClass]="
              regisForm.controls['name'].invalid &&
              regisForm.controls['name'].touched
                ? 'border-2 border-main-red'
                : ''
            "
          />
          <span
            class="text-main-red text-sm font-light"
            *ngIf="
              regisForm.controls['name']?.invalid &&
              regisForm.controls['name']?.touched
            "
            >***{{ "Filled information are incorrect" | transloco }}</span
          >
        </div>
        <div class="mb-5">
          <label for="lastname" class="block text-sm font-medium mb-2 ms-2">{{
            "Lastname" | transloco
          }}</label>
          <input
            type="text"
            id="lastname"
            name="lastname"
            required
            class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
            [placeholder]="'Lastname' | transloco"
            formControlName="lastName"
            [ngClass]="
              regisForm.controls['lastName'].invalid &&
              regisForm.controls['lastName'].touched
                ? 'border-2 border-main-red'
                : ''
            "
          />
          <span
            class="text-main-red text-sm font-light"
            *ngIf="
              regisForm.controls['lastName']?.invalid &&
              regisForm.controls['lastName']?.touched
            "
            >***{{ "Filled information are incorrect" | transloco }}</span
          >
        </div>
      </div>

      <div class="mb-5">
        <label for="card" class="block text-sm font-medium mb-2 ms-2">{{
          "Card no." | transloco
        }}</label>
        <input
          type="text"
          id="card"
          name="card"
          required
          minlength="25"
          class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
          placeholder="_ _ _ _ - _ _ XX - XXXX - _ _ _ _"
          formControlName="card"
          (input)="formatCardNumber($event)"
          [ngClass]="
            regisForm.controls['card'].invalid &&
            regisForm.controls['card'].touched
              ? 'border-2 border-main-red'
              : ''
          "
        />
        <span
          class="text-main-red text-sm font-light"
          *ngIf="
            regisForm.controls['card']?.invalid &&
            regisForm.controls['card']?.touched
          "
          >***{{ "Filled information are incorrect" | transloco }}</span
        >
        <p class="mt-2 text-sm">
          *{{ "6 digits first and 4 digits last" | transloco }}
        </p>
      </div>
      <div class="md:grid md:grid-cols-2 md:gap-6">
        <div class="mb-5 md:m-0">
          <label for="phone" class="block text-sm font-medium mb-2 ms-2">{{
            "Phone number" | transloco
          }}</label>
          <input
            type="text"
            id="phone"
            name="phone"
            required
            minlength="10"
            maxlength="10"
            class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
            [placeholder]="'Phone number' | transloco"
            formControlName="phone"
            [ngClass]="
              regisForm.controls['phone'].invalid &&
              regisForm.controls['phone'].touched
                ? 'border-2 border-main-red'
                : ''
            "
          />
          <span
            class="text-main-red text-sm font-light"
            *ngIf="
              regisForm.controls['phone']?.invalid &&
              regisForm.controls['phone']?.touched
            "
            >***{{ "Filled information are incorrect" | transloco }}</span
          >
        </div>
        <div class="mb-5 md:m-0">
          <label for="email" class="block text-sm font-medium mb-2 ms-2">{{
            "Email" | transloco
          }}</label>
          <input
            type="email"
            id="email"
            name="email"
            required
            class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
            [placeholder]="'Email' | transloco"
            formControlName="email"
            [ngClass]="
              regisForm.controls['email'].invalid &&
              regisForm.controls['email'].touched
                ? 'border-2 border-main-red'
                : ''
            "
          />
          <span
            class="text-main-red text-sm font-light"
            *ngIf="
              regisForm.controls['email']?.invalid &&
              regisForm.controls['email']?.touched
            "
            >***{{ "Filled information are incorrect" | transloco }}</span
          >
        </div>

        <div class="mb-5 md:m-0">
          <label for="password" class="block text-sm font-medium mb-2 ms-2">{{
            "Password" | transloco
          }}</label>
          <input
            type="password"
            id="password"
            name="password"
            required
            minlength="8"
            class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
            [placeholder]="'Password' | transloco"
            formControlName="password"
            [ngClass]="
              regisForm.controls['password'].invalid &&
              regisForm.controls['password'].touched
                ? 'border-2 border-main-red'
                : ''
            "
          />
          <span
            class="text-main-red text-sm font-light"
            *ngIf="
              regisForm.controls['password']?.invalid &&
              regisForm.controls['password']?.touched
            "
            >***{{ "Filled information are incorrect" | transloco }}</span
          >
          <p class="mt-2 text-sm">
            *<b>{{ "Password" | transloco }}</b
            >: {{ "Minimum 8 characters" | transloco }}
          </p>
        </div>
        <div class="mb-5 md:m-0">
          <label for="cpassword" class="block text-sm font-medium mb-2 ms-2">{{
            "Confirm password" | transloco
          }}</label>
          <input
            type="password"
            id="cpassword"
            name="cpassword"
            required
            class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
            [placeholder]="'Confirm password' | transloco"
            formControlName="cpassword"
            [ngClass]="
              regisForm.controls['cpassword'].touched &&
              regisForm.controls['password'].value !==
                regisForm.controls['cpassword'].value
                ? 'border-2 border-main-red'
                : ''
            "
          />
          <span
            class="text-main-red text-sm font-light"
            *ngIf="
              regisForm.controls['cpassword'].touched &&
              regisForm.controls['password']?.value !==
                regisForm.controls['cpassword']?.value
            "
            >***{{ "Filled information are incorrect" | transloco }}</span
          >
        </div>
      </div>
      <div class="mt-5">
        <p class="text-[#6F6F6F] ps-3">
          {{ "register privacy" | transloco }}
          <a
            routerLink="/privacy-policy"
            class="underline text-main-blue font-medium"
            >{{ "our Privacy Policy" | transloco }}</a
          >
          {{ "share permis" | transloco }}
        </p>
        <div class="mt-3 flex items-baseline">
          <mat-checkbox
            [color]="'primary'"
            class="example-margin"
            formControlName="acceptPrivacy"
          >
          </mat-checkbox>
          <p>
            {{ "I have read, understand, and agree to the" | transloco }}
            <a
              routerLink="/privacy-policy"
              class="underline text-main-blue font-medium"
              >{{ "Privacy Policy" | transloco }}</a
            >.
          </p>
        </div>
      </div>
      <div class="mt-10">
        <p *ngIf="errMsg" class="text-main-red px-3 mb-5">
          {{ errMsg | transloco }}
        </p>
        <p *ngIf="errMsgSV" class="text-main-red px-3 mb-5">{{ errMsgSV }}</p>
        <div class="sm:w-44">
          <app-primary-button
            *ngIf="!isLoading"
            content="Register"
          ></app-primary-button>
          <app-loading-button *ngIf="isLoading"></app-loading-button>
        </div>
      </div>
    </form>
  </div>
</div>
