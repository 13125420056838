<div class="p-5">
  <div class="flex flex-col items-center">
    <div
      class="my-8"
      *ngIf="
        !data.location.includes('/auth/activate') &&
        data.location !== 'offerDetail'
      "
    >
      <!-- <mat-icon class="transform scale-[5] text-[#399816]"
        >check_circle_outline</mat-icon
      > -->
      <img src="../assets/icon/check_circle.svg" alt="done icon" />
    </div>
    <div class="my-8" *ngIf="data.location.includes('/auth/activate')">
      <img
        *ngIf="data.status"
        src="../assets/icon/check_circle.svg"
        alt="done icon"
      />
      <img
        *ngIf="!data.status"
        src="../assets/icon/error.svg"
        alt="done icon"
      />
    </div>
    <div class="my-8" *ngIf="data.location === 'offerDetail'">
      <img
        *ngIf="!data.status"
        src="../assets/icon/error.svg"
        alt="done icon"
      />
    </div>
    <div *ngIf="data.location === 'offerDetail'">
      <h1 class="text-2xl tracking-wide text-center">
        {{ "We're experiencing a temporary issue." | transloco }}
      </h1>

      <p class="opacity-50 mt-5 text-center">
        {{ "Please try again in a few minutes." | transloco }}
      </p>
    </div>

    <!-- register page -->
    <div *ngIf="data.location === '/register'">
      <!-- <h1 class="text-2xl tracking-wide text-center">
        {{ "Your register has been submitted" | transloco }}
      </h1>

      <p class="opacity-50 mt-5 text-center">
        {{ "Please check your email inbox for the activate link" | transloco }}
      </p> -->
      <h1 class="text-2xl tracking-wide text-center">
        {{ "Register complete" | transloco }}
      </h1>

      <p class="opacity-50 mt-5 text-center">
        {{ "You can start redeeming and enjoy offers now" | transloco }}
      </p>
    </div>
    <!-- activate page -->
    <div *ngIf="data.location.includes('/auth/activate')">
      <ng-container *ngIf="data.status">
        <h1 class="text-2xl tracking-wide text-center">
          {{ "Register complete" | transloco }}
        </h1>

        <p class="opacity-50 mt-5 text-center">
          {{ "You can start redeeming and enjoy offers now" | transloco }}
        </p>
      </ng-container>
      <ng-container *ngIf="!data.status">
        <h1 class="text-2xl tracking-wide text-center">
          {{
            "We're having trouble activating your account right now" | transloco
          }}
        </h1>

        <p class="opacity-50 mt-5 text-center">
          {{
            "Please try logging in directly. If you encounter any problems, please contact us for assistance"
              | transloco
          }}
        </p>
      </ng-container>
    </div>
    <!-- forgot password page -->
    <div
      *ngIf="
        data.location === '/forgot-password' ||
        data.location === '/admin/forgot-password'
      "
    >
      <h1 class="text-2xl tracking-wide text-center">
        {{ "Your email has been submitted" | transloco }}
      </h1>

      <p class="opacity-50 mt-5 text-center">
        {{
          "Please check your email inbox for the reset password link"
            | transloco
        }}
      </p>
    </div>
    <!-- profile page -->
    <div
      *ngIf="data.location === '/profile' || data.location === '/admin/profile'"
    >
      <h1 class="text-2xl tracking-wide text-center">
        {{ "Your profile has been saved" | transloco }}
      </h1>
    </div>

    <!-- changepassword page -->
    <div
      *ngIf="
        data.location === '/change-password' ||
        data.location === '/admin/change-password'
      "
    >
      <h1 class="text-2xl tracking-wide text-center">
        {{ "Your new password has been successfully saved" | transloco }}
      </h1>

      <p class="opacity-50 mt-5 text-center">
        {{
          "You can now access your account using your new password" | transloco
        }}
      </p>
    </div>
    <!-- reset password page -->
    <div
      *ngIf="
        data.location.includes('/reset-password') ||
        data.location.includes('/admin/reset-password')
      "
    >
      <h1 class="text-2xl tracking-wide text-center">
        {{ "Your password has been successfully reset" | transloco }}
      </h1>

      <p class="opacity-50 mt-5 text-center">
        {{
          "You can now access your account using your new password" | transloco
        }}
      </p>
    </div>
    <!-- delete admin page -->
    <div *ngIf="data.location.includes('/admin/management')">
      <h1 class="text-2xl tracking-wide text-center">
        {{ data.name }} has been removed
      </h1>
    </div>

    <!-- invite admin page -->
    <div *ngIf="data.location.includes('/admin/invite-admin')">
      <h1 class="text-2xl tracking-wide text-center">
        Admin invitation has been sent
      </h1>

      <p class="opacity-50 mt-5 text-center">
        We will send an admin name and first time password to admin via email
        address
      </p>
    </div>
    <!-- admin offer page -->
    <div *ngIf="data.location.includes('/admin/offer')">
      <h1
        class="text-2xl tracking-wide text-center"
        *ngIf="data.action === 'pause'"
      >
        Offer redemption has been {{ data.isPause ? "paused" : "resumed" }}
      </h1>
      <h1
        class="text-2xl tracking-wide text-center"
        *ngIf="data.action === 'delete'"
      >
        Offer has been deleted
      </h1>
    </div>
    <!-- admin create offer page -->
    <div *ngIf="data.location.includes('/admin/create-offer')">
      <h1 class="text-2xl tracking-wide text-center">Offer has been created</h1>
    </div>
    <!-- admin create offer page -->
    <div *ngIf="data.location.includes('/admin/edit-offer')">
      <h1 class="text-2xl tracking-wide text-center">Offer has been edited</h1>
    </div>
    <!-- admin create offer page -->
    <div *ngIf="data.location.includes('/admin/edit-quota')">
      <h1 class="text-2xl tracking-wide text-center">
        Redemption quota has been updated
      </h1>
    </div>

    <div class="w-full mt-10">
      <app-primary-button
        content="Done"
        (onClick)="onClose()"
      ></app-primary-button>
    </div>
  </div>
</div>
