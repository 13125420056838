import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PublicModule } from './modules/public/public.module';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from './transloco-root.module';
import { MaterialModule } from './shared/material.module';
import { TokenInterceptor } from './services/token.interceptor';
import { PermissionsService } from './services/auth.guard';
import { AdminModule } from './modules/admin/admin.module';
import { AdminPermissionsService } from './modules/admin/services/auth-admin.guard';
import { EditorModule } from 'primeng/editor';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    PublicModule,
    AdminModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    EditorModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    PermissionsService,
    AdminPermissionsService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
