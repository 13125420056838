<div class="container mx-auto pt-10 bg-white px-20 mb-20">
  <h1 class="text-4xl mb-10">Dashboard</h1>
  <div class="grid grid-cols-1 md:grid-cols-12 gap-6" *ngIf="!isLoading">
    <div
      class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl p-6 md:col-span-4 flex flex-col justify-between"
      *ngIf="registrationStat"
    >
      <div class="flex flex-col">
        <h1 class="opacity-50 mb-5">Total Customer Registration</h1>

        <h2 class="text-5xl">{{ registrationStat.totalAll }}</h2>

        <hr class="my-5" />
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div class="flex flex-col">
            <p class="opacity-50 text-sm">Today</p>
            <h2 class="text-3xl">{{ registrationStat.totalDay }}</h2>
          </div>
          <div class="flex flex-col">
            <p class="opacity-50 text-sm">This Week</p>
            <h2 class="text-3xl">{{ registrationStat.totalWeek }}</h2>
          </div>
          <div class="flex flex-col">
            <p class="opacity-50 text-sm">This Month</p>
            <h2 class="text-3xl">{{ registrationStat.totalMonth }}</h2>
          </div>
          <div class="flex flex-col">
            <p class="opacity-50 text-sm">This Year</p>
            <h2 class="text-3xl">{{ registrationStat.totalYear }}</h2>
          </div>
        </div>
      </div>
      <div
        class="flex gap-2 text-main-blue cursor-pointer"
        routerLink="/admin/customer-list"
      >
        <span>View Customer list</span><mat-icon>arrow_right_alt</mat-icon>
      </div>
    </div>

    <div
      class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl p-6 md:col-span-8 max-h-96"
    >
      <h1 class="text-2xl mb-5">Customer Registration</h1>
      <p-chart
        type="bar"
        [data]="graph"
        [options]="options"
        height="90%"
      ></p-chart>
    </div>
  </div>
  <div
    class="mt-14 mb-10 flex gap-16 max-sm:justify-around text-center text-2xl lg:text-4xl font-medium"
    *ngIf="!isLoading"
  >
    <div
      *ngFor="let tab of tabs; let i = index"
      (click)="selectTab(i)"
      class="cursor-pointer transition ease-in-out active:scale-110 duration-150 text-gray-300 font-[Ontima]"
      [ngClass]="{ 'tab-item': true, active: i === selectedTab }"
    >
      {{ tab.title | transloco }}
    </div>
  </div>
  <ng-container [ngSwitch]="tabs[selectedTab].title" *ngIf="!isLoading">
    <div
      class="grid grid-cols-1 md:grid-cols-2 gap-6"
      *ngSwitchCase="'Welcome Offer'"
    >
      <div
        class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl p-6 flex flex-col justify-between"
        *ngIf="redemptionStat"
      >
        <div class="flex flex-col">
          <h1 class="opacity-50 mb-5">Total Redemption</h1>

          <h2 class="text-5xl">{{ redemptionStat.totalAll }}</h2>

          <hr class="my-5" />
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div class="flex flex-col">
              <p class="opacity-50 text-sm">Today</p>
              <h2 class="text-3xl">{{ redemptionStat.totalDay }}</h2>
            </div>
            <div class="flex flex-col">
              <p class="opacity-50 text-sm">This Week</p>
              <h2 class="text-3xl">{{ redemptionStat.totalWeek }}</h2>
            </div>
            <div class="flex flex-col">
              <p class="opacity-50 text-sm">This Month</p>
              <h2 class="text-3xl">{{ redemptionStat.totalMonth }}</h2>
            </div>
            <div class="flex flex-col">
              <p class="opacity-50 text-sm">This Year</p>
              <h2 class="text-3xl">{{ redemptionStat.totalYear }}</h2>
            </div>
          </div>
        </div>
        <div
          class="flex gap-2 text-main-blue cursor-pointer mt-10"
          routerLink="/admin/offers"
        >
          <span>View Offers list</span><mat-icon>arrow_right_alt</mat-icon>
        </div>
      </div>
      <div class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl p-6">
        <h1 class="text-2xl mb-5">Top 5 Redeemed</h1>

        <div
          *ngIf="topFiveData.length > 0"
          class="flex flex-col justify-start gap-4 font-medium mt-4"
        >
          <div
            class="flex justify-between bg-[#F7F7F7] p-2.5"
            *ngFor="let data of topFiveData; let index = index"
          >
            <p>{{ index + 1 }} {{ data.offerName }}</p>
            <p>{{ data.count }}</p>
          </div>
        </div>
        <div
          class="text-center h-1/2 flex items-center justify-center"
          *ngIf="topFiveData.length === 0"
        >
          <h1>No any redemption</h1>
        </div>
      </div>
    </div>
  </ng-container>
  <app-main-loading *ngIf="isLoading"></app-main-loading>
</div>
