<!-- banner -->
<div class="bg-gradient-to-r from-[#84C4EA] to-[#006DAD] w-full">
  <div
    class="flex flex-col md:flex-row items-center justify-center 2xl:gap-52 gap-10 md:p-24 py-10 px-6"
  >
    <div class="md:w-3/6 max-w-[550px]">
      <img
        class="w-full object-cover"
        src="../assets/Card-face-TCEB-new.png"
        alt="credit card"
      />
    </div>
    <div class="md:w-3/6 max-w-[550px] px-3.5">
      <h1
        class="font-bold text-4xl text-center md:text-5xl md:text-start text-white tracking-wide leading-relaxed font-[Ontima-bold]"
      >
        Thailand MICE VISA Prepaid Card
      </h1>
      <p class="text-white text-center md:text-start mt-2 md:mt-4">
        {{
          "Let's take advantage of exclusive offers for Thailand MICE Visa Prepaid Cardholders"
            | transloco
        }}
      </p>
      <div class="mt-8 lg:w-3/5 xl:w-2/5">
        <app-primary-button
          [content]="'Discover Offers'"
          (onClick)="scroll(content)"
        ></app-primary-button>
      </div>
    </div>
  </div>
</div>

<!-- content -->
<div class="container mx-auto pt-12 px-6 lg:p-24 lg:pb-0">
  <div
    class="grid grid-cols-3 gap-1 text-center text-xl sm:text-3xl md:text-4xl lg:text-5xl font-medium border-b-2"
  >
    <div
      *ngFor="let tab of tabs; let i = index"
      (click)="onSelectTab(i, 'main')"
      class="cursor-pointer transition ease-in-out active:scale-110 duration-150 text-gray-300 font-[Ontima]"
      [ngClass]="{ 'tab-item': true, active: i === selectedTab }"
    >
      {{ tab.title | transloco }}
    </div>
  </div>
  <!-- visa banner -->
  <div
    *ngIf="tabs[selectedTab].title === 'VISA Offer'"
    class="flex flex-col md:flex-row items-center justify-center 2xl:gap-52 gap-10 mt-10"
  >
    <div class="md:w-3/6 max-w-[550px] md:p-5 rounded-lg">
      <img
        class="w-full object-cover rounded-lg"
        src="../assets/banner.jpg"
        alt="banner picture"
      />
    </div>
    <div class="md:w-3/6 max-w-[550px] px-3.5">
      <h1 class="font-bold text-4xl md:text-5xl text-start font-[Ontima-bold]">
        {{
          "Discover worldwide privileges with VISA Commercial Card" | transloco
        }}
      </h1>
      <p class="text-start opacity-50 mt-2 md:mt-4 text-sm">
        {{
          "Eligibility for privileges depends on the type of card" | transloco
        }}
      </p>
      <p class="text-start opacity-50 mt-2 text-sm">
        {{ "Term&Condition apply" | transloco }}
      </p>
      <div class="mt-8 lg:w-3/5 xl:w-2/5">
        <a
          href="https://www.visa.co.th/th_th/visa-commercial-offers/"
          target="_blank"
        >
          <app-primary-button
            [content]="'Discover'"
            icon="exit_to_app"
            [isIconLeft]="false"
          ></app-primary-button>
        </a>
      </div>
    </div>
  </div>
  <!-- filter -->
  <div *ngIf="tabs[selectedTab].title !== 'Welcome Offer'" class="mt-10">
    <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
      <div *ngIf="tabs[selectedTab].title === 'VISA Offer'">
        <label for="cardholder" class="block text-sm mb-2 ms-2">
          Cardholder
        </label>
        <mat-select
          id="cardholder"
          [(ngModel)]="queryParams.cardHolder"
          name="cardholder"
          placeholder="Select cardholder"
          class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
          (selectionChange)="onSelectCardHolder($event)"
        >
          <mat-option *ngFor="let type of tabsVisa" [value]="type.value">{{
            type.title
          }}</mat-option>
        </mat-select>
      </div>
      <div>
        <label for="category" class="block text-sm mb-2 ms-2">
          {{ "Filter Category" | transloco }}
        </label>
        <mat-select
          id="category"
          [(ngModel)]="filterArray"
          name="category"
          placeholder="Select Category"
          class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
          multiple
          #categorySelect
          (ngModelChange)="filterCategory($event)"
        >
          <ng-container *ngIf="tabs[selectedTab].title === 'Partners Offer'">
            <mat-option
              *ngFor="let category of filterListPartner"
              [value]="category"
              >{{ category }}</mat-option
            >
          </ng-container>
          <ng-container
            *ngIf="
              tabs[selectedTab].title === 'VISA Offer' &&
              queryParams.cardHolder === 'INTER'
            "
          >
            <mat-option
              *ngFor="let category of filterListInter"
              [value]="category"
              >{{ category }}</mat-option
            >
          </ng-container>
          <ng-container
            *ngIf="
              tabs[selectedTab].title === 'VISA Offer' &&
              queryParams.cardHolder === 'THAI'
            "
          >
            <mat-option
              *ngFor="let category of filterListThai"
              [value]="category"
              >{{ category }}</mat-option
            >
          </ng-container>
        </mat-select>
      </div>
    </div>
    <div class="flex flex-wrap gap-2 mt-5">
      <mat-chip-row
        *ngFor="let category of filterArray"
        (removed)="remove(category)"
        color="primary"
        highlighted
      >
        {{ category.toLocaleUpperCase() }}
        <button matChipRemove aria-label="'remove ' + keyword">
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip-row>
    </div>
  </div>

  <div class="mt-10 w-full" #content>
    <!-- Content for the selected offer goes here -->
    <ng-container [ngSwitch]="tabs[selectedTab].title">
      <ng-container *ngSwitchCase="'Welcome Offer'">
        <!-- <div
          class="bg-[#F7F7F7] w-full p-5 rounded-2xl mb-10"
          *ngIf="isUser && role === 'USER'"
        >
          <h1 class="text-2xl font-medium">{{ "Notice" | transloco }}</h1>
          <p class="opacity-50 mt-4">
            {{
              "Please redeem your welcome offers in 3 months or before"
                | transloco
            }}
            {{ redeemEnd | date : "dd/MMM/YYYY" }}
          </p>
        </div> -->

        <div
          class="flex items-center justify-center"
          infinite-scroll
          [infiniteScrollDistance]="5"
          [infiniteScrollThrottle]="1000"
          (scrolled)="onScroll()"
        >
          <!-- Welcome Offer Content -->
          <div
            class="flex flex-col w-full md:flex-row md:flex-wrap gap-4 md:items-stretch md:justify-start lg:max-w-[720px] xl:max-w-[1088px]"
          >
            <!-- <ng-container *ngIf="isUser && role === 'USER'"> -->
            <ng-container *ngIf="!isLoading || pagination">
              <app-main-card
                *ngFor="let content of offerList; let index = index"
                [routerLink]="['offer', content._id]"
                [id]="content._id"
                [name]="content.merchantName"
                [nameEN]="content.merchantNameEN"
                [title]="content.offerName"
                [titleEN]="content.offerNameEN"
                [logo]="content.merchantImage"
                [image]="content.offerImage"
                [desc]="content.shortDescription"
                [descEN]="content.shortDescriptionEN"
                [category]="content.category"
              ></app-main-card>
            </ng-container>
            <!-- </ng-container> -->
          </div>
          <!-- <div
            class="bg-[#F7F7F7] py-10 px-5 lg:px-32 rounded-2xl w-full"
            *ngIf="!isUser || role !== 'USER'"
          >
            <div class="bg-white p-5 lg:px-24 rounded-2xl w-full text-center">
              <div class="flex items-center justify-center">
                <img src="../assets/icon/Offer_empty.svg" alt="empty icon" />
              </div>
              <h1 class="text-xl md:text-2xl font-medium">
                {{
                  "Please Login or Register to get a Welcome Offer" | transloco
                }}
              </h1>
              <p class="opacity-50 mt-5">
                {{
                  "Let's get offers for your Thailand MICE VISA Prepaid Card"
                    | transloco
                }}
              </p>
              <div class="flex justify-center items-center mt-10 w-full">
                <div class="w-52">
                  <app-primary-button
                    (onClick)="onOpenLoginDialog()"
                    content="Login / Register"
                  ></app-primary-button>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </ng-container>

      <div
        *ngSwitchCase="'Partners Offer'"
        class="flex items-center justify-center"
        infinite-scroll
        [infiniteScrollDistance]="5"
        [infiniteScrollThrottle]="1000"
        (scrolled)="onScroll()"
      >
        <!-- Partners Offer Content -->
        <div
          class="flex flex-col w-full md:flex-row md:flex-wrap gap-4 md:items-stretch md:justify-start lg:max-w-[720px] xl:max-w-[1088px]"
        >
          <ng-container *ngIf="!isLoading || pagination">
            <app-main-card
              *ngFor="let content of filterOffer"
              [routerLink]="['offer', content._id]"
              [id]="content._id"
              [name]="content.merchantName"
              [nameEN]="content.merchantNameEN"
              [title]="content.offerName"
              [titleEN]="content.offerNameEN"
              [logo]="content.merchantImage"
              [image]="content.offerImage"
              [desc]="content.shortDescription"
              [descEN]="content.shortDescriptionEN"
              [category]="content.category"
            ></app-main-card>
          </ng-container>
          <div
            class="md:text-2xl w-full"
            *ngIf="filterOffer && filterOffer.length === 0 && !isLoading"
          >
            <h1 class="text-center w-full">
              {{
                "We couldn't find any items matching your selected categories."
                  | transloco
              }}
            </h1>
            <h1 class="text-center w-full">
              {{
                "Try broadening your search or clearing some filters."
                  | transloco
              }}
            </h1>
          </div>
        </div>
      </div>
      <!-- VISA Offer Content -->
      <ng-container *ngSwitchCase="'VISA Offer'">
        <!-- <div
          class="bg-[#F7F7F7] w-full flex flex-col lg:flex-row lg:items-center lg:justify-around text-xl md:text-3xl p-2 rounded-2xl cursor-pointer mb-10"
        >
          <ng-container *ngFor="let tab of tabsVisa; let i = index">
            <div
              class="bg-[#F7F7F7] w-full h-full flex items-center justify-center rounded-2xl py-4 text-center"
              [ngClass]="{
                'tab-item-visa': true,
                active: i === selectedTabVisa
              }"
              (click)="onSelectTab(i, 'visa')"
            >
              <h1>{{ tab.title }}</h1>
            </div>
          </ng-container>
        </div> -->
        <div
          class="flex flex-col md:flex-row md:items-center md:justify-center"
          infinite-scroll
          [infiniteScrollDistance]="5"
          [infiniteScrollThrottle]="1000"
          (scrolled)="onScroll()"
        >
          <div
            class="flex flex-col w-full md:flex-row md:flex-wrap gap-4 md:items-stretch md:justify-start lg:max-w-[720px] xl:max-w-[1088px]"
          >
            <ng-container *ngIf="!isLoading || pagination">
              <app-main-card
                *ngFor="let content of filterOffer"
                [routerLink]="['offer', content._id]"
                [queryParams]="{
                  card: queryParams.cardHolder
                }"
                [id]="content._id"
                [name]="content.merchantName"
                [nameEN]="content.merchantNameEN"
                [title]="content.offerName"
                [titleEN]="content.offerNameEN"
                [logo]="content.merchantImage"
                [image]="content.offerImage"
                [desc]="content.shortDescription"
                [descEN]="content.shortDescriptionEN"
                [topTag]="tabsVisa[selectedTabVisa].title"
                [category]="content.category"
              ></app-main-card>
            </ng-container>
            <div
              class="md:text-2xl w-full"
              *ngIf="filterOffer && filterOffer.length === 0 && !isLoading"
            >
              <h1 class="text-center w-full">
                {{
                  "We couldn't find any items matching your selected categories."
                    | transloco
                }}
              </h1>
              <h1 class="text-center w-full">
                {{
                  "Try broadening your search or clearing some filters."
                    | transloco
                }}
              </h1>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <app-main-loading *ngIf="isLoading"></app-main-loading>
  </div>
</div>
<div class="bg-[#F7F7F7] mt-10 pb-5 pt-5">
  <div class="container mx-auto">
    <h1 class="text-2xl md:text-4xl font-[Ontima] text-center opacity-70">
      {{ "Brought to you by" | transloco }}
    </h1>
    <app-sponsor-banner
      [imageArr]="images"
      [isAdjustMargin]="true"
      [isAdjustSize]="true"
    ></app-sponsor-banner>
  </div>
  <div class="w-full h-2 bg-white"></div>
  <div class="container mx-auto">
    <h1 class="text-2xl md:text-4xl font-[Ontima] mt-5 text-center opacity-70">
      {{ "Strategic Partners" | transloco }}
    </h1>
    <app-sponsor-banner [imageArr]="imagesStrategic"></app-sponsor-banner>
  </div>
  <div class="w-full h-2 bg-white"></div>
  <div class="container mx-auto">
    <h1 class="text-2xl md:text-4xl font-[Ontima] mt-5 text-center opacity-70">
      {{ "Campaign Supporters" | transloco }}
    </h1>
    <app-sponsor-banner [imageArr]="imagesPartner"></app-sponsor-banner>
  </div>
</div>
