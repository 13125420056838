import { Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/modules/admin/services/admin.service';
import { CompletedPopupComponent } from '../completed-popup/completed-popup.component';
import { OffersAdminService } from 'src/app/modules/admin/services/offers-admin.service';
import { IConfirmBody } from 'src/app/shared/model/form.model';

@Component({
  selector: 'app-admin-confirmation',
  templateUrl: './admin-confirmation.component.html',
  styleUrls: ['./admin-confirmation.component.scss'],
})
export class AdminConfirmationComponent {
  password = new FormControl('');
  msg = '';
  isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AdminConfirmationComponent>,
    private router: Router,
    private adminService: AdminService,
    public matDialog: MatDialog,
    private offerService: OffersAdminService
  ) {}

  ngOnInit(): void {
    console.log(this.data);
  }

  onOpenCompleted() {
    const dialogRefComplete = this.matDialog.open(CompletedPopupComponent, {
      enterAnimationDuration: '100ms',
      exitAnimationDuration: '300ms',
      width: '600px',
      data: {
        location: window.location.pathname,
        name: this.data?.adminData?.firstName,
        action: this.data?.offerData?.action,
        isPause: this.data?.offerData?.isPause,
      },
      disableClose: true,
    });

    // dialogRefComplete.afterClosed().subscribe((data) => {
    //   this.dialogRef.close();
    // });
  }

  formSummary(): IConfirmBody {
    const value = this.password.value as string;
    const password: IConfirmBody = { password: value };

    return password;
  }

  formEditSummary(): FormData {
    const value = this.password.value as string;
    const formData = new FormData();
    formData.append('password', value);
    formData.append('formUpload', this.data.offerData.formUpload);

    return formData;
  }

  onSubmit() {
    if (this.password.invalid) {
      this.password.markAllAsTouched();
    } else if (this.data.location.includes('/admin/edit-quota')) {
      this.isLoading = true;
      const formData = this.formEditSummary();
      this.offerService
        .editOfferQuota(formData, this.data.offerData.id)
        .subscribe({
          next: (data) => {
            this.isLoading = false;
          },
          error: (err) => {
            console.log(err);
            this.msg = err.statusMessage;
            this.isLoading = false;
          },
          complete: () => {
            this.isLoading = false;
            this.onOpenCompleted();
          },
        });
    } else {
      this.isLoading = true;
      const passwordBody = this.formSummary();
      if (this.data.location.includes('/admin/management')) {
        this.adminService
          .deleteAdmin(this.data.adminData._id, passwordBody)
          .subscribe({
            next: (data) => {
              this.isLoading = false;
            },
            error: (err) => {
              console.log(err);
              this.msg = err.statusMessage;
              this.isLoading = false;
            },
            complete: () => {
              this.isLoading = false;
              this.onOpenCompleted();
            },
          });
      } else if (this.data.location.includes('/admin/offer')) {
        if (this.data.offerData.action === 'pause') {
          const body = this.formSummary();
          body.isPause = this.data.offerData.isPause;
          this.offerService.pauseOffer(this.data.offerData.id, body).subscribe({
            next: (data) => {
              this.isLoading = false;
            },
            error: (err) => {
              console.log(err);
              this.msg = err.statusMessage;
              this.isLoading = false;
            },
            complete: () => {
              this.isLoading = false;
              this.onOpenCompleted();
            },
          });
        } else {
          this.offerService
            .deleteOffer(this.data.offerData.id, passwordBody)
            .subscribe({
              next: (data) => {
                this.isLoading = false;
              },
              error: (err) => {
                console.log(err);
                this.msg = err.statusMessage;
                this.isLoading = false;
              },
              complete: () => {
                this.isLoading = false;
                this.onOpenCompleted();
              },
            });
        }
      }
    }
  }
}
