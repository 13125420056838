import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { StorageService } from 'src/app/services/storage.service';
import { Role } from 'src/app/shared/enums/roles';
import { AdminData } from 'src/app/shared/model/admin.model';
import { UserAuthData } from 'src/app/shared/model/user.model';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent implements OnInit, OnDestroy {
  role: any;
  subscription!: Subscription;
  user!: AdminData;
  isUser = false;
  constructor(
    private authService: AuthService,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.subscription = this.authService.currentUser.subscribe(
      (user: UserAuthData | null) => {
        this.role = user?.roles;
      }
    );

    const userStorage = localStorage.getItem('profile');
    this.user = JSON.parse(userStorage!);

    const subscription2 = this.storageService.changes.subscribe((data: any) => {
      if (data.key === 'profile') {
        this.user = data.value;
      }
    });

    this.subscription.add(subscription2);
  }

  onLogout() {
    this.authService.logout(true);
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
