import { Injectable, Renderer2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  date = Date.now();
  constructor() {}

  handleDownloadProgress(event: any, progress: number) {
    progress = Math.round((100 * event.loaded) / event.loaded);
    return progress;
  }

  handleHttpResponse(event: any, renderer: Renderer2, type: string): void {
    const file: Blob = event.body as Blob;
    this.downloadFileFunc(file, renderer, type);
  }

  private downloadFileFunc(
    file: Blob,
    renderer: Renderer2,
    type: string
  ): void {
    const downloadLink = renderer.createElement('a');
    renderer.setProperty(
      downloadLink,
      'href',
      window.URL.createObjectURL(file)
    );
    renderer.setProperty(downloadLink, 'download', `TCEB_${type}_${this.date}`); // Set your desired filename here
    downloadLink.click();
  }
}
