<div class="mx-auto flex items-center justify-center h-screen bg-[#F7F7F7]">
  <div
    class="bg-white shadow-md shadow-gray-300 border-2 rounded-2xl w-[494px] p-5"
  >
    <a
      routerLink="/admin"
      class="flex items-center gap-2 cursor-pointer text-blue-500"
    >
      <mat-icon>arrow_back</mat-icon>
      <span>Back</span>
    </a>
    <h1 class="text-4xl mb-10 mt-5">{{ "Forgot Password" | transloco }}</h1>
    <div class="mb-2 mt-8">
      <label for="email" class="block text-sm font-medium mb-2 ms-2">{{
        "fill an email" | transloco
      }}</label>
      <input
        type="email"
        id="email"
        name="email"
        required
        [formControl]="email"
        class="border-2 border-gray-400 w-full p-2 px-4 rounded-full font-light"
        [placeholder]="'Email' | transloco"
        [ngClass]="
          (email.invalid && email.touched) || errMsg
            ? 'border-2 border-main-red'
            : ''
        "
      />
      <span
        class="text-main-red text-sm font-light"
        *ngIf="email.invalid && email.touched"
        >***{{ "Filled information are incorrect" | transloco }}</span
      >
      <p class="text-sm text-main-red" *ngIf="errMsg">{{ errMsg }}</p>
    </div>
    <p class="mb-10 opacity-50 text-sm">
      {{ "We will sent a reset password link to your inbox" | transloco }}
    </p>
    <div class="sm:w-32">
      <app-loading-button *ngIf="isLoading"></app-loading-button>
      <app-primary-button
        *ngIf="!isLoading"
        content="Submit"
        (onClick)="onSubmit()"
      ></app-primary-button>
    </div>
  </div>
</div>
